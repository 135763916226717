import React, { useState } from "react";
import "./NewsLetter.scss";
import { IoIosMailOpen } from "react-icons/io";
import { BsArrowReturnRight } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { SUBSCRIBED_NEWSLETTER, globalAction, SHOW_LOADING } from "../../redux";
import { useRef } from "react";
import Loading from "../Loading/Loading";
import { useTranslation } from "react-i18next";

function Newsletter() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.errorReducer);
  const [email, setEmail] = useState(undefined);
  const formRef = useRef();

  const handleSubscribed = (e) => {
    e.preventDefault();
    let formData = { email: email };
    if (email) {
      dispatch(globalAction(SHOW_LOADING));
      dispatch(globalAction(SUBSCRIBED_NEWSLETTER, formData));
      setEmail(undefined);
      formRef.current.reset();
    }
  };

  return (
    <div className="Newsletter">
      <Loading showLoading={isLoading} />
      <div className=" cm-container ">
        <div className="newsletter-inner cleafix">
          <div className="icon-wrap">
            <IoIosMailOpen size={50} />
          </div>
          <div className="content">
            <div>
              <h4>{t("Newsletter")}</h4>
              <p className="newsletter-para">
                {t("Subscribe Our newsletter to get our latest update & news")}
              </p>
            </div>
            <div className="form-wrap">
              <form
                ref={formRef}
                onSubmit={(e) => {
                  handleSubscribed(e);
                }}
              >
                <input
                  type="email"
                  //   className="input"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button type="submit" className="btn-brown">
                  <BsArrowReturnRight size={40} />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Newsletter;
