import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import SeoSetting from '../../components/SeoSetting/SeoSetting';
import './Contactus.scss';
import contactUsImg from '../../assets/img/contact-us.png';
import { Button, Form } from 'react-bootstrap';
import { CONTACT_US, globalAction, SHOW_LOADING } from '../../redux';
import AllCountries from "../../Utilis/allCountry.json";

function Contactus(props) {
    const dispatch = useDispatch()
    const { isLoading } = useSelector(state => state.errorReducer)
    const [formData, setFormData] = useState(undefined)
    const formRef = useRef()

    const handleSubmit=((e)=>{
        e.preventDefault();
        dispatch(globalAction(SHOW_LOADING))
        dispatch(globalAction(CONTACT_US,formData))
        formRef.current.reset()
    })

    const changeText = (value, text) => {
        setFormData({...formData,[text]:value})
    }
    
    useEffect(() => {
        window.scroll({top:0})
    }, [])

    return (
        <div className='contact-us-wrap'>
            <div className="cm-container">
                <div className="container-inner">
                    <Loading
                        showLoading={isLoading}
                    />
                    <SeoSetting
                        title='Contact-DuAfrik'
                        metaDescription='Contact to Du-Afrik admin'
                    />

                    <div className="col-left">
                        <h1><span>Contact</span> Us</h1>
                        <p>Feel free to contact us on your queries and we will get back to you as soon as possible.</p>
                        <img src={contactUsImg} alt="Contact Us" />
                    </div>
                    <div className="col-right">
                        <div className="form-wrap">
                        <Form 
                        ref={formRef}
                        onSubmit={(e) => { handleSubmit(e) }}
                        >
                                       <Form.Group className="mb-4" controlId="name">
                                            <Form.Label>Full Name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter full name"
                                                className="input"
                                                onChange={(e) => changeText(e.target.value, 'name')}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-4" controlId="email">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                required
                                                type="email"
                                                placeholder="Enter name"
                                                className="input"
                                                onChange={(e) => changeText(e.target.value, 'email')}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-4" controlId="mobile">
                                            <Form.Label>Mobile</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Mobile number"
                                                className="input"
                                                onChange={(e) => changeText(e.target.value, 'mobile')}
                                            />
                                        </Form.Group>

                                        <label class="form-label">Country</label>
                                        <select
                                            class="form-control"
                                            required
                                            onChange={(e) => changeText(e.target.value, "country")}
                                            >
                                            {AllCountries.map((item, i) => {
                                                return (
                                                <option key={item.updated_at} value={item.id}>
                                                    {item.name}
                                                </option>
                                                );
                                            })}
                                        </select><br/>

                                        <Form.Group className="mb-4" controlId="subject">
                                            <Form.Label>Subject</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter subject"
                                                className="input"
                                                onChange={(e) => changeText(e.target.value, 'subject')}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-4" controlId="message">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control
                                        required={true}
                                        type="text"
                                        placeholder="Enter message..."
                                        as="textarea"
                                        style={{ height: '100px' }}
                                        className="input"
                                        onChange={(e) => changeText(e.target.value, 'message')}
                                    />
                                  
                                </Form.Group>

                                      
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            className="btn-brown"
                                        >
                                            Send
                                        </Button>
                                    </Form>
                        </div>
                    </div>



                </div>

            </div>
        </div>
    )
}

export default Contactus
