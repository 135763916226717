import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import "./become-partner.scss";

import Partner1 from "../../assets/img/how-it-works3.png";
import Partner2 from "../../assets/img/patner3.jpg";
import Partner3 from "../../assets/img/patner3.png";
import sellBanner from "../../assets/img/sellBanner.jpg";
import HomeBanner from "../../components/HomeBanner/HomeBanner";
import { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { globalAction, SHOW_LOADING, SELL_ON } from "../../redux";
import { useDispatch } from "react-redux";
import AllCountry from "../../Utilis/allCountry.json";
import exportCoutryMap from "../.././assets/img/fini.png";
const cardData = [
  {
    image: Partner1,
    title: "Register",
    description:
      "Visit our website and click on the 'Sign Up' button. Sign up with your email and create a secure password to join our platform.",
  },
  {
    image: Partner2,
    title: "KYC",
    description:
      "Verify your identity by submitting the required documents for a quick approval process.",
  },
  {
    image: Partner3,
    title: "Start selling",
    description:
      "List Your Products: Take clear photos of what you want to sell, write a simple description, and set a price. Go Live: Once your products are listed, customers can see and buy them. You’ll get notified when someone makes a purchase.",
  },
];

function BecomePartner() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(undefined);
  const formRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(globalAction(SHOW_LOADING));
    dispatch(globalAction(SELL_ON, formData));
    formRef.current.reset();
  };

  const changeText = (value, text) => {
    setFormData({ ...formData, [text]: value });
  };

  useEffect(() => {
    window.scroll({ top: 0 });
  }, []);

  return (
    <section className="become_partner">
      <>
        <HomeBanner
          img={sellBanner}
          // bannerTitle="How To Sell With Us"
        />
      </>
      <div className="container">
        <div className="spacing">
          <div className="page_title">
            <h3>how it is African trade by country’s ?</h3>
          </div>
          <p style={{ textAlign: "justify" }}>
            African trade finance is growing, and SMEs should be best placed to
            account for a significant proportion of this uptick A recent report
            by Bain & Company (see below) forecasts intra-Africa trade to grow
            at 5.3 percent between 2016 and 2026. SMEs, and in particular
            businesses with revenues of over US$1 million, are expected to
            account for a significant percentage of this growth. These
            enterprises contribute to a large proportion of Africa’s trade, but
            even though 88 percent of African SMEs have a bank account, only 25
            percent have a loan or a line of credit (AfDB). A similar IFC report
            calculated the finance gap (across all types of finance) for the 44
            million micro, small and medium-sized enterprises (MSMEs) in
            sub-Saharan Africa to be US$331 billion.
          </p>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div style={{ width: "50%" }}>
              <b>
                {" "}
                <h6>Gráfic with African top 10</h6>
              </b>

              <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                {/* <p>Top 10 Export Largest African Countries</p> */}
                {[
                  "South Africa",
                  "Nigeria",
                  "Algeria",
                  "Angola",
                  "Libya",
                  "Egypt",
                  "Ghana",
                  "DR Congo",
                  "Gabon",
                  "Zimbabwe",
                ].map((country, index) => (
                  <li
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "5px",
                    }}
                  >
                    <span style={{ marginRight: "8px" }}>{index + 1}.</span>
                    {country}
                  </li>
                ))}
              </ul>
            </div>
            <div style={{ width: "50%" }}>
              <img
                style={{ height: "450px", width: "550px " }}
                src={exportCoutryMap}
              />
            </div>
          </div>
          <b>
            <h5>DuAfrik Modus Operandis</h5>
          </b>
          <p style={{ textAlign: "justify" }}>
            Based on a e-commerce platform, African brands, from all sector,
            will be able to display their products directly to the end consumer.
            Whether for wholesale purchases of raw materials and industrial
            inputs, or retail for end consumers, such as fashion or beauty
            products, will any customer be able to find the greatest number of
            possibilities, as long as they are exclusively produced in Africa or
            with African materials.
          </p>
          <p style={{ textAlign: "justify" }}>
            The platform get together the two extremes: those who want to sell
            to those who want to buy. In between there are the Partners, project
            ambassadors or Country Managers which encourage brands adoptions and
            business expansion.
          </p>
          <p style={{ textAlign: "justify" }}>
            A business where everyone wins: the Partners who bring the Sellers,
            the brands that sell more, and the Consumers with more products at
            the best price!
          </p>
          <p style={{ textAlign: "justify" }}>
            An online sales site, that enables transactions between unlikely
            agents, who would hardly come into contact otherwise.
          </p>

          {/* ------------------------------------------ */}
          <div className="spacing">
            <div className="page_title">
              <h3>How to sell on DuAfrik</h3>
            </div>

            <div className="kyc">
              <h6 style={{ textAlign: "justify" }}>
                Selling on e-commerce platforms involves creating appealing
                product listings with clear descriptions and high-quality
                images. Promote your products through social media channels to
                reach a wider audience and consider using paid advertising for
                increased visibility. Providing excellent customer service, such
                as responding promptly to inquiries and resolving issues
                quickly, is key to building trust and encouraging repeat
                purchases. These steps can help you effectively sell on
                e-commerce platforms and grow your online business
              </h6>

              <div className="card_wrap">
                {cardData.map((card, index) => (
                  <div key={index} className="kyc_card">
                    <img
                      height="130px"
                      width="130px"
                      src={card.image}
                      alt={card.title}
                    />
                    <div className="card_body">
                      <h4>{card.title}</h4>
                      <p>{card.description}</p>
                      {/* <Link to="/">Learn More..</Link> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* -------------------------------------------------- */}
        </div>
        <div className="spacing">
          <div className="page_title notranslate">
            <h3>
              <span>Sell on DuAfrik</span>
            </h3>
          </div>

          <div className="form-wrap">
            <Form
              ref={formRef}
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <Form.Group className="mb-4" controlId="name">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter full name"
                  className="input"
                  onChange={(e) => changeText(e.target.value, "name")}
                />
              </Form.Group>

              <Form.Group className="mb-4" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  type="email"
                  placeholder="Enter name"
                  className="input"
                  onChange={(e) => changeText(e.target.value, "email")}
                />
              </Form.Group>
              <Form.Group className="mb-4" controlId="mobile">
                <Form.Label>Mobile</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="Mobile number"
                  className="input"
                  onChange={(e) => changeText(e.target.value, "mobile")}
                />
              </Form.Group>
              <Form.Group className="mb-4" controlId="Country">
                <Form.Label>Country </Form.Label>
                <Form.Select
                  aria-label="Floating label select example"
                  required
                  className="input"
                  // onLoad={() => {
                  //     dataForUpdate?.country && getStateByCountryId(Number((dataForUpdate?.country)))
                  // }}
                  onChange={(e) => changeText(e.target.value, "country")}
                >
                  <option>Select country</option>
                  {AllCountry.map((indCountry, i) => {
                    return (
                      <option
                        key={indCountry.name}
                        value={indCountry.id}
                        // selected={formData?.country == indCountry.id}
                      >
                        {indCountry.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-4" controlId="companyName">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Company Name"
                  className="input"
                  onChange={(e) => changeText(e.target.value, "companyName")}
                />
              </Form.Group>

              <Form.Group className="mb-4" controlId="companyAddress">
                <Form.Label>Company Address</Form.Label>
                <Form.Control
                  required={true}
                  type="text"
                  placeholder="Enter company address..."
                  as="textarea"
                  style={{ height: "100px" }}
                  className="input"
                  onChange={(e) => changeText(e.target.value, "companyAddress")}
                />
              </Form.Group>

              <Form.Group className="mb-4" controlId="message">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  required={true}
                  type="text"
                  placeholder="Enter message..."
                  as="textarea"
                  style={{ height: "100px" }}
                  className="input"
                  onChange={(e) => changeText(e.target.value, "message")}
                />
              </Form.Group>

              <Form.Group className="mb-4" controlId="files">
                <Form.Label>Attachment</Form.Label>
                <Form.Control
                  required={true}
                  type="file"
                  className="input"
                  onChange={(e) => changeText(e.target.files[0], "files")}
                />
              </Form.Group>

              {/* <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    Upload
                  </span>
                </div>
                <div class="custom-file">
                  <input
                    type="file"
                    class="custom-file-input"
                  />
                  <label class="custom-file-label" for="inputGroupFile01">
                    Choose file
                  </label>
                </div>
              </div> */}
              <Button variant="primary" type="submit" className="btn-brown">
                Submit Request
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BecomePartner;
