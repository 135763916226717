import { takeEvery, put, call, delay } from "redux-saga/effects";
import {
  accountVerifyApi,
  changePasswordApi,
  forgetPasswordApi,
  loginApi,
  registrationApi,
  reset_passwordApi,
} from "../../api";
import { SIGN_UP, REMOVE_MESSAGE, CLOSE_LOADING } from "../actionsType";
import { globalAction } from "../actions";
import {
  ACCOUNT_VERIFY,
  CHANGE_PASSWORD,
  FORGET_PASSWORD,
  HIDE_MESSAGE,
  LOGIN,
  RESET_PASSWORD,
  SET_USER,
  SHOW_MESSAGE,
} from "..";
import { setSession } from "../../Utilis/setSession";
const callApi = (customFunction, data) => {
  return customFunction(data)
    .then((result) => result)
    .catch((err) => err.response);
};

function* signUp({ payload }) {
  const { history } = payload;
  try {
    const result = yield call(callApi, registrationApi, payload);
    const { status, data } = result;

    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        console.log("run");
        yield localStorage.setItem("DuAfricUserEmail", data.data.email);
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(2000);
        yield history.push("/verify-account");
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err.response));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* accountVerify({ payload }) {
  const { history } = payload;
  try {
    const result = yield call(callApi, accountVerifyApi, payload);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield put(globalAction(SET_USER, data.data));
        history.push("/Dashboard");
        if (data.data.role_id === 3) {
          history.push("/vendorDashboard");
        }
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err.response));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* login({ payload }) {
  const { history } = payload;
  try {
    let result = yield call(callApi, loginApi, payload);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        setSession(data?.access_token);
        // yield localStorage.setItem("duAfirk_token", data?.access_token);
        yield localStorage.setItem("duAfirk_user", JSON.stringify(data.data));
        yield put(globalAction(SET_USER, data.data));
        window.location.assign("/");
        // history.push("/");
        // window.location.reload();
        // if (data.data.role_id === 3) {
        //   history.push("/vendorDashboard");
        // }
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        if (
          data.error ===
          "Please activate your account by verifying on your email"
        ) {
          yield localStorage.setItem("DuAfricUserEmail", payload.email);
          yield history.push("/verify-account");
        }
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err.response));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* forgetPassword({ payload }) {
  try {
    const result = yield call(callApi, forgetPasswordApi, payload);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(2000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(2000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    console.log(err);
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err.response));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* resetPassword({ payload }) {
  const { history } = payload;
  try {
    const result = yield call(callApi, reset_passwordApi, payload);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        history.push("/login");
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(2000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(2000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    console.log(err);
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err.response));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* changePassword({ payload }) {
  try {
    const result = yield call(callApi, changePasswordApi, payload);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(2000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(2000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    console.log(err);
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err.response));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

export function* watchAuthentication() {
  yield takeEvery(SIGN_UP, signUp);
  yield takeEvery(ACCOUNT_VERIFY, accountVerify);
  yield takeEvery(LOGIN, login);
  yield takeEvery(FORGET_PASSWORD, forgetPassword);
  yield takeEvery(RESET_PASSWORD, resetPassword);
  yield takeEvery(CHANGE_PASSWORD, changePassword);
}
