import React, { useEffect, useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { MdLocationOn } from "react-icons/md";
import "./Profile.scss";
import userDemoImg from "../../../../assets/img/user-profile.jpg";
import { useSelector } from "react-redux";
// import { getStateByCountryId } from "../../../../Utilis/HelperFunction";
import AllCountries from "../../../../Utilis/allCountry.json";
import {
  getUserDashboardDetailsApi,
  getVendorDashboardDetailsApi,
  stateApi,
} from "../../../../api";
import {
  CLOSE_LOADING,
  globalAction,
  SHOW_LOADING,
  UPDATE_PROFILE,
} from "../../../../redux";
import { useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";

function Profile(props) {
  const { user } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const [getStates, setGetStates] = useState([]);
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);
  const [imagePreview, setimagePreview] = useState(undefined);
  const [dashboardData, setDashboardData] = useState(undefined);

  /** Its takes country id as a argus. return state data*/
  const getStateByCountryId = async (id) => {
    await stateApi(id).then(async (res) => {
      const { data, status } = res;
      if (status === 200) {
        await setGetStates(data.state_list);
      }
    });
  };

  const changeText = (value, fieldName) => {
    let credentials = formData;
    credentials[fieldName] = value;

    if (fieldName === "country_id") {
      setGetStates(getStateByCountryId(value));
    }
    if (fieldName === "state_id") {
      let seletedCountryName = getStates.find(
        (item) => item.id == Number(value)
      );
      credentials["state"] = seletedCountryName.name;
    }
    setFormData(credentials);
  };

  const onSelectImage = async (value, fieldName) => {
    let file = await value.target.files[0];
    let reader = await new FileReader();
    if (file) {
      reader.readAsDataURL(file);
    }
    reader.onload = async () => {
      let credentials = formData;
      credentials[fieldName] = file;
      setimagePreview(reader.result);
      setFormData(credentials);
    };
  };

  const handleSubmit = (e, formType) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      dispatch(globalAction(SHOW_LOADING));
      dispatch(globalAction(UPDATE_PROFILE, formData));
    }
    setValidated(true);
  };

  useEffect(() => {
    setGetStates(getStateByCountryId(user?.country_id));
    setFormData({
      ...formData,
      name: user?.name,
      user_id: user?.id,
      country_id: user?.country_id,
      email: user?.email,
      mobile: user?.mobile,
      state_id: user?.state_id,
      city: user?.city,
      state: user?.state,
    });
    // for vendor
    if (user?.id && user?.role_id === 3) {
      dispatch(globalAction(SHOW_LOADING));
      getVendorDashboardDetailsApi({ vendor_id: user?.id })
        .then((res) => {
          dispatch(globalAction(CLOSE_LOADING));
          const { status, data } = res;
          if (status === 200) {
            setDashboardData(data);
          }
        })
        .catch((err) => {
          alert("something went wrong. Please try again");
        });
    }

    if (user?.id && user?.role_id === 2) {
      dispatch(globalAction(SHOW_LOADING));
      getUserDashboardDetailsApi({ user_id: user?.id })
        .then((res) => {
          dispatch(globalAction(CLOSE_LOADING));
          const { status, data } = res;
          if (status === 200) {
            setDashboardData(data);
          }
        })
        .catch((err) => {
          alert("something went wrong. Please try again");
        });
    }
  }, []);

  // useEffect(() => {

  // }, [user])
  // console.log(getStates)
  console.log("dashboardData", user);
  return (
    <div className="vd-dashboard-wrap profile">
      <div className="title" style={{ marginTop: 20 }}>
        <h3>profile</h3>
      </div>
      <div className="vd-dashboard-body">
        <div className="content-left box-size">
          <div className="edit-profile">
            <h4>Edit Profile</h4>

            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => {
                handleSubmit(e, "updateProfile");
              }}
            >
              <Row className="mb-3">
                <Form.Group as={Col} controlId="name">
                  <Form.Label>
                    Name <span className="requered">*</span>
                  </Form.Label>
                  <Form.Control
                    className="input"
                    type="text"
                    placeholder="Name"
                    required
                    defaultValue={user?.name}
                    onChange={(e) => changeText(e.target.value, "name")}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="email">
                  <Form.Label>
                    Business Email <span className="requered">*</span>
                  </Form.Label>
                  <Form.Control
                    className="input"
                    type="email"
                    placeholder="Email"
                    required
                    defaultValue={user?.email}
                    disabled={true}
                    // onChange={(e) => changeText(e.target.value, 'email')}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="phoneNumber">
                  <Form.Label>
                    Mobile <span className="requered">*</span>
                  </Form.Label>
                  <PhoneInput
                    country="us"
                    specialLabel="Custom Phone*"
                    fullWidth
                    name="phone_number"
                    id="phone_number"
                    inputStyle={{
                      border: "none",
                      background: "#ffffff",
                      height: "43px",
                      paddingTop: "15px",
                      width: "100%",
                      boxShadow: "none",
                      outline: "none",
                    }}
                    containerStyle={{
                      border: "1px solid #ced4da",
                      height: "45px",
                      background: "#ffffff",
                      boxShadow: "none",
                    }}
                    value={user?.mobile}
                    onChange={(value) => changeText(value, "mobile")}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>
                    Country <span className="requered">*</span>
                  </Form.Label>
                  <Form.Select
                    defaultValue={user?.country?.name}
                    selected={user?.country?.id}
                    required
                    onChange={(e) => changeText(e.target.value, "country_id")}
                  >
                    <option>{user?.country?.name}</option>
                    {AllCountries.map((item, i) => {
                      return (
                        <option key={item.updated_at} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridCity" required>
                  <Form.Label>
                    State <span className="requered">*</span>
                  </Form.Label>
                  <Form.Select
                    defaultValue={user?.state?.name}
                    selected={user.state_id}
                    required
                    onChange={(e) => changeText(e.target.value, "state_id")}
                  >
                    <option>{user.state?.name}</option>
                    {getStates.length > 0 &&
                      getStates.map((item, i) => {
                        return (
                          <option key={i} value={item?.id}>
                            {item?.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridCity" required>
                  <Form.Label>
                    City <span className="requered">*</span>
                  </Form.Label>
                  <Form.Control
                    className="input"
                    type="text"
                    placeholder="Enter city"
                    required
                    defaultValue={user?.city}
                    onChange={(e) => changeText(e.target.value, "city")}
                  />
                </Form.Group>
              </Row>

              {/* <Form.Group controlId="formFile" className="mb-3" required>
                <Form.Label>
                  Choose Profile Picture <span className="requered">*</span>
                </Form.Label>
                <Form.Control className="input" type="file" onChange={(e) => onSelectImage(e, "photo")} />
              </Form.Group> */}

              <Button className="btn-brown" variant="primary" type="submit">
                Update
              </Button>
            </Form>
          </div>
        </div>
        <div className="content-right box-size">
          <div className="profile_card">
            <div className="card_head">
              <div className="img">
                <label htmlFor="updateImage">
                  <img
                    src={imagePreview || user?.profile_image || userDemoImg}
                    alt="user"
                  />
                </label>

                {console.log(user, "userinfo")}
                <Form.Control
                  id="updateImage"
                  className="input img-upload"
                  type="file"
                  accept="image/*"
                  onChange={(e) => onSelectImage(e, "photo")}
                />
              </div>
              {user?.role_id === 3 && (
                <>
                  <h3>
                    {user?.name.charAt(0).toUpperCase() + user?.name.slice(1)}
                  </h3>
                  <h4>
                    {user?.company_name.charAt(0).toUpperCase() +
                      user?.company_name.slice(1)}
                  </h4>
                  <div className="location">
                    <span>
                      <MdLocationOn />
                    </span>
                    <span>{user?.company_address}</span>
                  </div>
                </>
              )}

              {user?.role_id === 2 && (
                <>
                  <h3>
                    {user?.name.charAt(0).toUpperCase() + user?.name.slice(1)}
                  </h3>
                  <div className="location">
                    <span>
                      {" "}
                      {user?.state?.name}, {user?.country?.name}
                    </span>
                  </div>
                </>
              )}

              <span className="seller">
                {user?.role_id === 3 ? "Vendor" : "Customer"}
              </span>
            </div>
            <div className="card_body">
              {user?.role_id === 3 ? (
                <div className="count">
                  <h4>{dashboardData?.count_product}</h4>
                  <span>Total Products</span>
                </div>
              ) : (
                <div className="count">
                  <h4>{dashboardData?.count_wishlist}</h4>
                  <span>Wishlist</span>
                </div>
              )}

              <div className="count">
                <h4>{dashboardData?.count_orders}</h4>
                <span>Total Orders</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
