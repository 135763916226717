import React from "react";
import { useSelector } from "react-redux";
import { Switch, Route } from "react-router-dom";
import NotFound from "../../components/Not-found/NotFound";
import SelectAddress from "../Address/SelectAddress";
import ShoppingCart from "../ShoppingCart/ShoppingCart";
import Profile from "../VendorDashboard/Dashboard/Profile/Profile";
import OrderDetails from "./OrderManagement/OrderDetails";
import Ordermanagement from "./OrderManagement/OrderManagement";
import ResetPassword from "./ResetPassword";

import UserDashboard from "./UserDashboard";
import WishList from "./WishList";


function UserDashboardRoutes() {
  const { user } = useSelector((state) => state.authReducer);
  return (
    <Switch>
      <>
        <Route exact path="/dashboard" component={UserDashboard} />
        <Route exact path="/dashboard/orders" component={Ordermanagement} />
        <Route exact path="/dashboard/change-password" component={ResetPassword} />
        <Route exact path="/dashboard/saved-address" component={SelectAddress} />
        
         <Route exact path="/dashboard/profile" component={Profile}  />
         <Route exact path="/dashboard/order-detail/:id" component={OrderDetails}  />
         <Route exact path="/dashboard/wishlist" component={WishList}  />
         <Route exact path="/dashboard/cart" component={ShoppingCart}  />
      </>

      <Route component={NotFound} />
    </Switch>
  );
}

export default UserDashboardRoutes;
