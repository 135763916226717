import React, { useState } from "react";
import "./Dashboard.scss";
import { Link } from "react-router-dom";
import { Form, Button, InputGroup } from "react-bootstrap";
import { CHANGE_PASSWORD, globalAction, SHOW_LOADING } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { IoEye, IoEyeOffSharp } from "react-icons/io5";

function ResetPassword() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);
  const [formData, setFormData] = useState(undefined);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData && formData?.new_password?.length <= 5) {
      window.alert("New Password Must be atleast 6 characters!");
      return;
    }
    //setFormData({ ...formData, id: user.id })
    dispatch(globalAction(SHOW_LOADING));
    dispatch(globalAction(CHANGE_PASSWORD, formData));
    setFormData({
      id: user.id,
      new_password: "",
      old_password: "",
      confirm_password: "",
    });
  };

  const handleChange = (value, fieldName) => {
    setFormData({ ...formData, [fieldName]: value });
  };

  useEffect(() => {
    setFormData({ id: user.id });
  }, [user]);

  return (
    <div className="user_dashboard reset_password">
      <div className="page_title">
        <h3>Reset Password</h3>
      </div>

      <div className="recent_order box-size">
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Form.Group className="mb-1" controlId="formBasicPassword">
            <Form.Label>Old Password</Form.Label>
            <InputGroup
              style={{
                border: "1px solid #000000",
                borderRadius: "4px",
                position: "relative",
              }}
            >
              <Form.Control
                required
                type={showOldPassword ? "text" : "password"}
                placeholder="Enter Old Password"
                // className="input"
                value={formData?.old_password}
                onChange={(e) => handleChange(e.target.value, "old_password")}
                style={{
                  fontSize: "18px",
                  color: "#3a3a3a",
                  zIndex: "0 !important",
                  border: "none",
                  marginRight: "50px",
                  padding: "0 0 0 10px",
                }}
              />
              <Button
                onClick={() => setShowOldPassword(!showOldPassword)}
                style={{
                  color: "#000000",
                  backgroundColor: "#ffffff",
                  borderColor: "#ffffff",
                  outline: "none",
                  boxShadow: "none",
                  position: "absolute",
                  right: 0,
                  zIndex: "99 !important",
                }}
              >
                {showOldPassword ? (
                  <IoEye fontSize={25} />
                ) : (
                  <IoEyeOffSharp fontSize={25} />
                )}
              </Button>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3" controlId="NewPassword">
            <Form.Label>New Password</Form.Label>
            <InputGroup
              style={{
                border: "1px solid #000000",
                borderRadius: "4px",
                position: "relative",
              }}
            >
              <Form.Control
                required
                type={showPassword ? "text" : "password"}
                placeholder="Enter New Password"
                // className="input"
                value={formData?.new_password}
                onChange={(e) => handleChange(e.target.value, "new_password")}
                style={{
                  fontSize: "18px",
                  color: "#3a3a3a",
                  zIndex: "0 !important",
                  border: "none",
                  marginRight: "50px",
                  padding: "0 0 0 10px",
                }}
              />
              <Button
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  color: "#000000",
                  backgroundColor: "#ffffff",
                  borderColor: "#ffffff",
                  outline: "none",
                  boxShadow: "none",
                  position: "absolute",
                  right: 0,
                  zIndex: "99 !important",
                }}
              >
                {showPassword ? (
                  <IoEye fontSize={25} />
                ) : (
                  <IoEyeOffSharp fontSize={25} />
                )}
              </Button>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3" controlId="contfirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <InputGroup
              style={{
                border: "1px solid #000000",
                borderRadius: "4px",
                position: "relative",
              }}
            >
              <Form.Control
                required
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Enter Confirm Password"
                // className="input"
                value={formData?.confirm_password}
                onChange={(e) =>
                  handleChange(e.target.value, "confirm_password")
                }
                style={{
                  fontSize: "18px",
                  color: "#3a3a3a",
                  zIndex: "0 !important",
                  border: "none",
                  marginRight: "50px",
                  padding: "0 0 0 10px",
                }}
              />
              <Button
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                style={{
                  color: "#000000",
                  backgroundColor: "#ffffff",
                  borderColor: "#ffffff",
                  outline: "none",
                  boxShadow: "none",
                  position: "absolute",
                  right: 0,
                  zIndex: "99 !important",
                }}
              >
                {showConfirmPassword ? (
                  <IoEye fontSize={25} />
                ) : (
                  <IoEyeOffSharp fontSize={25} />
                )}
              </Button>
            </InputGroup>
          </Form.Group>
          <Button className="btn-black" variant="primary" type="submit">
            Change Password
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default ResetPassword;
