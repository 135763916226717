import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { globalAction, SHOW_LOADING, UPDATE_COMPANY_PROFILE } from '../../../redux';
import AllCountry from '../../../Utilis/allCountry.json';
import './UpdateKYC.scss';

function UpdateCompany() {
    const [formData, setFormData] = useState({})
    const [validated, setValidated] = useState(false);
    const { user } = useSelector(state => state.authReducer)
    const dispatch = useDispatch()



    const changeText = (value, fieldName) => {
        let credentials = formData;
        credentials[fieldName] = value
        setFormData(credentials)
    }

    const onselect = (e, fieldName) => {
        console.log(e.target.files[0])
        setFormData({ ...formData, [fieldName]: e.target.files[0] })
    }

    const handleSubmit = (e, formType) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }
        else {
            let tempData = {
                ...formData,
                latitude: '0.554',
                longitude: '87.554'
            }
            console.log(tempData)
            dispatch(globalAction(SHOW_LOADING))
            console.log('tempDAta', tempData)
            dispatch(globalAction(UPDATE_COMPANY_PROFILE, tempData))
        }
        setValidated(true);
    }

    useEffect(() => {
        if (user) {
            let tempObj = {
                id: user?.id,
                company_name: user?.company_name,
                company_address: user?.company_address,
                company_url: user?.company_url,
                country_id: user?.country_id,
                company_details: user?.company_details
            }
            setFormData(tempObj)
        }
    }, [user])

    console.log(user)
    return (
        <div className='updateCompany'>
            <div class="title"><h3>Edit Company Info</h3></div>
            <div className="ftd-fw-form company">
                <Form
                    noValidate
                    validated={validated}
                    onSubmit={(e) => { handleSubmit(e) }}
                >
                    <Row >
                        <Col md>
                            <Form.Group className="mb-4" controlId="formBasicCompanyName">
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Enter company name"
                                    className="input"
                                    defaultValue={formData?.company_name}
                                    onChange={(e) => changeText(e.target.value, 'company_name')}
                                />
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-4" controlId="Companywebsiturl">
                                <Form.Label>Company Website Url</Form.Label>
                                <Form.Control
                                    type="text"
                                    defaultValue={formData?.company_url}
                                    placeholder="Company Website Url"
                                    className="input"
                                    onChange={(e) => changeText(e.target.value, 'company_url')}
                                />
                                <Form.Text id="Companywebsiturl" muted>(Its optional)
                                </Form.Text>
                            </Form.Group>

                        </Col>
                    </Row>

                    <Row >
                        <Col md>
                            <Form.Group className="mb-4" controlId="companyAddress">
                                <Form.Label>Company Address</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    defaultValue={formData?.company_address}
                                    placeholder="Enter Company Address"
                                    className="input"
                                    onChange={(e) => changeText(e.target.value, 'company_address')}
                                />
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-4" controlId="Country">
                                <Form.Label>Country </Form.Label>
                                <Form.Select
                                    aria-label="Floating label select example"
                                    required
                                    className="input"
                                    onChange={(e) => changeText(e.target.value, 'country_id')}
                                >
                                    <option>Select Country</option>
                                    {AllCountry.map((item, i) => {
                                        return (
                                            <option
                                                value={item.id}
                                                selected={formData?.country_id == item.id}
                                            >{item.name}</option>
                                        )
                                    })}

                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className="mb-4 visible-upload" controlId="bannerChange">
                        <Form.Label>Banner Image</Form.Label>
                        <Form.Control
                            type="file"
                            placeholder="Banner_image"
                            className="input"
                            defaultValue={formData?.company_details}
                            onChange={(e) => onselect(e, 'banner_image')}
                        />
                       
                    </Form.Group>

                    <Form.Group className="mb-4" controlId="CompanyDescription">
                        <Form.Label>Company Description</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Company description"
                            as="textarea"
                            style={{ height: '100px' }}
                            className="input"
                            defaultValue={formData?.company_details}
                            onChange={(e) => changeText(e.target.value, 'company_details')}
                        />
                        <Form.Text id="Companywebsiturl" muted>(Its optional)
                        </Form.Text>
                    </Form.Group>

                    <Button variant="primary" type="submit" className="btn-brown">
                        Update
                    </Button>
                </Form>
            </div>
        </div>
    )
}

export default UpdateCompany
