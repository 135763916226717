import React from "react";
import { Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

function SelectUserType(props) {
  const { toggleModal, modalVisible } = props;
  return (
    <div className="select-user-wrap">
      <Modal
        show={modalVisible}
        onHide={toggleModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cm-modal select-user"
      >
        <Modal.Header closeButton>
          <h3>{`Select Account`}</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="select-wrap">
            <div className="select-inner">
              <div className="btn-wrap">
                <a className="btn-black btn" href="/fill-address">
                  Guest
                </a>
                <p>Continue as a guest</p>
              </div>
              <div className="btn-wrap">
                <a className="btn-black btn" href="/login">
                  Login/Signup
                </a>
                <p>With an exiting account.</p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SelectUserType;
