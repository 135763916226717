import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import './NotFound.scss'

function NotFound() {
    useEffect(() => {
        window.scroll({top:0})
    }, [])
    return (
        <div className="not-found">
            <div className="cm-container">
            <div className="not-found-inner">
                <h1>404</h1>
                <p>Page in progress. We will update soon.</p>
                <Link to='/' className="btn-black"> Home</Link>
            </div>
            </div>
        </div>
    )
}

export default NotFound;
