import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import MaterialTable from "material-table";
import "./OrderManagement.scss";
import tableIcons from "../../../Dashboard/TableIcons";
import { useDispatch, useSelector } from "react-redux";
import {
  CLOSE_LOADING,
  GET_ORDER_LIST_BY_VENDOR_ID,
  globalAction,
  SHOW_LOADING,
} from "../../../../redux";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import {
  getOrderListByVendorIdApi,
  getVendorDashboardDetailsApi,
} from "../../../../api";

function OrderManagement() {
  const { user } = useSelector((state) => state.authReducer);
  const { orderList } = useSelector((state) => state.appReducer);
  const [dashboardData, setDashboardData] = useState(undefined);
  const [orderGraph, setOrderGraph] = useState([]);
  const dispatch = useDispatch();

  // console.log(orderList)

  const orderTitle = [
    {
      title: "Order Id",
      render: (rowData) => (
        <Link to={`/vendorDashboard/order-detail/${rowData.id}`}>
          {rowData?.order_number}
        </Link>
      ),
      field: "order_number",
    },
    {
      title: "Total Price",
      render: (rowData) => "$" + rowData.order_price,
      field: "order_price",
    },
    { title: "Payment Mode", field: "payment_type" },
    { title: "Payment Status", field: "payment_status" },
    { title: "Order status", field: "order_status" },
    { title: "Order Date", field: "order_at" },
    {
      title: "Actions",
      render: (rowData) => (
        <a href={`/vendorDashboard/order-detail/${rowData.id}`}>
          <FaEye />
        </a>
      ),
      field: "CancelOrder",
      sorting: false,
    },
  ];

  const orderData = [
    { orderType: "Basic Order", orderId: "1", Qty: "3", TotalAmount: "$40" },
    { orderType: "Shoes", orderId: "2", Qty: "1", TotalAmount: "$40" },
    { orderType: "Shots", orderId: "3", Qty: "1", TotalAmount: "$40" },
    { orderType: "na", orderId: "4", Qty: "5", TotalAmount: "$40" },
  ];

  useEffect(() => {
    if (user?.id) {
      dispatch(globalAction(SHOW_LOADING));
      getVendorDashboardDetailsApi({ vendor_id: user?.id })
        .then((res) => {
          dispatch(globalAction(CLOSE_LOADING));
          const { status, data } = res;
          if (status === 200) {
            setDashboardData(data);
          }
        })
        .catch((err) => {
          alert("something went wrong. Please try again");
        });
    }
  }, []);

  useEffect(() => {
    let tempOrderGraph = [
      ["Months", "Orders", "Cancelled", "Completed", "Active"],
    ];
    dashboardData?.dates?.forEach((item, i) => {
      let numberOfOrderBooked =
        dashboardData?.order_graph_last_12_months_status_wise?.all.find(
          (item2) => item2.months === item.month
        )?.total;

      let cancelledOrder =
        dashboardData?.order_graph_last_12_months_status_wise?.cancelled.find(
          (item2) => item2.months === item.month
        )?.total; // get number Of order Cancelled in  given month

      let CompleteOrder =
        dashboardData?.order_graph_last_12_months_status_wise?.completed.find(
          (item2) => item2.months === item.month
        )?.total; // get number Of order which are completed in  given month

      let PendingOrder =
        dashboardData?.order_graph_last_12_months_status_wise?.pending.find(
          (item2) => item2.months === item.month
        )?.total; // get number Of order which are pending in  given month

      tempOrderGraph.push([
        item?.month,
        numberOfOrderBooked || 0,
        cancelledOrder || 0,
        CompleteOrder || 0,
        PendingOrder || 0,
      ]);
    });
    setOrderGraph(tempOrderGraph);
  }, [dashboardData]);

  return (
    <div className="vd-dashboard-wrap order-management">
      <div className="title">
        <h3>Order Management</h3>
      </div>
      <div className="vd-dashboard-body">
        <div className="order_chart_warp box-size">
          <div className="order_chart">
            <Chart
              width={"100%"}
              height={"300px"}
              chartType="Bar"
              loader={<div>Loading Chart</div>}
              data={orderGraph}
              options={{
                // Material design options
                chart: {
                  title: "Orders",
                  // subtitle: "Sales, Expenses, and Profit: 2014-2017",
                },
              }}
              // For tests
              rootProps={{ "data-testid": "1" }}
            />
          </div>
        </div>
        <div className="all_orders box-size">
          <div className="data-table">
            <MaterialTable
              title="All Orders"
              columns={orderTitle}
              icons={tableIcons}
              options={{
                search: true,
                // exportButton: true,
                thirdSortClick: false,
                pageSize: 10,
                debounceInterval: 500,
                pageSizeOptions: [5, 10, 20],
              }}
              localization={{
                body: {
                  emptyDataSourceMessage: "No records to display",
                },
              }}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let params = `?vendor_id=${user?.id}`;
                  params += `&page=${query.page + 1}`;
                  params += `&limit=${query.pageSize}`;
                  params += `&search=${query.search}`;

                  if (
                    query.orderBy &&
                    query.orderBy.field &&
                    query.orderDirection
                  ) {
                    params += `&direction=${query.orderDirection}`;
                    params += `&sort=${query.orderBy.field}`;
                  }

                  if (user) {
                    // prepare your data and then call resolve like this:
                    console.log(params);
                    getOrderListByVendorIdApi(params).then((res) => {
                      let { data, status } = res;
                      if (status === 200) {
                        resolve({
                          data: data?.data?.data,
                          page: data?.data?.current_page - 1, // current page number
                          totalCount: data?.data?.total, // total row number
                        });
                      }
                    });
                  }
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderManagement;
