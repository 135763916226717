import React, { useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import "./Address.scss";
import logo from "../../assets/img/golden-logo.png";
import { useState } from "react";
import AllCountry from "../../Utilis/allCountry.json";
import { stateApi } from "../../api";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_ADDRESS_BY_USER_ID,
  globalAction,
  SHOW_LOADING,
  UPDATE_ADDRESS_BY_ADDRESS_ID,
} from "../../redux";
import Loading from "../../components/Loading/Loading";

function AddressModal(props) {
  const { modalVisible, modalToggleHandle, isUpdateModal, dataForUpdate } =
    props;
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);
  const [setAsDefaultAddress, setSetAsDefaultAddress] = useState(false);
  const [states, setStates] = useState([]);
  const fromRef = useRef();
  const { user } = useSelector((state) => state.authReducer);
  const { isLoading, message } = useSelector((state) => state.errorReducer);
  const dispatch = useDispatch();

  const getStateByCountryId = async (id) => {
    await stateApi(id).then(async (res) => {
      if (res.status === 200) {
        await setStates(res.data.state_list);
      }
    });
  };

  const changeText = (value, fieldName) => {
    setFormData({ ...formData, [fieldName]: value });
    if (fieldName === "country") {
      getStateByCountryId(value);
    }
  };

  const handleSubmit = (e, formType) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      if (isUpdateModal) {
        // its submit update address form data
        let finalData = {
          ...formData,
          user_id: user?.id,
          id: dataForUpdate?.id,
          default_address: setAsDefaultAddress ? "yes" : "no",
        };
        dispatch(globalAction(SHOW_LOADING));
        dispatch(globalAction(UPDATE_ADDRESS_BY_ADDRESS_ID, finalData));
      } else {
        // its submit the add new address from data
        let finalData = {
          ...formData,
          user_id: user?.id,
          default_address: setAsDefaultAddress ? "yes" : "no",
        };
        dispatch(globalAction(SHOW_LOADING));
        dispatch(globalAction(ADD_ADDRESS_BY_USER_ID, finalData));
      }
    }
    setValidated(true);
  };

  const handleClose = (e) => {
    modalToggleHandle();
    setFormData({});
    fromRef.current.reset();
  };

  useEffect(() => {
    if (
      message === "Address added successfully" ||
      message === "Address updated successfully"
    ) {
      modalToggleHandle();
    }
    if (isUpdateModal && dataForUpdate?.country) {
      // set default data when we open modal for update address
      setFormData(dataForUpdate);
      getStateByCountryId(Number(dataForUpdate?.country));
      setSetAsDefaultAddress(
        dataForUpdate?.default_address === "yes" ? true : false
      );
    }
  }, [message, isUpdateModal, dataForUpdate]);

  return (
    <div className="address-modal">
      <div className="address-modal-inner ">
        <>
          <Loading showLoading={isLoading} />

          <Modal
            show={modalVisible}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="cm-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <img src={logo} alt="Du-Afrik" />
                <h3>{isUpdateModal ? "Update Address" : "Add Address"}</h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                noValidate
                validated={validated}
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
                ref={fromRef}
              >
                <Row>
                  <Col md>
                    <Form.Group className="mb-4" controlId="full_name">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter full name"
                        className="input"
                        defaultValue={formData?.full_name}
                        onChange={(e) =>
                          changeText(e.target.value, "full_name")
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md>
                    <Form.Group className="mb-4" controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email "
                        className="input"
                        defaultValue={formData?.email}
                        onChange={(e) => changeText(e.target.value, "email")}
                      />
                      <Form.Text id="Companywebsiturl" muted>
                        (Its optional)
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md>
                    <Form.Group className="mb-4" controlId="mobile_no">
                      <Form.Label>Mobile Number </Form.Label>
                      <Form.Control
                        required
                        type="tel"
                        placeholder="Enter  mobile number"
                        className="input"
                        defaultValue={formData?.mobile_no}
                        onChange={(e) =>
                          changeText(e.target.value, "mobile_no")
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md>
                    <Form.Group className="mb-4" controlId="Country">
                      <Form.Label>Country </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        required
                        className="input"
                        onLoad={() => {
                          dataForUpdate?.country &&
                            getStateByCountryId(Number(dataForUpdate?.country));
                        }}
                        onChange={(e) => changeText(e.target.value, "country")}
                      >
                        <option>Select country</option>
                        {AllCountry.map((indCountry, i) => {
                          return (
                            <option
                              key={indCountry.name}
                              value={indCountry.id}
                              selected={formData?.country == indCountry.id}
                            >
                              {indCountry.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md>
                    <Form.Group className="mb-4" controlId="state">
                      <Form.Label>State </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        required
                        type="text"
                        className="input"
                        aria-readonly={states.length > 0 ? false : true}
                        disabled={states.length > 0 ? false : true}
                        onChange={(e) => changeText(e.target.value, "state")}
                      >
                        <option>Select state</option>
                        {states.map((item, id) => {
                          return (
                            <option
                              key={item.name}
                              value={item.id}
                              selected={formData?.state == item.id}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md>
                    <Form.Group className="mb-4" controlId="zip">
                      <Form.Label>Zip Code </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter  zip code"
                        className="input"
                        defaultValue={formData?.zip}
                        onChange={(e) => changeText(e.target.value, "zip")}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="mb-4" controlId="address_line_1">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    required={true}
                    type="text"
                    placeholder="Enter address"
                    as="textarea"
                    style={{ height: "100px" }}
                    className="input"
                    defaultValue={formData?.address_line_1}
                    onChange={(e) =>
                      changeText(e.target.value, "address_line_1")
                    }
                  />
                </Form.Group>

                <Form.Group className="mb-4" controlId="address_line_2">
                  <Form.Label>Additional Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter additional address"
                    as="textarea"
                    style={{ height: "100px" }}
                    className="input"
                    defaultValue={
                      formData?.address_line_2 === "null"
                        ? ""
                        : formData?.address_line_2
                    }
                    onChange={(e) =>
                      changeText(e.target.value, "address_line_2")
                    }
                  />
                  <Form.Text id="Companywebsiturl" muted>
                    (Its optional)
                  </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="default_address">
                  <Form.Check
                    type="checkbox"
                    checked={setAsDefaultAddress}
                    value={setAsDefaultAddress ? "yes" : "no"}
                    label="set default address as delivery address"
                    defaultValue={formData?.default_address}
                    onChange={(e) =>
                      setSetAsDefaultAddress(!setAsDefaultAddress)
                    }
                  />
                </Form.Group>

                <Modal.Footer>
                  <Button
                    variant="secondary"
                    className="btn-black outline"
                    onClick={(e) => {
                      handleClose();
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    className="btn-black "
                    variant="primary"
                    type="submit"
                  >
                    {isUpdateModal ? "Edit" : "Add"}
                  </Button>
                </Modal.Footer>
                {/* <Button variant="primary" type="submit" className="btn-brown">
                                Submit & Next
                            </Button> */}
              </Form>
            </Modal.Body>
          </Modal>
        </>
      </div>
    </div>
  );
}

export default AddressModal;
