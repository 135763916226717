import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { vendorNotificationApi } from '../../../api';
import { globalAction, SET_VENDOR_NOTIFICATIONS } from '../../../redux';

function GetNotification(props) {
    const { user } = props
    const dispatch = useDispatch()


    const getNotifications = (params) => {
        vendorNotificationApi(params)
            .then((res) => {
                const { status, data } = res
                if (status === 200) {
                    dispatch(globalAction(SET_VENDOR_NOTIFICATIONS, data.data.data))
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (user?.role_id === 3) {
            let params = `?vendor_id=${user?.id}`
            getNotifications(params)
        }
    }, [window.location.href]);

    
    return (<></>)
        ;
}

export default GetNotification;
