import React from "react";
import Slider from "react-slick";
import { GrNext, GrPrevious } from "react-icons/gr";
import { Link } from "react-router-dom";

function Slider1(props) {
  const { title, data, items } = props;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <GrNext />,
    prevArrow: <GrPrevious />,
    autoplay: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div>
      {/* <div className="slider-heading-wrap ">
                <h2>{title} </h2>
            </div> */}
      {data && data.length > 1 ? (
        <Slider {...settings}>
          {data !== undefined &&
            data.map((item, id) => {
              if (item?.page !== "login" && item?.page !== "register") {
                return (
                  <div className="slider-img-wrap">
                    <a
                      href={`/${item?.link}`}
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(item?.link, "_blank");
                      }}
                    >
                      <img
                        src={item.image}
                        alt={item?.title}
                        className="slider-one-item"
                      />
                    </a>
                  </div>
                );
              }
            })}
        </Slider>
      ) : (
        <>
          {data !== undefined &&
            data.map((item, id) => {
              if (item?.page !== "login" && item?.page !== "register") {
                return (
                  <div className="slider-img-wrap ">
                    <a
                      href={`/${item?.link}`}
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(item?.link, "_blank");
                      }}
                    >
                      <img src={item.image} alt={item?.title} />
                    </a>
                  </div>
                );
              }
            })}
        </>
      )}
    </div>
  );
}

export default Slider1;
