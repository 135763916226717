import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route } from "react-router-dom";
import NotFound from '../../../components/Not-found/NotFound';
import ResetPassword from '../../Dashboard/ResetPassword';
import SellingCountries from '../UpdateKYC/SellingCountries';
import UpdateCompany from '../UpdateKYC/UpdateCompany';
import UpdateKYC from '../UpdateKYC/UpdateKYC';
import UpdatePaymentInfo from '../UpdateKYC/updatePaymentInfo';
import DashboardSummary from './DashboardSummary/DashboardSummary';
import FirstTimeDetailForm from './FirstTimeDetailForm/FirstTimeDetailForm';
import CancelledOrder from './OrderManagement/CancelledOrder';
import OrderManagement from './OrderManagement/OrderManagement';
import ReturnedOrder from './OrderManagement/ReturnedOrders';
import AddnewProduct from './ProductManagement/AddProduct/AddProducts';
import ProductVariant from './ProductManagement/ProductVarient/ProductVariant';
import ViewAllVariantOfProduct from './ProductManagement/ViewAllVariantOfProduct/ViewAllVariantOfProduct';
import ViewProduct from './ProductManagement/ViewProducts/ViewProduct';
import Profile from './Profile/Profile';
import UserRejectedPage from './UserRejectedPage/UserRejectedPage';
import Wallet from './Wallet/Wallet';
import WalletTransaction from './Wallet/WalletTransaction';
import OrderDetails from '../../Dashboard/OrderManagement/OrderDetails'
import { GET_VENDOR_DETAILS, globalAction } from '../../../redux';
import YourStore from '../UpdateKYC/YourStore';
import ShippingInfo from './ShippingInfo/ShippingInfo';
import FeedbackOrder from './OrderManagement/FeedbackOrder';
import GetNotification from './GetNotification';




function DashboardRouter() {
  const { user } = useSelector(state => state.authReducer)
  const dispatch = useDispatch()
  useEffect(() => {
    if (user) {
      dispatch(globalAction(GET_VENDOR_DETAILS, user?.id))
    }
  }, [])
  return (
    <Switch>
      {/* <Route path="/vendorDashboard" component={FirstTimeDetailForm} /> */}
      {user?.role_id === 3 && user?.kyc_verified === 1 ? (  // Profile are verified
        <>
          <GetNotification user={user} />
          <Route exact user={user} path="/vendorDashboard/order-management" component={OrderManagement} />
          <Route exact path="/vendorDashboard/order-management/cancelled-orders" component={CancelledOrder} />
          <Route exact path="/vendorDashboard/order-management/feedback-orders" component={FeedbackOrder} />
          <Route exact path="/vendorDashboard/order-management/returned-orders" component={ReturnedOrder} />
          <Route exact path="/vendorDashboard/account-management" component={Profile} />
          <Route exact path="/vendorDashboard/profile" component={Profile} />
          <Route exact path="/vendorDashboard/change-password" component={ResetPassword} />
          <Route exact path="/vendorDashboard/product-management" component={ViewProduct} />
          <Route exact path="/vendorDashboard/product-management/add-product" component={AddnewProduct} />
          <Route exact path="/vendorDashboard/product-management/update-product/:slug" component={AddnewProduct} />
          <Route exact path="/vendorDashboard/product-management/product-variants" component={ProductVariant} />
          <Route exact path="/vendorDashboard/product-management/product-variants/:slug" component={ViewAllVariantOfProduct} />
          <Route exact path="/vendorDashboard" component={DashboardSummary} />
          <Route exact path="/vendorDashboard/update-company" component={UpdateCompany} />
          <Route exact path="/vendorDashboard/update-payment-Info" component={UpdatePaymentInfo} />
          <Route exact path="/vendorDashboard/selling-countries" component={SellingCountries} />
          <Route exact path="/vendorDashboard/wallet" component={Wallet} />
          <Route exact path="/vendorDashboard/wallet/transaction" component={WalletTransaction} />
          <Route exact path="/vendorDashboard/order-detail/:id" component={OrderDetails} />
          <Route exact path="/vendorDashboard/store" component={YourStore} />
          <Route exact path='/vendorDashboard/shipping-information' component={ShippingInfo} />


          {/* <Route exact path="/vendorDashboard/update-KYC" component={UpdateKYC} /> */}
        </>
      ) : (
        <>
          {user?.kyc_verified === 2 ? // Profile are rejected
            <>
              <Route path="/vendorDashboard" component={UserRejectedPage} />
              {/* <Route exact path="/vendorDashboard/update-company" component={UpdateCompany} />
              <Route exact path="/vendorDashboard/update-payment-Info" component={UpdatePaymentInfo} /> */}
              {/* <Route exact path="/vendorDashboard/update-KYC" component={UpdateKYC} /> */}
            </>
            : // Profile are pending for approval
            <Route path="/vendorDashboard" component={FirstTimeDetailForm} />

          }


        </>
      )}

      <Route component={NotFound} />
    </Switch>
  );
}

export default DashboardRouter
