import React, { memo, useEffect, useRef, useState } from "react";
import "./OrderSummary.scss";
import emptyProductImg from "../../assets/img/emptyProduct.png";
import { FaMinus, FaPlus, FaTrashAlt } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  ADD_TO_CART,
  BOOK_ORDER_BY_USER,
  DELETE_CART_ITEM,
  globalAction,
  SHOW_LOADING,
  UPDATE_CART_ITEM_QTY,
} from "../../redux";
import { useDispatch } from "react-redux";
import { AiFillEdit } from "react-icons/ai";
import Loading from "../../components/Loading/Loading";
import Paypal from "../../components/OnlinePayment/Paypal";
import { Button, Modal } from "react-bootstrap";
import { getPaymentSettingApi } from "../../api";
import PhoneInput from "react-phone-input-2";
import { getLatLngByPostalCode } from "../../config/getLatLngByPostalCode";
import distanceBetweenTwoLatLng from "../../config/distanceBetweenTwoLatLng";
import axiosServices from "../../Utilis/axios";

function OrderSummary(props) {
  const dispatch = useDispatch();
  const { cartList, cartTotal, savedAddressList } = useSelector(
    (state) => state.appReducer
  );
  const [buyNowCartTotal, setBuyNowCartTotal] = useState(undefined);
  const { isLoading } = useSelector((state) => state.errorReducer);
  const [ShippingInfo, setShippingInfo] = useState(
    JSON.parse(localStorage.getItem("ShippingInfo"))
  );
  const { user } = useSelector((state) => state.authReducer);
  const [orderAsGuest, setOrderAsGuest] = useState(false);
  const [paymentOption, setPaymentOption] = useState(undefined);
  const [modalVisible, setModalVisible] = useState(false);
  const [guestData, setGuestData] = useState({ user_type: "guest" });
  const getQty = useRef();
  const { history } = props;
  const [paymentSetting, setPaymentSetting] = useState(undefined);
  const [finalCartList, setFinalCartList] = useState([]);
  const [codError, setCodError] = useState(false);
  const [customerLocation, setCustomerLocation] = useState({});
  const [sellerLocation, setSellerLocation] = useState({});
  const [shippingCost, setShippingCost] = useState(null);
  const [distance, setDistance] = useState(null);
  const [productsWeight, setProductsWeight] = useState(null);
  const [checkCountry, setCheckCountry] = useState(null);
  const [orderTotal, setOrderTotal] = useState(null);
  const [orderSubTotal, setOrderSubTotal] = useState(null);

  const getPaymentSetting = () => {
    getPaymentSettingApi().then((res) => {
      const { status, data } = res;
      if (status === 200) {
        setPaymentSetting(data.payment_setting);
      }
    });
  };

  useEffect(() => {
    setOrderSubTotal(cartTotal);
    if (cartTotal && shippingCost) {
      setOrderTotal(
        Number(Number(cartTotal).toFixed(2)) +
          Number(Number(shippingCost).toFixed(2))
      );
    }
  }, [shippingCost, cartTotal]);
  const handleChangeQty = (e, typeOfChange, i) => {
    let tempQty = Number(getQty.current.innerText);
    if (typeOfChange === "minus" && tempQty > 0) {
      tempQty = finalCartList[i]["qty"] - 1;
      finalCartList[i]["qty"] = tempQty;
    } else {
      tempQty = finalCartList[i]["qty"] + 1;
      finalCartList[i]["qty"] = tempQty;
    }

    //  check user comes from BuyNow or cart
    if (localStorage.getItem("buyNow")) {
      setFinalCartList([...finalCartList]);
    } else {
      dispatch(globalAction(SHOW_LOADING));
      dispatch(
        globalAction(UPDATE_CART_ITEM_QTY, {
          cartList: finalCartList,
          user: user,
        })
      );
    }
  };
  const handleDelete = (e, Item, i) => {
    if (window.confirm("Are you sure to remove product from your cart ?")) {
      dispatch(globalAction(SHOW_LOADING));
      finalCartList.splice(i, 1);
      //  check user comes from BuyNow or cart
      if (localStorage.getItem("buyNow")) {
        props.history.push("/");
      } else {
        dispatch(
          globalAction(DELETE_CART_ITEM, {
            cartList: finalCartList,
            item: Item,
            user: user,
            showMessage: true,
          })
        );
      }
    }
  };

  const handlePlaceOrder = (e) => {
    e.preventDefault();
    let order_product = [];
    finalCartList.forEach((item) => {
      const { id, qty, tax_included_sale_price, tax_included_regular_price } =
        item;
      let price = item.tax_included_regular_price;
      if (item.tax_included_sale_price !== null) {
        price = item.tax_included_sale_price;
      }
      order_product.push({
        product_id: id,
        qty: qty,
        single_price:
          tax_included_sale_price !== null
            ? tax_included_sale_price
            : tax_included_regular_price,
        total_price: Number(price) * Number(qty),
      });
    });

    let finalOrderObject = {
      order_duration: 5,
      order_quantity: finalCartList.length,
      order_price: localStorage.getItem("buyNow") // For Buy Now
        ? finalCartList[0]?.tax_included_sale_price !== null
          ? Number(
              finalCartList[0]?.tax_included_sale_price * finalCartList[0]?.qty
            ).toFixed(2)
          : Number(
              finalCartList[0]?.tax_included_regular_price *
                finalCartList[0]?.qty
            ).toFixed(2)
        : Number(cartTotal).toFixed(2),
      payment_type: "cod",
      order_status: "Pending",
      shipping_addess:
        ShippingInfo?.shipping_addess?.id || localStorage.getItem("shippingId"),
      billing_address:
        ShippingInfo?.billing_address?.id ||
        localStorage.getItem("billingId") ||
        localStorage.getItem("shippingId"),
      order_product,
      gst_billing: "no",
      user_type: "user",
      shiping_fee: 200,
    };

    if (user) {
      finalOrderObject = { ...finalOrderObject, user_id: user?.id };

      dispatch(globalAction(SHOW_LOADING));
      dispatch(
        globalAction(BOOK_ORDER_BY_USER, {
          finalOrderObject: finalOrderObject,
          history: history,
        })
      );
    } else {
      finalOrderObject = { ...finalOrderObject, ...guestData };
      dispatch(globalAction(SHOW_LOADING));

      dispatch(
        globalAction(BOOK_ORDER_BY_USER, {
          finalOrderObject: finalOrderObject,
          history: history,
        })
      );
    }
  };

  const modalToggle = (e, isCancel) => {
    e?.preventDefault();
    setModalVisible(!modalVisible);
    if (isCancel) {
      history.push("/");
    } else {
      for (const key in guestData) {
        if (guestData[key] === "") {
          setModalVisible(true);
        }
      }
    }
  };

  useEffect(() => {
    if (history.location.pathname === "/order-summary-as-guest") {
      setModalVisible(true);
      setOrderAsGuest(true);
    }
    getPaymentSetting();
    if (localStorage.getItem("buyNow")) {
      setFinalCartList(JSON.parse(localStorage.getItem("buyNow"))); // set buyNow cart items
      let TempBuyNowCartTotal = 0;
      if (
        JSON.parse(localStorage.getItem("buyNow"))[0]
          ?.tax_included_sale_price !== null
      ) {
        TempBuyNowCartTotal = Number(
          JSON.parse(localStorage.getItem("buyNow"))[0]
            ?.tax_included_sale_price *
            JSON.parse(localStorage.getItem("buyNow"))[0]?.qty
        ).toFixed(2);
      } else {
        TempBuyNowCartTotal = Number(
          JSON.parse(localStorage.getItem("buyNow"))[0]
            ?.tax_included_regular_price *
            JSON.parse(localStorage.getItem("buyNow"))[0]?.qty
        ).toFixed(2);
      }
      setBuyNowCartTotal(TempBuyNowCartTotal); // set buyNow cart Total
    } else {
      setFinalCartList(cartList);
    }

    // check Cod Availability
    if (
      paymentSetting?.cod_enable === "yes" &&
      paymentSetting?.cod_minimum_price_limit <
        Number(cartTotal || buyNowCartTotal)
    ) {
      setCodError(false);
    } else {
      setCodError(true);
    }
  }, []);

  const handleInputChange = (value, fieldName) => {
    if (value.trim !== "") {
      setGuestData({ ...guestData, [fieldName]: value });
    }
  };

  const CaputreGuestInfoModal = () => {
    return (
      <div className="cancel-order">
        <Modal
          show={modalVisible}
          onHide={(e) => {
            modalToggle(e, "Cancelled");
          }}
          backdrop="static"
          keyboard={true}
          className="cancel-order-inner guest-info"
        >
          <Modal.Header closeButton>
            <Modal.Title>Place Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Enter your details here carefully.
            <em>
              <small>(all fields are required)</small>
            </em>
            <div className="cancel-reason-wrap ">
              <form
                onSubmit={(e) => {
                  modalToggle(e);
                }}
              >
                <label htmlFor="name">Full Name</label>
                <input
                  required
                  type="text"
                  className="input"
                  defaultValue={guestData?.name}
                  placeholder="Enter your full email"
                  onChange={(e) => {
                    handleInputChange(e.target.value, "name");
                  }}
                />
                <label htmlFor="email">Email</label>
                <input
                  required
                  type="mobile"
                  className="input"
                  defaultValue={guestData?.email}
                  placeholder="Enter your email"
                  onChange={(e) => {
                    handleInputChange(e.target.value, "email");
                  }}
                />
                <label htmlFor="mobile">Mobile</label>

                <PhoneInput
                  country="us"
                  fullWidth
                  name="phone_number"
                  id="phone_number"
                  inputStyle={{
                    border: "none",
                    background: "#ffffff",
                    height: "33px",
                    paddingTop: "15px",
                    width: "100%",
                    boxShadow: "none",
                    outline: "none",
                  }}
                  containerStyle={{
                    border: "1px solid #000000",
                    height: "35px",
                    background: "#ffffff",
                    boxShadow: "none",
                  }}
                  value={guestData?.mobile}
                  onChange={(value) => handleInputChange(value, "mobile")}
                />
                {/* <input
                  type="text"
                  className="input"
                  placeholder="Enter your mobile"
                  defaultValue={guestData?.mobile}
                  onChange={(e) => {
                    handleInputChange(e.target.value, "mobile");
                  }}
                /> */}
                <Modal.Footer>
                  <Button
                    variant="primary"
                    onClick={(e) => modalToggle(e, "Cancelled")}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="danger"
                    type="submit"
                    // onClick={() => { modalToggle() }}
                  >
                    Proceed to Order
                  </Button>
                </Modal.Footer>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  // GET LATITUDE AND LONGITUDE BY POSTAL CODE

  useEffect(() => {
    if (ShippingInfo && ShippingInfo?.shipping_addess?.zip) {
      getLatLngByPostalCode(ShippingInfo?.shipping_addess?.zip).then((res) => {
        setCustomerLocation(res.data.results[0]?.geometry?.location);
      });
    }
  }, [ShippingInfo]);

  // CALCULATE TOTAL PRODUCTS WEIGHT, GET LAT & LNG OF SELLER AND CHECK COUNTRY

  useEffect(() => {
    if (finalCartList.length > 0)
      getLatLngByPostalCode(finalCartList[0]?.user?.pincode).then((res) => {
        setSellerLocation(res.data.results[0]?.geometry?.location);
        const totalWeight = finalCartList
          ?.map((item) => Number(item?.weight) * Number(item?.qty))
          ?.reduce((a, b) => Number(a) + Number(b));
        setProductsWeight(totalWeight);
        const checkCountry =
          Number(ShippingInfo?.shipping_addess?.country) ===
          Number(finalCartList[0]?.user?.country_id);
        setCheckCountry(checkCountry ? "inside" : "outside");
      });
  }, [finalCartList]);

  // CALCULATE DISTANCE BETWEEN SELLER AND A CUSTOMER

  useEffect(() => {
    if (customerLocation && sellerLocation) {
      const distanceInKm = distanceBetweenTwoLatLng(
        sellerLocation?.lat,
        sellerLocation?.lng,
        customerLocation?.lat,
        customerLocation?.lng
      );
      setDistance(Number(distanceInKm).toFixed(2));
    }
  }, [customerLocation, sellerLocation]);

  // CALCULATE THE SHIPPING FEE FOR PRODUCTS

  const getShippingFee = (body) => {
    axiosServices
      .post("/api/shipping-fee", body)
      .then((res) => {
        if (res?.status === 200) {
          setShippingCost(res?.data?.shipping_fee);
        }
      })
      .catch((err) => alert(err?.message || "Something wents wrong!"));
  };
  useEffect(() => {
    if (productsWeight && checkCountry && distance !== "NaN") {
      const body = {
        total_weight: productsWeight,
        country: checkCountry,
        distance: Number(distance),
      };
      setTimeout(() => {
        getShippingFee(body);
      }, 2000);
    }
  }, [productsWeight, checkCountry, distance, finalCartList]);

  return (
    <section className="orderSummary spacing">
      <Loading showLoading={isLoading} />
      {CaputreGuestInfoModal()}

      {finalCartList && finalCartList.length > 0 ? (
        <div className="container">
          <div className="content_left">
            <div className="title">
              <h3>Review Your Delivery, Biliing Address &amp; Orders.</h3>
              <p>
                By placing your order, you agree to Du Afrik policies and
                agreements
              </p>
            </div>
            {}
            {guestData?.name && guestData?.email && guestData?.mobile && (
              <div className="shipping-details guest-details">
                <div className="shipping-action">
                  <a
                    onClick={(e) => {
                      modalToggle(e);
                    }}
                  >
                    <AiFillEdit />
                    Change
                  </a>
                </div>

                <div className="shipping-item delivery">
                  <h5>
                    Your Details
                    <em>
                      <small>(as a guest)</small>
                    </em>
                  </h5>
                  <h6>Name :{guestData?.name}</h6>
                  <h6>Email :{guestData?.email}</h6>
                  <h6>Mobile :{guestData?.mobile}</h6>
                  <br></br>
                  <small>
                    <em>By this information you track your order</em>
                  </small>
                </div>
              </div>
            )}
            <div className="shipping-details">
              {!orderAsGuest && (
                <div className="shipping-action">
                  <Link to="/select-Address">
                    <AiFillEdit />
                    Change
                  </Link>
                </div>
              )}

              {ShippingInfo.shipping_addess && (
                <div className="shipping-item delivery">
                  <h4>Delivery Address</h4>
                  <h6>{ShippingInfo?.shipping_addess?.full_name}</h6>
                  <p>{`${ShippingInfo?.shipping_addess?.address_line_1}, ${ShippingInfo?.shipping_addess?.state_data?.name}(${ShippingInfo?.shipping_addess?.zip}), ${ShippingInfo?.shipping_addess?.country_data?.name}`}</p>
                  {ShippingInfo?.shipping_addess?.address_line_2 && (
                    <p>
                      {`${ShippingInfo?.shipping_addess?.address_line_2}, ${ShippingInfo?.shipping_addess?.state_data?.name}(${ShippingInfo?.shipping_addess?.zip}), ${ShippingInfo?.shipping_addess?.country_data?.name}`}{" "}
                      <em>(Alternate Address)</em>
                    </p>
                  )}
                  <p>{`+${ShippingInfo?.shipping_addess?.country_data?.phonecode}-${ShippingInfo?.shipping_addess?.mobile_no}`}</p>
                </div>
              )}
              {ShippingInfo.billing_address && (
                <div className="shipping-item billing">
                  <h4> Billing Address</h4>
                  <h6>{ShippingInfo?.billing_address?.full_name}</h6>
                  <p>{`${ShippingInfo?.billing_address?.address_line_1}, ${ShippingInfo?.billing_address?.state_data?.name}(${ShippingInfo?.billing_address?.zip}), ${ShippingInfo?.billing_address?.country_data?.name}`}</p>
                  {ShippingInfo?.billing_address?.address_line_2 && (
                    <p>
                      {`${ShippingInfo?.billing_address?.address_line_2}, ${ShippingInfo?.billing_address?.state_data?.name}(${ShippingInfo?.billing_address?.zip}), ${ShippingInfo?.billing_address?.country_data?.name}`}{" "}
                      <em>(Alternate Address)</em>
                    </p>
                  )}
                  <p>{`+${ShippingInfo?.billing_address?.country_data?.phonecode}-${ShippingInfo?.billing_address?.mobile_no}`}</p>
                </div>
              )}
            </div>

            <div className="ship_product">
              {finalCartList &&
                finalCartList.map((item, i) => {
                  const {
                    slug,
                    title,
                    created_at,
                    id,
                    image_url,
                    image,
                    qty,
                    tax_included_regular_price,
                    tax_included_sale_price,
                  } = item;
                  return (
                    <div className="item" key={created_at}>
                      <table>
                        <tr>
                          <th>Image</th>
                          <th>Name</th>
                          <th>QTY</th>
                          <th>ITEM TOTAL</th>
                          <th>REMOVE</th>
                        </tr>
                        <tr>
                          <td>
                            <Link to={`/product-detail/${slug}`}>
                              <img
                                src={image_url || image}
                                alt={title ? title : slug}
                              />
                            </Link>
                          </td>
                          <td>
                            <Link to={`/product-detail/${slug}`}>
                              <h6>
                                {title ? title : slug?.split("-")?.join(" ")}
                              </h6>
                            </Link>
                            {tax_included_sale_price !== null ? (
                              <p>
                                {tax_included_sale_price !==
                                  tax_included_regular_price && (
                                  <del>
                                    {Number(tax_included_regular_price).toFixed(
                                      2
                                    )}
                                    $
                                  </del>
                                )}
                                <span>
                                  {Number(tax_included_sale_price).toFixed(2)}$
                                </span>
                              </p>
                            ) : (
                              <span>{tax_included_regular_price}$</span>
                            )}
                          </td>
                          <td>
                            {/* <td className="button"> */}
                            <div className="qty">
                              {/* {qty > 1 && (
                                <span
                                  className="minu"
                                  onClick={(e) =>
                                    handleChangeQty(e, "minus", i)
                                  }
                                >
                                  <FaMinus />
                                </span>
                              )} */}
                              <span className="num" ref={getQty}>
                                {qty}
                              </span>
                              {/* {Number(qty) < Number(item.stock_qty) && (
                                <span
                                  className="plus"
                                  onClick={(e) => handleChangeQty(e, "plus", i)}
                                >
                                  <FaPlus />
                                </span>
                              )} */}
                            </div>
                          </td>
                          <td>
                            <span>
                              {Number(tax_included_sale_price) * Number(qty)}
                              &nbsp;$
                              {/* {(
                                Number(tax_included_sale_price) * Number(qty)
                              ).toFixed(2)}{" "}
                              $ */}
                            </span>
                            {/* {tax_included_sale_price !== null ? (
                              <span>
                                {(
                                  Number(tax_included_sale_price) * Number(qty)
                                ).toFixed(2)}{" "}
                                $
                              </span>
                            ) : (
                              <span>
                                {(
                                  Number(tax_included_regular_price) *
                                  Number(qty)
                                ).toFixed(2)}
                                $
                              </span>
                            )} */}
                          </td>
                          <td>
                            <span
                              onClick={(e) => {
                                handleDelete(e, item, i);
                              }}
                            >
                              <FaTrashAlt />
                            </span>
                          </td>
                        </tr>
                      </table>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="content_right">
            <div className="content-inner">
              <h5>Order Summary ({finalCartList && finalCartList.length})</h5>

              <ul>
                <li>
                  <span>Subtotal:</span>

                  {localStorage.getItem("buyNow") ? (
                    <span>
                      {Number(finalCartList[0]?.tax_included_sale_price) *
                        Number(finalCartList[0]?.qty)}
                    </span>
                  ) : (
                    // <span>
                    //   {finalCartList[0]?.tax_included_sale_price !== null // For Buy Now
                    //     ? Number(
                    //         finalCartList[0]?.tax_included_sale_price *
                    //           finalCartList[0]?.qty
                    //       ).toFixed(2)
                    //     : Number(
                    //         finalCartList[0]?.tax_included_regular_price *
                    //           finalCartList[0]?.qty
                    //       ).toFixed(2)}
                    //   $
                    // </span>
                    // <span>{Number(cartTotal).toFixed(2)}$</span>
                    <span>{Number(orderSubTotal)} $</span>
                  )}
                </li>
                <li>
                  <span>Shipping cost:</span>
                  {/* <span>{Number(shippingCost).toFixed(2)}$</span> */}
                  {shippingCost && <p>{`${shippingCost} $`}</p>}
                  {!shippingCost && <p>Calculating...</p>}
                </li>
                <li>
                  <div className="d-flex flex-column">
                    <b>Order Total:</b>
                    <small>
                      <i>(included all taxes)</i>
                    </small>
                  </div>
                  {localStorage.getItem("buyNow") ? (
                    <span>
                      {finalCartList[0]?.tax_included_sale_price !== null // For Buy Now
                        ? Number(
                            Number(
                              finalCartList[0]?.tax_included_sale_price *
                                finalCartList[0]?.qty
                            ).toFixed(2)
                          ) + Number(Number(shippingCost).toFixed(2))
                        : Number(
                            Number(
                              finalCartList[0]?.tax_included_regular_price *
                                finalCartList[0]?.qty
                            ).toFixed(2)
                          ) + Number(Number(shippingCost).toFixed(2))}{" "}
                      $
                    </span>
                  ) : (
                    <>
                      {orderTotal && <b>{`${orderTotal} $`}</b>}
                      {!orderTotal && <p>Calculating...</p>}
                    </>
                  )}
                </li>
              </ul>
            </div>
            <div className="payment-choice">
              {/* <div className="payment-invoice">
                                <input
                                    type="radio"
                                    name="paymentChoice"
                                    id="cod"
                                    disabled={codError}
                                    onChange={() => {  setPaymentOption('cod')  }}
                                />
                                <label htmlFor="cod">Pay on delivery</label>
                            </div>

                            {(codError && (paymentSetting?.cod_minimum_price_limit - Number(cartTotal || buyNowCartTotal))) &&
                                <div style={{ color: '#d92a2a' }} className="cod-alert">
                                    <p>Add <strong>${paymentSetting?.cod_minimum_price_limit - Number(cartTotal || buyNowCartTotal)}</strong> of eligible items to your order to quality for Cash on Delivery</p>
                                </div>} */}

              {paymentSetting?.paypal_enable === "yes" && (
                <div className="payment-invoice">
                  <input
                    type="radio"
                    name="paymentChoice"
                    id="onlinePayment"
                    onChange={() => {
                      setPaymentOption("OnlinePayment");
                    }}
                  />
                  <label htmlFor="onlinePayment">
                    Credit Card / Debit Card / Net Banking
                  </label>
                </div>
              )}
            </div>
            {cartList && paymentOption && ShippingInfo && (
              <div className="button_wrap">
                {paymentOption === "cod" ? (
                  <a
                    onClick={(e) => {
                      handlePlaceOrder(e);
                    }}
                  >
                    Pay & Place Order
                  </a>
                ) : (
                  <Paypal
                    orderInfo={{
                      cartList: finalCartList,
                      cartTotal: localStorage.getItem("buyNow")
                        ? finalCartList[0]?.tax_included_sale_price !== null
                          ? // For Buy Now
                            Number(
                              finalCartList[0]?.tax_included_sale_price *
                                finalCartList[0]?.qty
                            ).toFixed(2)
                          : Number(
                              Number(
                                finalCartList[0]?.tax_included_regular_price *
                                  finalCartList[0]?.qty
                              ).toFixed(2)
                            ) + Number(Number(shippingCost).toFixed(2))
                        : Number(Number(cartTotal).toFixed(2)) +
                          Number(Number(shippingCost).toFixed(2)),
                      ShippingInfo: ShippingInfo,
                      shiping_fee: shippingCost,
                      user: user,
                      history: history,
                      guestData: guestData,
                      orderAsGuest: orderAsGuest,
                      clientId: paymentSetting?.paypal_client_secret,
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="container no-product">
          <div className="empty-product-img">
            <img src={emptyProductImg} alt="No Product Found" />
          </div>

          <h1>No Product found...!</h1>
        </div>
      )}
    </section>
  );
}

export default memo(OrderSummary);
