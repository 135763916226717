import React from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import './Slider4.scss'
import { FaRegCalendarAlt,FaComments, } from "react-icons/fa";
import { GrNext,GrPrevious } from "react-icons/gr";
import parse from 'html-react-parser';
import moment from 'moment';

function Slider4(props) {
    const { title, data, items } = props
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: items,
        slidesToScroll: 1,
        nextArrow:<GrNext />,
      prevArrow: <GrPrevious />
      
    };
    return (
        <div className="slider4-wrap">
            <div className="bs-heading">
                <h5>{title}</h5>
            </div>
            <div className="bs-slider-items-wrap">
                <Slider {...settings}>
                    {data !== undefined &&
                        data.map((item, i) => {
                            return (
                                <div className="bs-item">
                                    <div className="bs-img">
                                        <img alt={item.seo_title} src={item.image} /></div>
                                    <div className="bs-content">
                                        <h1>{item.title}</h1>
                                        <p> {item?.short_description||'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab voluptatibus eveniet praesentium! Inventore temporibus culpa quidem voluptate sunt rerum accusamus nostrum quae consequuntur animi, modi ducimus deserunt nisi enim suscipit obcaecati ipsum at adipisci autem sapiente. Veniam deserunt perferendis veritatis. '}</p>
                                        <div className="bs-meta-info">
                                            <span><FaRegCalendarAlt/> {moment(item?.created_at).format('MMMM Do YYYY')}</span> 
                                            <span><FaComments/>  {item.comment_count}</span>
                                        </div>
                                        <div className="bs-btn">
                                            <Link to={`/blog-post/${item.id}`}>Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>

            </div>

        </div>
    )
}

export default Slider4
