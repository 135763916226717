import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getPaymentSettingApi } from "../../api";
import { BOOK_ORDER_BY_USER, globalAction, SHOW_LOADING } from "../../redux";

function Paypal(props) {
  const {
    orderInfo,
    orderInfo: {
      cartList,
      cartTotal,
      ShippingInfo,
      user,
      history,
      guestData,
      clientId,
      shiping_fee,
    },
  } = props;
  const dispatch = useDispatch();

  const handlePlaceOrder = (orderDetails) => {
    let order_product = [];
    cartList?.forEach((item) => {
      const {
        id,
        qty,
        tax_included_sale_price,
        tax_included_regular_price,
        tax,
      } = item;
      let price = item.tax_included_regular_price;
      if (item.tax_included_sale_price !== null) {
        price = item.tax_included_sale_price;
      }
      order_product.push({
        product_id: id,
        qty: qty,
        single_price:
          tax_included_sale_price !== null
            ? tax_included_sale_price
            : tax_included_regular_price,
        total_price: Number(price) * Number(qty),
        tax: tax,
      });
    });

    let tax_fee = null;
    tax_fee =
      order_product.length > 0 &&
      order_product
        .map(
          (item) =>
            (Number(item?.single_price) / (100 + Number(item?.tax))) *
            Number(item?.tax) *
            Number(item?.qty)
        )
        ?.reduce((a, b) => Number(a) + Number(b));
    let finalOrderObject = {
      tax_fee,
      order_duration: 5,
      order_quantity: cartList.length,
      order_price: Number(cartTotal).toFixed(2),
      payment_type: orderDetails.purchase_units[0].soft_descriptor || "PAYPAL",
      order_status: "pending",
      shipping_addess:
        user !== null
          ? ShippingInfo?.shipping_addess?.id
          : ShippingInfo?.shipping_addess,
      billing_address:
        user !== null
          ? ShippingInfo?.billing_address?.id
          : ShippingInfo?.billing_address,
      order_product,
      gst_billing: "no",
      user_type: "user",
      shiping_fee: shiping_fee,
      transaction_id: orderDetails.purchase_units[0].payments.captures[0].id,
    };
    if (user !== null) {
      finalOrderObject = { ...finalOrderObject, user_id: user?.id };
      dispatch(globalAction(SHOW_LOADING));

      dispatch(
        globalAction(BOOK_ORDER_BY_USER, {
          finalOrderObject: finalOrderObject,
          history: history,
        })
      );
    } else {
      finalOrderObject = { ...finalOrderObject, ...guestData };
      dispatch(globalAction(SHOW_LOADING));
      dispatch(
        globalAction(BOOK_ORDER_BY_USER, {
          finalOrderObject: finalOrderObject,
          history: history,
        })
      );
    }
  };

  return (
    <>
      {clientId !== undefined && (
        <PayPalScriptProvider
          options={{
            "client-id": clientId,
          }}
        >
          <PayPalButtons
            style={{
              tagline: false,
              label: "pay",
            }}
            createOrder={(data, actions) => {
              //  set up transaction
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: Number(orderInfo?.cartTotal),
                    },
                  },
                ],
              });
            }}
            onApprove={async (data, actions) => {
              const order = await actions.order.capture();
              handlePlaceOrder(order);
            }}
            onCancel={(data, actions) => {
              alert("Are you sure to cancel..?");
            }}
            onError={(err) => {
              alert(
                err ||
                  "Something went wrong. we not processed your request. Please try again"
              );
            }}
          />
        </PayPalScriptProvider>
      )}
    </>
  );
}

export default Paypal;
