import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationFR from "../locales/fr/translation.json";
import translationEN from "../locales/en/translation.json";
import translationPT from "../locales/pt/translation.json";

const fallbackLng = ["en"];
const availableLanguages = ["en", "fr", "pt"];

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },

  pt: {
    translation: translationPT,
  },
};
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,
    react: {
      useSuspense: false,
    },
    detection: {
      checkWhitelist: true,
    },
    debug: false,
    languages: availableLanguages,
    // whitelist: availableLanguages,
  });
export default i18n;
