import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import whiteBg from '../../assets/img/whiteBg.png'
import CmAlert from '../../components/CmAlert/CmAlert';
import Loading from '../../components/Loading/Loading';
import { ACCOUNT_VERIFY, globalAction, SHOW_LOADING } from '../../redux';
import './EmailVerify.scss'

function EmailVerify() {
    const history = useHistory()
    const dispatch = useDispatch()
    const { visibleMessage, isLoading, message,status } = useSelector(state => state.errorReducer)
    const [userEmail, setUserEmail] = useState(undefined);
    const [OTP, setOTP] = useState(undefined);
    const [checkEmpty, setcheckEmpty] = useState(false);

    useEffect(() => {
        window.scroll({ top: 0 })
        setUserEmail(localStorage.getItem("DuAfricUserEmail"));
        if (localStorage.getItem("DuAfricUserEmail")===null) {
            history.push('/login')
        }

        return () => {
            localStorage.removeItem("DuAfricUserEmail")
        };
    }, []);

    

    const handleSubmit = (() => {
        if (OTP.trim() === '') {
            setOTP(undefined)
            setcheckEmpty(true)
        }
        else {
            dispatch(globalAction(SHOW_LOADING))
            dispatch(globalAction(ACCOUNT_VERIFY, { token: OTP, email: userEmail,history:history }))
        }
    })

    return (
        <>
            <CmAlert
                message={message}
                status={status}
                showAlert={visibleMessage}
            />
            <div className="email-verify-wrap">
                <div className="otp-verify-main-wrap">
                    <div className="otp-verify-inner">
                        <Loading
                            showLoading={isLoading}
                        />
                        <div className="logo section">
                            <div className="logo">
                                <img src={whiteBg} alt="Bellstone" />
                            </div>
                        </div>
                        <div className="otp-content">
                            <h3>Verify your email</h3>
                            <p>
                                You need to verified your email account for first time to login your
                                acccount.{" "}


                                We already sent a verification code to your email{" "}
                                <span className="otp-email">{`( ${userEmail} )`}</span>
                            </p>
                        </div>
                        <div className={`otp-form ${checkEmpty === true ? 'error' : ''}`}>
                            <input
                                type="text"
                                onChange={(e) => {
                                    setOTP(e.target.value); setcheckEmpty(false)
                                }}
                                id="otp" name="otp"
                            />
                            {
                                checkEmpty &&
                                <small className='error-req'>*Required</small>
                            }


                        </div>
                        {
                            OTP !== undefined &&
                            <div className="otp-btn animate-pop-in">
                                <button onClick={() => handleSubmit()} className="button" type='button'>
                                    Verify
                                </button>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default EmailVerify
