import React from "react";
import { Helmet } from "react-helmet";

function SeoSetting(props) {
  const {
    title,
    metaDescription,
    canonicalLink,
    metaKeywords,
    tags,
    metaCategory,
  } = props;
  return (
    <Helmet>
      {title && <title> {title} </title>}
      {canonicalLink && <a rel="canonical" href={canonicalLink} />}
      {metaDescription && <meta name="description" content={metaDescription} />}
      {metaKeywords && <meta name="keywords" content={metaKeywords} />}
      {tags && <meta name="tags" content={tags} />}
      {metaCategory && <meta name="category" content={metaCategory} />}
    </Helmet>
  );
}

export default SeoSetting;
