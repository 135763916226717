import React from 'react'
import { ImWarning } from 'react-icons/im';
import RejectKYCImg from '../../../../assets/img/Maker-checker.svg'

function UserRejectedPage() {
    return (
        <div class="first-time-detail-form-wrap">
		<div class="ftd-inner">
			<div class="ftd-alert">
				<div class="ftd-inner">
					<h6><span><ImWarning /></span> Warning: KYC REJECT</h6>
                    <p><strong>Reason :</strong>Your reject reason here</p>
				</div>
			</div>
			<div class="ftd-pending-approval">
				<div class="ftd-pending-approval-inner">
					<div class="ftd-pa-ing"><img alt="Reject Profile" src={RejectKYCImg}/></div>
					<div class="ftd-pa-content">
						<p>Your profile reject by Du-Afrik. Please update your KYC with valid and correct details.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
    )
}

export default UserRejectedPage
