import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import './VendorListing.scss'
import imagePlaceHolder from '../../assets/img/image-placeholder.jpg';
import userImagePlaceHolder from '../../assets/img/user-profile.jpg'
import { useState } from 'react';
import { getVendorLIstByCountryId } from '../../api';
import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_LOADING, globalAction, SHOW_LOADING } from '../../redux';
import Loading from '../../components/Loading/Loading';
import { AiOutlineShop } from 'react-icons/ai';
import { ImLocation2 } from 'react-icons/im';
import { MdVerified } from 'react-icons/md';
import { FaHandPointRight } from 'react-icons/fa';
import { BsFillPatchQuestionFill } from 'react-icons/bs';
import ReactPaginate from 'react-paginate';

function VendorListing(props) {
    const { isLoading } = useSelector(state => state.errorReducer)
    const [vendorListingData, setVendorListingData] = useState(undefined)
    const { match: { params } } = props;
    const dispatch = useDispatch()

    let countryId = params.id

    const getVendorListing = async (params) => {
        dispatch(globalAction(SHOW_LOADING))
        await getVendorLIstByCountryId(params)
            .then((async (res) => {
                dispatch(globalAction(CLOSE_LOADING))
                const { status, data } = res;
                if (status === 200) {
                    await setVendorListingData(data.vendor_list)
                }

            }))
            .catch((err) => {
                console.log(err)
                dispatch(globalAction(CLOSE_LOADING))
            })

    }

    useEffect(() => {
        window.scroll({ top: 0 })
        console.log(countryId)
        if (countryId) {
            let params = `?country_id=${countryId}`
            getVendorListing(params)
        }
    }, [])

    const handlePageClick = (data) => {
        document.getElementById("card-wrap").scrollIntoView(true);
        dispatch(globalAction(SHOW_LOADING));
        let params = `?user_id=${countryId}&page=${data.selected + 1}`
        getVendorListing(params)
    };


    console.log('vendorListingData', vendorListingData)

    return (

        <div className='vendor-listing-wrap'>
            <Loading
                showLoading={isLoading}
            />
            {vendorListingData?.data?.length > 0 &&
                <div className="banner">
                    <div
                        className="image"

                    >
                        <h1>Our Vendors</h1>
                        <p>

                            <Link to='/'
                            >Home</Link>
                        </p>
                    </div>
                </div>
            }

            <div className="vlw-inner cm-container">
                {vendorListingData?.data?.length > 0 ?
                    <>
                        <div className="vls-listing">
                            {vendorListingData?.data.length > 0 &&
                                vendorListingData?.data?.map((item, i) => {
                                    console.log('item', item)
                                    return (
                                        <div className="item">

                                            <div className="item-content"
                                                style={{ backgroundImage: `url('https://duafrik.imperialitforweb.com${item?.store_banner || imagePlaceHolder}')` }}>
                                                <div className="item-content-inner">
                                                    <h2><FaHandPointRight /> {item?.name}</h2>
                                                    <p><AiOutlineShop /> {item?.complete_legal_name}</p>
                                                    <p><ImLocation2 /> {item?.company_address}</p>
                                                    {item?.kyc_verified === 1
                                                        ?
                                                        <p style={{ color: '#9dff9d', }}><MdVerified /> Verified Seller</p>
                                                        :
                                                        <p><BsFillPatchQuestionFill /> Verfication Pending </p>
                                                    }
                                                    <Link to={`/vendor-store/${item?.id}`}></Link>

                                                </div>

                                            </div>

                                            <div className="item-bottom">
                                                <img src={item?.profile_image || userImagePlaceHolder} alt="" />
                                                <Link to={`/vendor-store/${item?.id}`}>Visit Store</Link>
                                            </div>
                                        </div>
                                    )
                                })
                            }


                        </div>
                        <div className="vls-pagination-wrap">
                            <ReactPaginate
                                previousLabel={"previous"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                pageCount={Math.ceil(vendorListingData?.total / vendorListingData?.per_page)} marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"} pageLinkClassName={"page-link"} previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"} forcePage={Number(vendorListingData?.current_page - 1)} />
                        </div>
                    </>

                    :
                    <div className="no-vendor">
                        <h1> No data found....!</h1>
                    </div>

                }

            </div>
        </div>
    )
}

export default VendorListing
