import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import { Link } from "react-router-dom";
import { BsCartCheck } from "react-icons/bs";
import { FiTruck } from "react-icons/fi";
import { AiOutlineTags } from "react-icons/ai";
import MaterialTable from "material-table";
import tableIcons from "../Dashboard/TableIcons";
import { FaEye, FaTrash } from "react-icons/fa";
import { MdOutlineFavoriteBorder } from "react-icons/md";
import { useSelector } from "react-redux";
import { getUserDashboardDetailsApi } from '../../api/Api_calls'
import { SHOW_LOADING, globalAction, CLOSE_LOADING } from '../../redux/index'
import { useDispatch } from "react-redux";

function UserDashboard() {
  const { user } = useSelector(state => state.authReducer)
  const { cartList } = useSelector(state => state.appReducer)
  const dispatch = useDispatch()
  const [dashboardData, setDashboardData] = useState(undefined)




  useEffect(() => {
    if (user?.id) {
      dispatch(globalAction(SHOW_LOADING))
      getUserDashboardDetailsApi({ user_id: user?.id })
        .then((res) => {
          dispatch(globalAction(CLOSE_LOADING))
          const { status, data } = res
          if (status === 200) {
            setDashboardData(data)
          }
        })
        .catch((err) => {

          alert('something went wrong. Please try again')
        })
    }

  }, [])

  const orderTitle = [
    { title: "Order Id", render: (rowData) => <Link to={`/dashboard/order-detail/${rowData.id}`}>{rowData?.order_number}</Link>, field: "OrderID" },
    { title: "Total Price", field: "order_price" },
    { title: "Payment Mode", field: "payment_type" },
    { title: "Payment Status", field: "payment_status" },
    { title: "Order status", field: "order_status" },
    { title: "Order Date", field: "order_at" },
    {
      title: "Actions",
      render: (rowData) => (
        <Link
          to={`/dashboard/order-detail/${rowData.id}`}>
          <FaEye />
        </Link>
      ),
      field: "CancelOrder",
    },
  ];
  // {
  //   title: "Cacel Order",
  //   render: (rowData) => (
  //     <Link to={`#`}>
  //       <FaTrash />
  //     </Link>
  //   ),
  //   field: "CancelOrder",
  // },


  return (
    <div className="user_dashboard">
      <div className="page_title">
        <h3>Dashboard</h3>
      </div>
      <div className="dashboard_cards">
        <div className="item new">
          <div className="content">
            <span>{cartList.length}</span>
            <p>Cart</p>
          </div>
          <div className="icon">
            <span>
              <BsCartCheck />
            </span>
          </div>
          <Link to="/dashboard/cart"></Link>
        </div>
        <div className="item total">
          <div className="content">
            <span>{dashboardData?.count_orders}</span>
            <p>Total order</p>
          </div>
          <div className="icon">
            <span>
              <FiTruck />
            </span>
          </div>
          <Link to="/dashboard/orders"></Link>
        </div>
        <div className="item saved">
          <div className="content">
            <span>{dashboardData?.count_wishlist}</span>
            <p>Saved Products</p>
          </div>
          <div className="icon">
            <span>
              <MdOutlineFavoriteBorder />
            </span>
          </div>
          <Link to='/dashboard/wishlist'></Link>
        </div>
      </div>
      <div className="recent_order box-size">
        <Link to="/dashboard/orders"> <small><em>(View all)</em></small></Link>
        <MaterialTable
          title="All Orders"
          columns={orderTitle}
          data={dashboardData && dashboardData?.orders?.data}
          icons={tableIcons}
          options={{
            search: false,
            // exportButton: false,
            paging: false
          }}
        />
      </div>
    </div>
  );
}

export default UserDashboard;
