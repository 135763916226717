import React, { useEffect, useRef, useState } from "react";
import "./ShoppingCart.scss";
import emptyProductImg from "../../assets/img/emptyProduct.png";
import { FaEye, FaMinus, FaPlus, FaTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  DELETE_CART_ITEM,
  globalAction,
  SHOW_LOADING,
  UPDATE_CART_ITEM_QTY,
} from "../../redux";
import { useDispatch } from "react-redux";
import SelectUserType from "./SelectUserType";
function ShoppingCart(props) {
  const dispatch = useDispatch();
  const { cartList, cartTotal, productDetails } = useSelector(
    (state) => state.appReducer
  );
  const { user } = useSelector((state) => state.authReducer);
  const getQty = useRef();
  const [modalVisible, setModalVisible] = useState(false);
  const [finalCartList, setFinalCartList] = useState([]);
  const [totalCartAmount, setTotalCartAmount] = useState(cartTotal);

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  useEffect(() => {
    setTotalCartAmount(cartTotal);
  }, [cartTotal, cartList]);

  useEffect(() => {
    if (localStorage.getItem("buyNow")) {
      setFinalCartList(JSON.parse(localStorage.getItem("buyNow")));
    } else {
      setFinalCartList(cartList);
    }
  }, []);

  const handleChangeQty = (e, typeOfChange, i, stock_qty) => {
    dispatch(globalAction(SHOW_LOADING));
    let tempQty = Number(getQty.current.innerText);
    if (typeOfChange === "minus" && tempQty > 0) {
      tempQty = finalCartList[i]["qty"] - 1;
      finalCartList[i]["qty"] = tempQty;
    } else {
      tempQty = finalCartList[i]["qty"] + 1;
      if (tempQty <= stock_qty) {
        finalCartList[i]["qty"] = tempQty;
      }
    }
    console.log(finalCartList, "finalCartList");
    //  check user comes from BuyNow or cart
    if (localStorage.getItem("buyNow")) {
      localStorage.setItem("buyNow", JSON.stringify(finalCartList));
      setFinalCartList([...finalCartList]);
    } else {
      dispatch(
        globalAction(UPDATE_CART_ITEM_QTY, {
          cartList: finalCartList,
          user: user,
        })
      );
    }
  };
  const handleDelete = (e, Item, i) => {
    dispatch(globalAction(SHOW_LOADING));
    cartList.splice(i, 1);
    //  check user comes from BuyNow or cart
    if (localStorage.getItem("buyNow")) {
      props.history.push("/");
    } else {
      dispatch(
        globalAction(DELETE_CART_ITEM, {
          cartList: cartList,
          item: Item,
          user: user,
          showMessage: true,
        })
      );
    }
  };

  return (
    <section className="shopping_cart spacing">
      {finalCartList && finalCartList.length > 0 ? (
        <div className="container">
          <div className="content_left">
            <div className="title">
              <h3>Shopping Cart</h3>
            </div>
            <div className="sub_title">
              <h5>Shipping Update</h5>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer.
              </p>
            </div>
            <div className="ship_product">
              {finalCartList &&
                finalCartList.map((item, i) => {
                  const {
                    title,
                    created_at,
                    id,
                    stock_qty,
                    image_url,
                    image,
                    qty,
                    tax_included_regular_price,
                    tax_included_sale_price,
                    slug,
                  } = item;
                  return (
                    <div className="item" key={created_at}>
                      <table>
                        <tr>
                          <th>Image</th>
                          <th>Name</th>
                          <th>QTY</th>
                          <th>ITEM TOTAL</th>
                          <th>REMOVE</th>
                        </tr>
                        <tr>
                          <td>
                            <Link to={`/product-detail/${slug}`}>
                              <img src={image_url || image} alt={title} />
                            </Link>
                          </td>
                          <td>
                            <Link to={`/product-detail/${slug}`}>
                              <h6>
                                {" "}
                                {title ? title : slug?.split("-")?.join(" ")}
                              </h6>
                            </Link>
                            {tax_included_sale_price !== null ? (
                              <div>
                                {Number(tax_included_sale_price) !==
                                  Number(tax_included_regular_price) && (
                                  <del>
                                    {Number(tax_included_regular_price).toFixed(
                                      2
                                    )}
                                    $
                                  </del>
                                )}
                                <span>
                                  {Number(tax_included_sale_price).toFixed(2)}$
                                </span>
                              </div>
                            ) : (
                              <span>{tax_included_regular_price}$</span>
                            )}
                          </td>
                          <td className="button">
                            <div className="qty">
                              {qty > 1 && (
                                <span
                                  className="minu"
                                  onClick={(e) =>
                                    handleChangeQty(e, "minus", i, stock_qty)
                                  }
                                >
                                  <FaMinus />
                                </span>
                              )}
                              <span className="num" ref={getQty}>
                                {qty}
                              </span>
                              <span
                                className="plus"
                                onClick={(e) =>
                                  handleChangeQty(e, "plus", i, stock_qty)
                                }
                              >
                                <FaPlus />
                              </span>
                            </div>
                          </td>
                          <td>
                            {`${
                              Number(tax_included_sale_price) * Number(qty)
                            } $`}
                            {/* {Number(tax_included_sale_price) * Number(qty)} $ */}
                            {/* {tax_included_sale_price !== null ? (
                              <span>
                                {(
                                  Number(tax_included_sale_price) * Number(qty)
                                ).toFixed(2)}
                                $
                              </span>
                            ) : (
                              <span>
                                {(
                                  Number(tax_included_regular_price) *
                                  Number(qty)
                                ).toFixed(2)}
                              </span>
                            )} */}
                          </td>
                          <td>
                            <span
                              onClick={(e) => {
                                handleDelete(e, item, i);
                              }}
                            >
                              <FaTrashAlt />
                            </span>
                          </td>
                        </tr>
                      </table>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="content_right">
            <h5>Order Summary ({finalCartList && finalCartList.length})</h5>

            <ul>
              <li>
                <span>Subtotal:</span>
                {localStorage.getItem("buyNow") ? (
                  <span>
                    {/* {finalCartList[0]?.tax_included_sale_price !== null // For Buy Now
                      ? Number(
                          finalCartList[0]?.tax_included_sale_price *
                            finalCartList[0]?.qty
                        ).toFixed(2)
                      : Number(
                          finalCartList[0]?.tax_included_regular_price *
                            finalCartList[0]?.qty
                        ).toFixed(2)} */}
                    {Number(finalCartList[0]?.tax_included_sale_price) *
                      Number(finalCartList[0]?.qty)}
                    &nbsp; $
                  </span>
                ) : (
                  // <span>{Number(totalCartAmount).toFixed(2)}$</span>
                  <span>{`${totalCartAmount} $`}</span>
                )}
              </li>
              {/* <li>
                <span>Shipping cost:</span>
                <span>0$</span>
              </li> */}
              <li>
                <div className="d-flex flex-column">
                  <b>Order Total:</b>
                  <small>
                    <i>(included all taxes)</i>
                  </small>
                </div>
                {localStorage.getItem("buyNow") ? (
                  <b>
                    {/* {finalCartList[0]?.tax_included_sale_price !== null // For Buy Now
                      ? Number(
                          finalCartList[0]?.tax_included_sale_price *
                            finalCartList[0]?.qty
                        ).toFixed(2)
                      : Number(
                          finalCartList[0]?.tax_included_regular_price *
                            finalCartList[0]?.qty
                        ).toFixed(2)} */}
                    {Number(finalCartList[0]?.tax_included_sale_price) *
                      Number(finalCartList[0]?.qty)}
                    &nbsp;$
                  </b>
                ) : (
                  <b>{`${totalCartAmount} $`}</b>
                )}
              </li>
            </ul>

            <div className="button_wrap">
              {user ? (
                <Link to="/select-Address">Select Address</Link>
              ) : (
                <Link
                  to="/select-Address"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleModal();
                  }}
                >
                  Select Address
                </Link>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="container no-product">
          <div className="empty-product-img">
            <img src={emptyProductImg} alt="No Product Found" />
          </div>

          <h1>No Product found...!</h1>
        </div>
      )}
      <SelectUserType toggleModal={toggleModal} modalVisible={modalVisible} />
    </section>
  );
}

export default ShoppingCart;
