import React, { useEffect, useState } from "react";
import HomeBanner from "../../components/HomeBanner/HomeBanner";
import "./Blog.scss";
import Listing1 from "../../assets/img/982383785.jpg";
import {
  FacebookIcon,
  XIcon,
  LinkedinIcon,
  EmailIcon,
  WhatsappIcon,
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  LinkedinShareButton,
} from "react-share";
import { FaLongArrowAltRight, FaRegCalendarAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import Recent from "../../assets/img/product1.png";
import { useDispatch } from "react-redux";
import { getBlogPostDetailsByIdApi } from "../../api";
import {
  CLOSE_LOADING,
  GET_BLOG_CATEGORIES,
  globalAction,
  SHOW_LOADING,
} from "../../redux";
import parse from "html-react-parser";
import moment from "moment";
import { useSelector } from "react-redux";

function BlogPost(props) {
  const dispatch = useDispatch();
  const [postPageData, setPostPageData] = useState(undefined);
  const {
    match: { params },
  } = props;
  const { blogCategories } = useSelector((state) => state.appReducer);

  const getPostData = (id) => {
    dispatch(globalAction(SHOW_LOADING));
    getBlogPostDetailsByIdApi(id)
      .then((res) => {
        dispatch(globalAction(CLOSE_LOADING));
        if (res.status === 200) {
          setPostPageData(res.data);
        } else {
          alert("Bad request");
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(globalAction(CLOSE_LOADING));
        alert("Something went wrong");
      });
  };
  useEffect(() => {
    if (params?.id) {
      getPostData(params?.id);
    }
    dispatch(globalAction(GET_BLOG_CATEGORIES));
  }, []);
  useEffect(() => {
    window.scroll({ top: 0 });
  }, [blogCategories]);

  return (
    <section className="blog_post">
      <HomeBanner img={postPageData?.blog?.image} bannerTitle="blog title" />

      <div className="post_content">
        <div className="container">
          <div className="post_left">
            <div className="post_text">
              {parse(postPageData?.blog?.body || "")}
            </div>
            {postPageData?.blog?.tags && (
              <div className="tags">
                <span>Tags:-</span>{" "}
                {postPageData?.blog?.tags.split(",").reduce((acc, item) => {
                  return (acc = acc + item);
                }, "")}
              </div>
            )}
            <div className="socila_section">
              <p className="date-view">
                <span>
                  <FaRegCalendarAlt />
                </span>
                {moment(postPageData?.blog?.created_at).format("LL")}
              </p>

              <div className="share-social">
                <span>Share : </span>
                <FacebookShareButton
                  url={window.location.href}
                  title={"Facebook"}
                  hashtag={"#hashtag"}
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton
                  url={window.location.href}
                  title={"Twitter"}
                  hashtag={"#hashtag"}
                >
                  <XIcon size={32} round />
                </TwitterShareButton>
                <LinkedinShareButton
                  url={window.location.href}
                  title={"Linkdin"}
                  hashtag={"#hashtag"}
                >
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
                <EmailShareButton
                  url={window.location.href}
                  title={"Email"}
                  hashtag={"#hashtag"}
                >
                  <EmailIcon size={32} round />
                </EmailShareButton>
              </div>
            </div>

            {/* <div class="comments">
              <h5>Comment ({postPageData?.blog?.comment_count || 0})</h5>
              <div class="comment-option">
                <div class="see-more"></div>
              </div>
            </div>
            <div class="contact-form-area">
              <h5> Comments</h5>
              <form id="abc" class="contact-form">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <textarea rows="5" placeholder="Comments" type="text" id="validationCustom01" class="form-control"></textarea>
                    <div class="invalid-feedback">Please enter your comments.</div>
                  </div>
                  <div class="submit form-group col-md-12">
                    <button type="submit" class="btn btn-primary">
                      Post
                    </button>
                  </div>
                </div>
              </form>
            </div> */}
          </div>
          <div className="pos-right">
            <div className="blog_category">
              <h4>Categories</h4>
              <ul>
                {blogCategories &&
                  blogCategories?.data &&
                  blogCategories?.data.map((item, i) => {
                    return (
                      <li>
                        <a href={`/blog/${item?.id}`}>
                          <span>{item?.name}</span>
                          <span>
                            <FaLongArrowAltRight />
                          </span>
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="recent_post">
              <h4>Recent Post</h4>
              {postPageData?.recent_posts &&
                postPageData?.recent_posts.length > 0 &&
                postPageData?.recent_posts.map((item, i) => {
                  return (
                    <div className="recent_post_conent">
                      <a href={`/blog-post/${item?.id}`}>
                        <img src={item?.image} alt={item?.title} />
                      </a>
                      <div className="recent_post_conent_body">
                        <a href={`/blog-post/${item?.id}`}>
                          <h6>{item?.title}</h6>
                        </a>
                        <p>
                          <span>
                            <FaRegCalendarAlt />
                          </span>
                          <span> {moment(item?.created_at).format("LL")}</span>
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogPost;
