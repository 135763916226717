import React, { useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { Link } from "react-router-dom";
import "./Menu.scss";
import { MdLogout } from "react-icons/md";
import { globalAction, LOGOUT } from "../../redux";
import { useDispatch } from "react-redux";

function Menu(props) {
  const dispatch = useDispatch();
  const { menuItems, activeMenuName } = props;
  const [activeMenuKey, SetActiveMenuKey] = useState(undefined);
  const MenuClose = () => {
    let tarEle = document.getElementsByClassName("vd-main-screen-wrap");
    tarEle[0].classList.remove("dash-menu-open");
  };

  const logout = (e) => {
    dispatch(globalAction(LOGOUT));
  };

  const [childMenuFlag, setChildMenuFlag] = useState(null);
  const childMenuHandle = (e, parentNodeText) => {
    if (parentNodeText === childMenuFlag) {
      setChildMenuFlag(null);
    } else {
      setChildMenuFlag(parentNodeText);
    }
  };

  return (
    <div className="dashboard-menu-wrap">
      <div className="dh-menu-inner">
        <ul className="level-1">
          {menuItems.map((item, i) => {
            return (
              <li
                className={`${
                  item.name === childMenuFlag ? "child-menu-open" : ""
                } 
                                ${activeMenuName === item.url ? "active" : ""}
                                ${activeMenuKey === item.key ? "active" : ""}
                                `}
              >
                <Link
                  to={item.url}
                  onClick={(e) => {
                    if (item.childItems) {
                      e.preventDefault();
                      childMenuHandle(e, item.name);
                    }
                    MenuClose();
                    SetActiveMenuKey(undefined);
                  }}
                >
                  <span className="icon">{item.icon}</span>
                  {item.name}
                </Link>
                {item.childItems && (
                  <div className="childTrigger">
                    {" "}
                    <IoMdArrowDropdown />
                  </div>
                )}

                {item.childItems && (
                  <ul className="level-2">
                    {item.childItems.map((citem, i) => {
                      return (
                        <li
                          className={` ${
                            activeMenuName === citem.url ? "active" : ""
                          }`}
                        >
                          <Link
                            to={citem.url}
                            onClick={() => {
                              MenuClose();
                              SetActiveMenuKey(item.key);
                            }}
                          >
                            <span className="icon">{citem.icon}</span>
                            {citem.name}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
          <li>
            <div className="mt-5">
              <Link
                to="/login"
                onClick={(e) => {
                  logout(e);
                }}
              >
                <b>
                  Log Out &nbsp;
                  <MdLogout />
                </b>
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Menu;
