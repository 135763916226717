/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./become-partner.scss";
import HomeBanner from "../../components/HomeBanner/HomeBanner";
import { Accordion, Tab, Tabs } from "react-bootstrap";
import axios from "../../Utilis/axios";
import { Apis } from "../../api";

function Faqs() {
  const [faqs, setFaqs] = useState([]);
  useEffect(() => {
    axios
      .get(`admin/faq`)
      .then((res) => console.log(res, "gdgdg"))
      .catch((err) => console.log(err));
  }, []);

  return (
    <section className="become_partner">
      <>
        <HomeBanner bannerTitle="Faqs" />
      </>
      <div className="container">
        <div className="spacing">
          <div className="faqs-wrap">
            <Tabs
              defaultActiveKey="Pricing and Payments"
              id="uncontrolled-tab-example"
            >
              <Tab eventKey="Getting Started" title="Getting Started"></Tab>
              <Tab eventKey="Pricing and Payments" title="Pricing and Payments">
                <Accordion defaultActiveKey="0" flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header as="h2">
                      What is listing?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Listing a product refers to filling out all the
                        necessary information and adding images of the product
                        so that a customer can make an informed buying decision.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>What is listing?</Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Listing a product refers to filling out all the
                        necessary information and adding images of the product
                        so that a customer can make an informed buying decision.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Tab>
              <Tab
                eventKey="Listings and Catalog
"
                title="Listings and Catalog
"
              ></Tab>
              <Tab
                eventKey="Order Management and Shipping"
                title="Order Management and Shipping
"
              ></Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faqs;
