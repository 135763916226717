import React from 'react'
import { Spinner } from 'react-bootstrap'
import './loading.scss'

function Loading(props) {
    
    return (
       props.showLoading? 
       <div className="loading-wrap" >
       <Spinner animation="border" role="status">
           
       </Spinner>
   </div>
       :
       ''
       
    )
}

export default Loading
