import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import "./how-to-use.scss";

import Partner1 from "../../assets/img/partner1.png";
import Partner2 from "../../assets/img/partner2.png";
import Partner3 from "../../assets/img/partner3.png";
import deliveryBanner from "../../assets/img/Delivery banner (1).jpg";
import HomeBanner from "../../components/HomeBanner/HomeBanner";
import { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { globalAction, SHOW_LOADING, SELL_ON } from "../../redux";
import { useDispatch } from "react-redux";
import AllCountry from "../../Utilis/allCountry.json";
import exportCoutryMap from "../.././assets/img/fini.png";

function HowUse() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(undefined);
  const formRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(globalAction(SHOW_LOADING));
    dispatch(globalAction(SELL_ON, formData));
    formRef.current.reset();
  };

  const changeText = (value, text) => {
    setFormData({ ...formData, [text]: value });
  };

  useEffect(() => {
    window.scroll({ top: 0 });
  }, []);

  return (
    <section className="become_partner">
      <>
        <HomeBanner img={deliveryBanner} />
      </>
      <div className="container">
        <div className="spacing">
          <div className="page_title notranslate">
            <h3>DuAfrik</h3>
          </div>
          {/* <h3>DuAfrik</h3> */}
          <p style={{ textAlign: "justify" }}>
            In Africa we find the home, with many cultures but transversal,
            where the sun shines golden, strengthens our traditions are
            improving, like an old elephant that is getting modern. We are
            Africa. We are DuAfrik.
          </p>
          <b>
            {" "}
            <h5>
              <b>What it is DuAfrik?</b>
            </h5>
          </b>
          <p style={{ textAlign: "justify" }}>
            We are building the largest and most complete window of products
            made in Africa, capable of show not only our culture and diversity,
            but also the quality and capacity prodution already existing in our
            continent. Together, bringing all suppliers, produtors and consumers
            trading directly on one platform, we will make DuAfrik the best
            reference on the Africa, no matter the sector or subject.{" "}
          </p>
          <p style={{ textAlign: "justify" }}>
            We are Africa, and we intente to show the essence of it, and where
            all world the has begin.
          </p>
          <b>
            <h5>Why Duafrik make sense? </h5>
          </b>
          <p style={{ textAlign: "justify" }}>
            Africa is fundamentally a trading continent. The socio-economic
            structure of most African countries hinges on trade. Despite recent
            and concerted efforts to diversify economies, trade still accounts
            for 51 percent of Africa’s GDP according to World Bank data.
          </p>
          <p style={{ textAlign: "justify" }}>
            Above of that would you be able to say how many people, languages,
            artistic expressions, musical styles, gastronomic products,
            philosophy schools, recognized writers, world celebrities, have
            their roots in Africa?
          </p>
          <p style={{ textAlign: "justify" }}>
            How many African products are consumed and where? Who are the
            biggest producers and what is the quality of the products already
            available and if the prices do they reach the final consumer?
          </p>
          <p style={{ textAlign: "justify" }}>
            What opportunities do African brands have for growth, with customers
            inside and outside Africa, and how to increase trade between African
            countries?
          </p>
          <p style={{ textAlign: "justify" }}>
            How can wholesalers and small producers be able to sell their
            products, and how can individual consumers be able to buy at fair
            prices and with direct benefits for those who manufacture them?
          </p>
          <p style={{ textAlign: "justify" }}>
            How to stimulate the growth of African economies, increase knowhow
            of what is already produced in Africa and attract investors and new
            businesses?
          </p>
          <p style={{ textAlign: "justify" }}>
            DuAfrik comes to answer questions, and all the others that are yet
            to be asked. <br />
            We are a unitary, transversal and multidisciplinary project,
            focusing on Africa and everything that has African people as the
            main beneficiaries
          </p>
          <b>
            <h5>How it works? </h5>
          </b>
          <p style={{ textAlign: "justify" }}>
            Supported by access levels and the possibility for each one to
            manage their own business, the platform allows each Sellers to
            control its own stock and manage its own sales.
          </p>
          <p style={{ textAlign: "justify" }}>
            On other side, the Partner will also be able to control the Brands
            in they network and monitor the growth of the business.
          </p>
          <p style={{ textAlign: "justify" }}>
            The DuAfrik platform generates reports for everyone individually,
            and as well a view of all network, making it possible for the
            administrator to understand which business is growing, or which area
            it needs help <br />
            To developed. <br />
            All can be analysed at regional level, from country to country, by
            sector of activity perspective or by making comparisons between
            partners and between brands.
          </p>
        </div>
      </div>
    </section>
  );
}

export default HowUse;
