/** @format */

import { Apis } from "./globals";
// import axios from "axios";
import axios from "../Utilis/axios";
const {
  mid,
  registration,
  accountVerify,
  login,
  profile_company,
  profile_kyc,
  profile_payment,
  update_countries,
  forgot_password,
  reset_password,
  state,
  update_profile,
  category_list,
  subcategory,
  brand_list,
  offers_list,
  add_product,
  product_list,
  product_details,
  update_product,
  attribute,
  add_product_variant,
  product_variants,
  delete_product_variant,
  update_product_variant,
  advertisement,
  product_listing,
  blog,
  blog_category,
  add_address,
  address_list,
  delete_address,
  update_address,
  add_order,
  orders,
  privacy_policy,
  terms_condition,
  affiliate,
  wallet_tc,
  in_news,
  vendor_connect,
  about_us,
  change_password,
  contact_us,
  seller_contact_us,
  order_details,
  order_cancel,
  add_save_product,
  save_product_list,
  delete_save_product,
  payment_setting,
  list_withdraw_request,
  add_withdraw_request,
  ordersbyVedorID,
  user_dashboard,
  user_vendor,
  home,
  vendor_details,
  add_review,
  blog_details,
  vendor_listing,
  order_feedback,
  subscribe,
  cancel_order_product,
  update_order_status,
  update_order_shipping,
  order_feedback_list,
  vendor_notification_list,
  clearAllNotifications,
  markAsReadNotifcation,
  footer,
  add_to_cart,
  tax_list,
  header,
} = Apis;

export async function taxApiListApi() {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + tax_list)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function registrationApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(mid + registration, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function headerApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + header, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function footerApi() {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + footer)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function accountVerifyApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        mid + accountVerify + "?email=" + data.email + "&token=" + data.token
      )
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function loginApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(mid + login, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function updateProfileCompanyApi(data) {
  let formData = new FormData();
  for (var key in data) {
    formData.append(key, data[key]);
  }
  return new Promise((resolve, reject) => {
    axios
      .post(mid + profile_company, formData)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function updateProfileKycApi(data) {
  let formData = new FormData();
  for (var key in data) {
    formData.append(key, data[key]);
  }
  return new Promise((resolve, reject) => {
    axios
      .post(mid + profile_kyc, formData)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function updatePaymentInfoApi(data) {
  let formData = new FormData();
  for (var key in data) {
    formData.append(key, data[key]);
  }
  return new Promise((resolve, reject) => {
    axios
      .post(mid + profile_payment, formData)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function updateProfileApi(data) {
  let formData = new FormData();
  for (var key in data) {
    formData.append(key, data[key]);
  }
  return new Promise((resolve, reject) => {
    axios
      .post(mid + update_profile, formData)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function updateCountriesApi(data) {
  let formData = new FormData();
  for (var key in data) {
    formData.append(key, data[key]);
  }
  return new Promise((resolve, reject) => {
    axios
      .post(mid + update_countries, formData)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function forgetPasswordApi(data) {
  let formData = new FormData();
  for (var key in data) {
    formData.append(key, data[key]);
  }
  return new Promise((resolve, reject) => {
    axios
      .post(mid + forgot_password, formData)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function reset_passwordApi(data) {
  let formData = new FormData();
  for (var key in data) {
    formData.append(key, data[key]);
  }
  return new Promise((resolve, reject) => {
    axios
      .post(mid + reset_password, formData)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function stateApi(countryId) {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + state + "?country_id=" + countryId)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getCategoryListApi(params) {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + category_list)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getOffersListApi() {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + offers_list)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getSubCategoryListApi(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + subcategory + "?category_id=" + id)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getBrandsListApi(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + brand_list)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function addNewProductApi(data) {
  let formData = new FormData();
  for (const key in data) {
    if (key === "gallery_images") {
      data[key].forEach((item, i) => {
        formData.append(`gallery_images[]`, item);
      });
    } else {
      formData.append(key, data[key]);
    }
  }
  return new Promise((resolve, reject) => {
    axios
      .post(mid + add_product, formData)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function updateProductApi(data) {
  let formData = new FormData();
  for (const key in data) {
    if (key === "gallery_images") {
      data[key].forEach((item, i) => {
        formData.append(`gallery_images[]`, item);
      });
    } else {
      formData.append(key, data[key]);
    }
  }
  return new Promise((resolve, reject) => {
    axios
      .post(mid + update_product, formData)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getProductListByVendorId(params) {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + product_list + params)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getProductDetailByIdApi(data) {
  let formData = new FormData();
  for (var key in data) {
    formData.append(key, data[key]);
  }
  return new Promise((resolve, reject) => {
    axios
      .post(mid + product_details, formData)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getProductAttributeApi() {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + attribute)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function addProductVariantByVendorApi(data) {
  let formData = new FormData();
  for (const key in data) {
    if (key === "gallery_images_variant") {
      data[key].forEach((item, i) => {
        formData.append(`gallery_images_variant[]`, item);
      });
    } else {
      formData.append(key, data[key]);
    }
  }
  return new Promise((resolve, reject) => {
    axios
      .post(mid + add_product_variant, formData)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getProductVariantListByProductIdApi(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + product_variants + "?product_id=" + id)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function deleteProductVariantByProductApi(data) {
  let formData = new FormData();
  for (var key in data) {
    formData.append(key, data[key]);
  }
  return new Promise((resolve, reject) => {
    axios
      .post(mid + delete_product_variant, formData)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function UpdateProductVariantByIdApi(data) {
  let formData = new FormData();
  for (var key in data) {
    formData.append(key, data[key]);
  }
  return new Promise((resolve, reject) => {
    axios
      .post(mid + update_product_variant, formData)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getAdvertisementListApi(params) {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + advertisement + params)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getProductListingForUserApi(params) {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + product_listing + params)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getBlogListingApi(params) {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + blog + params)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getBlogCategoriesApi() {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + blog_category)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function add_addressByIdApi(data) {
  let formData = new FormData();
  for (var key in data) {
    formData.append(key, data[key]);
  }
  return new Promise((resolve, reject) => {
    axios
      .post(mid + add_address, formData)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function updateAddressByAddressIdApi(data) {
  let formData = new FormData();
  for (var key in data) {
    formData.append(key, data[key]);
  }
  return new Promise((resolve, reject) => {
    axios
      .post(mid + update_address, formData)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getAddressListByUserIdApi(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + address_list + "?id=" + id)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function deleteAddressByAddressIdApi(id) {
  return new Promise((resolve, reject) => {
    axios
      .post(mid + delete_address + "?id=" + id)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function addOrderApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(mid + add_order, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getOrderListByUserIdApi(params) {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + orders + params)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function privacyPolicyApi() {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + privacy_policy)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function termsConditionApi() {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + terms_condition)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function affiliateApi() {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + affiliate)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function walletTcApi() {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + wallet_tc)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function inNewsApi() {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + in_news)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function vendorConnectApi() {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + vendor_connect)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function aboutUsApi() {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + about_us)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function changePasswordApi(data) {
  let formData = new FormData();
  for (var key in data) {
    formData.append(key, data[key]);
  }
  return new Promise((resolve, reject) => {
    axios
      .post(mid + change_password, formData)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function contactUsApi(data) {
  let formData = new FormData();
  for (var key in data) {
    formData.append(key, data[key]);
  }
  return new Promise((resolve, reject) => {
    axios
      .post(mid + contact_us, formData)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function postSellInfo(data) {
  let formData = new FormData();
  for (var key in data) {
    formData.append(key, data[key]);
  }
  return new Promise((resolve, reject) => {
    axios
      .post(mid + seller_contact_us, formData)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getOrderDetailByOrderId(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + order_details + "?id=" + id)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function orderCancelApi(data) {
  let formData = new FormData();
  for (var key in data) {
    formData.append(key, data[key]);
  }
  return new Promise((resolve, reject) => {
    axios
      .post(mid + order_cancel, formData)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function addProductToWishlistApi(data) {
  let formData = new FormData();
  for (var key in data) {
    formData.append(key, data[key]);
  }
  return new Promise((resolve, reject) => {
    axios
      .post(mid + add_save_product, formData)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getWishListByUserIdApi(params) {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + save_product_list + params)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function deleteWishlistProductByIdApi(data) {
  let formData = new FormData();
  for (var key in data) {
    formData.append(key, data[key]);
  }
  return new Promise((resolve, reject) => {
    axios
      .post(mid + delete_save_product, formData)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getPaymentSettingApi() {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + payment_setting)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function GetListWithdrawRequestApi(params) {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + list_withdraw_request + params)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function moneyWithRequestApi(data) {
  // let formData = new FormData();
  // for (var key in data) {
  //   formData.append(key, data[key])
  // }
  return new Promise((resolve, reject) => {
    axios
      .post(mid + add_withdraw_request, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getOrderListByVendorIdApi(params) {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + ordersbyVedorID + params)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getUserDashboardDetailsApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(mid + user_dashboard, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getVendorDashboardDetailsApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(mid + user_vendor, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getHomepageApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + home)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getVendorDetailsByIdApi(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + vendor_details + "?user_id=" + id)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function addReviewByUserApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(mid + add_review, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getBlogPostDetailsByIdApi(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + blog_details + `?id=` + id)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getVendorLIstByCountryId(params) {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + vendor_listing + params)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function orderFeedbackByUserIdApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(mid + order_feedback, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function SubscribedNewsletterApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(mid + subscribe, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function cancelOrderProductApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(mid + cancel_order_product, data)
      .then((result) => resolve(result))
      .catch((error) => {
        reject(error);
      });
  });
}

export async function updateOrderStatusApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(mid + update_order_status, data)
      .then((result) => resolve(result))
      .catch((error) => {
        reject(error);
      });
  });
}

export async function updateShippingStatusApis(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(mid + update_order_shipping, data)
      .then((result) => resolve(result))
      .catch((error) => {
        reject(error);
      });
  });
}

export async function fetchvendorPaymentGraph(data) {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + "vendorPaymentGraph/" + data)
      .then((result) => {
        resolve(result);
        console.log(result, "result result");
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function orderFeedbackListByVendorIdApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(mid + order_feedback_list, data)
      .then((result) => resolve(result))
      .catch((error) => {
        reject(error);
      });
  });
}

export async function vendorNotificationApi(params) {
  return new Promise((resolve, reject) => {
    axios
      .get(mid + vendor_notification_list + params)
      .then((result) => resolve(result))
      .catch((error) => {
        reject(error);
      });
  });
}

export async function ClearAllNotificationsApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(mid + clearAllNotifications, data)
      .then((result) => resolve(result))
      .catch((error) => {
        reject(error);
      });
  });
}

export async function markAsReadNotificationApi(params) {
  return new Promise((resolve, reject) => {
    axios
      .post(mid + markAsReadNotifcation + params)
      .then((result) => resolve(result))
      .catch((error) => {
        reject(error);
      });
  });
}
export async function addToCartApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(mid + add_to_cart + data)
      .then((result) => resolve(result))
      .catch((error) => {
        reject(error);
      });
  });
}

// export async function getProductDetailByIdApi(data) {
// let formData=new FormData()
//   return new Promise((resolve, reject) => {
//     axios
//       .get(mid + product_details+formData)
//       .then((result) => resolve(result))
//       .catch((error) => reject(error))
//   })
// }
