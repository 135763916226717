import React, { useEffect, useState } from "react";
import "./Blog.scss";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import brands from "../../assets/img/brands1.png";
import brands2 from "../../assets/img/brands2.png";
import brands3 from "../../assets/img/brands3.png";
import Listing1 from "../../assets/img/valuable1.png";
import Listing2 from "../../assets/img/valuable2.png";
import Listing3 from "../../assets/img/valuable3.png";
import { useDispatch, useSelector } from "react-redux";
import { GET_BLOG_CATEGORIES, GET_BLOG_LISTING, globalAction, SHOW_LOADING } from "../../redux";
import Loading from "../../components/Loading/Loading";
import ReactPaginate from "react-paginate";
import { useRef } from "react";

function BlogListing(props) {
  const {
    blogCategories,
    blogListing
  } = useSelector(state => state.appReducer)
  const {
    isLoading,
  } =
  useSelector((state) => state.errorReducer);
  const dispatch = useDispatch()
  const [searchQuery, setSearchQuery] = useState(undefined);
  const myFormRef = useRef()

  const{match:{params}}=props
  let hasUrlSlug=params.slug;

  let filterObj={
    limit:5
  }

  useEffect(() => {
    window.scroll({top:0})
    dispatch(globalAction(SHOW_LOADING))
    dispatch(globalAction(GET_BLOG_CATEGORIES))
    if(hasUrlSlug){
      filterObj['page']=1;
      filterObj['category_id']=hasUrlSlug
      delete filterObj['search']
    }
    dispatch(globalAction(GET_BLOG_LISTING,filterObj))
  }, [hasUrlSlug])




  const handlePageClick = (data) => {
    document.getElementById('blog_card_wrap').scrollIntoView(true)
    dispatch(globalAction(SHOW_LOADING))
    filterObj['page'] = data.selected + 1
    dispatch(globalAction(GET_BLOG_LISTING, filterObj));// get Product list 
  }
 
  const handleOnSubmit=(e)=>{
    e.preventDefault()
    delete filterObj['category_id']
    delete filterObj['limit']
    filterObj['search']=searchQuery;
    filterObj['page']=1;
    filterObj['limit']=5;
    dispatch(globalAction(SHOW_LOADING))
    dispatch(globalAction(GET_BLOG_LISTING, filterObj));
    setSearchQuery('')
    myFormRef.current.reset()
  }
  return (
    <section className="blog spacing">
      <Loading
        showLoading={isLoading}
      />
      <div className="container">
        <div className="blog_listing">
          <aside className="blog_aside">
            <form 
            ref={myFormRef}
            className="search"
             onSubmit={(e)=>{handleOnSubmit(e)}}>
              <input 
              type="text"
              onChange={(e)=>{setSearchQuery(e.target.value)}}
               />
              <span className="icon"
              onClick={(e)=>{handleOnSubmit(e)}}>
                <FaSearch />
              </span>
        
             
            </form>
            <div className="blog_category">
              <h4>Categories</h4>
              <ul>
                {blogCategories &&
                  blogCategories?.data?.length > 0 &&
                  blogCategories?.data.map((item, i) => {
                    return (
                      <li>
                        <Link  
                        to={`/blog/${item.id}`}
                        >{item.name}</Link>
                      </li>
                    )

                  })
                }



              </ul>
            </div>
            {/* <div className="popular_post" >
              <h4>Popular Post</h4>
              <ul>
                {PopularPost.map((itme, index) => (
                  <li>
                    <Link to={itme.link}>
                      <img src={itme.image} alt={itme.ait} />
                      <p>{itme.title}</p>
                    </Link>
                  </li>
                ))}
              </ul>
            </div> */}
          </aside>
          <div className="blog_main">
            <div className="blog_card_wrap" id="blog_card_wrap">
            {blogListing &&
                  blogListing?.data?.length > 0 &&
                  blogListing?.data.map((item, index) => (
                <div className="blog_card">
                  <div className="featured_img">
                    <img src={item.image} alt={item.title} />
                  </div>
                  <div className="listing_content">
                    <Link  
                    to={`/blog-post/${item.id}`}
                    >
                      <h3>{item.title}</h3>
                    </Link>
                    <p>{item.short_description||'Description is the pattern of narrative development that aims to make vivid a place, object, character, or group. Description is one of four rhetorical modes, along with exposition, argumentation, and narration. In practice it would be difficult to write literature that drew on just one of the four basic modes'}</p>
                    <div className="button_wrap">
                      <Link  
                       to={`/blog-post/${item.id}`}
                      className="btn btn-brown">
                        Read More...
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {blogListing &&
            blogListing?.data?.length > 0 ?
                <div className="pcw-pagination">
                  <div className="pcw-pagination-inner">
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(
                        blogListing?.total / blogListing?.per_page
                      )}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={
                        "pagination justify-content-center"
                      }
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                      forcePage={Number(blogListing?.current_page - 1)}
                    />
                  </div>
                </div>
                :
                <div className="not-product">
                  <h4>No Blog Found ...!</h4>
                </div>
              }
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogListing;
