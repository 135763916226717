// Initial State

import { LOGOUT, SET_USER } from "../actionsType";

const initialState = {
  user: JSON.parse(localStorage.getItem("duAfirk_user")),
  isLogin: localStorage.getItem("duAfirk_token") ? true : false,
};
console.log(localStorage.getItem("duAfirk_token") ? true : false);
// Redux: Counter Reducer
const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_USER: {
      return {
        ...state,
        user: payload,
        isLogin: localStorage.getItem("duAfirk_token") ? true : false,
      };
    }
    case LOGOUT: {
      localStorage.clear();
      return {
        ...state,
        user: null,
        isLogin: false,
      };
    }

    default: {
      return state;
    }
  }
};
// Exports
export default authReducer;
