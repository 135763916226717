import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./HomeBanner.scss";

function HomeBanner(props) {
  const { img, imgAltText, buttonLink, buttonText, bannerTitle } = props;
  return (
    <div className={`h-banner-wrap ${img ? "with-image" : "no-image"}`}>
      <div className="h-banner-inner isBanner">
        {img && <img src={img} alt={imgAltText} />}
      </div>

      <div className="banner_content">
        <div className="container">
          <h1>{bannerTitle}</h1>
        </div>
      </div>
      {/* <div className="h-banner-btn-wrap">
                <a 
                href={buttonLink}
                className="banner-btn-1"
                >
                    {buttonText}
                    </a>
            </div> */}
    </div>
  );
}

export default HomeBanner;
