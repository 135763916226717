import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_PRODUCT_LIST, globalAction } from "../../../../../redux";
import tableIcons from "../../../../Dashboard/TableIcons";
import "./ViewProduct.scss";
import { AiTwotoneEdit } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import { getProductListByVendorId } from "../../../../../api";
import axios from "axios";

function ViewProduct(props) {
  const { productList } = useSelector((state) => state.appReducer);
  const { user } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");

  const deleteProduct = (data) => {
    const confrm = window.confirm("Are you sure to delete ?");
    if (!confrm) return;
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}api/delete_product?id=${data.id}`
      )
      .then((res) => {
        if (res?.status === 200) {
          window.location.reload();
        }
      })
      .catch((err) => alert(err));
  };

  const orderTitle = [
    { title: " ID", field: "id" },
    {
      title: "Image",
      field: "image_url",
      render: (rowData) => (
        <Link to={`/product-detail/${rowData?.slug}`}>
          <img
            className="p-image"
            src={rowData?.image_url || rowData?.product_variant?.[0]?.image}
            style={{ width: 100, height: 100, borderRadius: "50%" }}
          />
        </Link>
      ),
    },
    {
      title: "Name",
      field: "title",
      render: (rowData) => (
        <Link to={`/product-detail/${rowData?.slug}`}>{rowData?.title}</Link>
      ),
    },
    { title: "Category", field: "category_name" },
    {
      title: "Price",
      field: productList?.sale_price !== null ? "sale_price" : "regular_price",
    },
    { title: "QTY", field: "stock_qty" },
    {
      title: "QTY Status",
      field: "stock_availability",
      render: (rowData) =>
        rowData?.stock_availability === "out_stock"
          ? "Out of Stock"
          : "In Stock",
    },
    {
      title: "Edit",
      render: (rowData) => (
        <a
          title="Edit"
          href={`/vendorDashboard/product-management/update-product/${rowData?.id}`}
          className="editButton"
        >
          <span>
            <AiTwotoneEdit />
          </span>
        </a>
      ),
    },
    {
      title: "Delete",
      render: (rowData) => (
        <div>
          <span
            onClick={() => deleteProduct(rowData)}
            style={{ cursor: "pointer" }}
          >
            <MdDelete />
          </span>
        </div>
      ),
    },
  ];
  return (
    <div className="all-main-product-wrap">
      <div className="amp-inner">
        <div className="title">
          <h3>All Products</h3>
        </div>
        <div className="vd-dashboard-body box-size amp-content">
          {/* <div className="searchbar">
            <input
              placeholder="Search..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div> */}
          <MaterialTable
            title=""
            columns={orderTitle}
            icons={tableIcons}
            options={{
              search: true,
              // exportButton: true,
              pageSize: 10,
              pageSizeOptions: [5, 10, 20],
            }}
            localization={{
              body: {
                emptyDataSourceMessage: "No records to display",
              },
            }}
            data={(query) =>
              new Promise((resolve, reject) => {
                console.log(query, "query");
                let params = `?user_id=${user?.id}`;
                params += `&page=${query.page + 1}`;
                params += `&limit=${query.pageSize}`;
                params += `&search=${query.search}`;
                if (user) {
                  // prepare your data and then call resolve like this:
                  // console.log(params)
                  getProductListByVendorId(params).then((res) => {
                    let { data, status } = res;
                    console.log("getOrderListByUserIdApi", data.product);
                    if (status === 200) {
                      resolve({
                        data: data?.product?.data,
                        page: data?.product?.current_page - 1, // current page number
                        totalCount: data?.product?.total, // total row number
                      });
                    }
                  });
                }
              })
            }
          />
        </div>
      </div>
    </div>
  );
}

export default ViewProduct;
