import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Button, Col, Form, Row, SplitButton } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { stateApi } from "../../api";
import {
  ADD_ADDRESS_AS_GUEST,
  ADD_ADDRESS_BY_USER_ID,
  globalAction,
  SHOW_LOADING,
} from "../../redux";
import AllCountry from "../../Utilis/allCountry.json";
import PhoneInput from "react-phone-input-2";

function FillAddress(props) {
  const [validated, setValidated] = useState(false);
  const [shippingAddess, setshippingAddess] = useState({ type: "ForShipping" });
  const [billingAddess, setbillingAddess] = useState({ type: "ForBilling" });
  const [states, setStates] = useState([]);
  const [billingSameAsDelivery, setBillingSameAsDelivery] = useState(false);
  const fromRef = useRef();
  const dispatch = useDispatch();

  const getStateByCountryId = async (id) => {
    await stateApi(id).then(async (res) => {
      if (res.status === 200) {
        await setStates(res.data.state_list);
      }
    });
  };

  const changeText = (value, fieldName, addressType) => {
    if (addressType === "billing_address") {
      setbillingAddess({ ...billingAddess, [fieldName]: value });
      if (fieldName === "country") {
        getStateByCountryId(value);
        let selectedCountry = AllCountry.find((item, i) => item.id == value);
        setbillingAddess({
          ...billingAddess,
          country_data: selectedCountry,
          country: selectedCountry.id,
        });
      }
      if (fieldName === "state") {
        let selectedState = states.find((item, i) => item.id == value);
        setbillingAddess({
          ...billingAddess,
          state_data: selectedState,
          state: selectedState.id,
        });
      }
    } else {
      setshippingAddess({ ...shippingAddess, [fieldName]: value });
      if (fieldName === "country") {
        getStateByCountryId(value);
        let selectedCountry = AllCountry.find((item, i) => item.id == value);
        setshippingAddess({
          ...shippingAddess,
          country_data: selectedCountry,
          country: selectedCountry.id,
        });
      }
      if (fieldName === "state") {
        let selectedState = states.find((item, i) => item.id == value);
        setshippingAddess({
          ...shippingAddess,
          state_data: selectedState,
          state: selectedState.id,
        });
      }
    }
  };

  const handleSubmit = (e, formType) => {
    const form = e.currentTarget;
    let finalData = [shippingAddess, billingAddess];
    if (billingSameAsDelivery) {
      finalData = [shippingAddess, shippingAddess];
    }
    e.preventDefault();
    if (form.checkValidity() === false) {
      e.stopPropagation();
      alert("Carefully fill required fields");
    } else {
      finalData.forEach((item, i) => {
        dispatch(globalAction(SHOW_LOADING));
        dispatch(globalAction(ADD_ADDRESS_AS_GUEST, item));
      });
      let ShippingInfo = {
        billing_address: finalData[1],
        shipping_addess: finalData[0],
      };
      localStorage.setItem("ShippingInfo", JSON.stringify(ShippingInfo));
      props.history.push("/order-summary-as-guest");
    }
    setValidated(true);
  };

  return (
    <div className="fill-address-wrap">
      <div className="fill-address-inner cm-container">
        <div className="title">
          <h1>Address</h1>
        </div>

        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          ref={fromRef}
          autoComplete="false"
        >
          <Row>
            <Col md>
              <Form.Group className="mb-4" controlId="full_name">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter full name"
                  className="input"
                  // defaultValue={formData?.full_name}
                  onChange={(e) => changeText(e.target.value, "full_name")}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md>
              <Form.Group className="mb-4" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email "
                  className="input"
                  // defaultValue={formData?.email}
                  onChange={(e) => changeText(e.target.value, "email")}
                />
                <Form.Text id="Companywebsiturl" muted>
                  (Its optional)
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md>
              <Form.Group className="mb-4" controlId="mobile_no">
                <Form.Label>Mobile Number </Form.Label>
                <Form.Control
                  required
                  type="tel"
                  placeholder="Enter  mobile number"
                  className="input"
                  // defaultValue={formData?.mobile_no}
                  onChange={(e) => changeText(e.target.value, "mobile_no")}
                />
                {/* <PhoneInput
                  country="us"
                  fullWidth
                  name="phone_number"
                  id="phone_number"
                  inputStyle={{
                    border: "none",
                    background: "#ffffff",
                    height: "33px",
                    paddingTop: "15px",
                    width: "100%",
                    boxShadow: "none",
                    outline: "none",
                  }}
                  containerStyle={{
                    border: "1px solid #000000",
                    height: "35px",
                    background: "#ffffff",
                    boxShadow: "none",
                  }}
                  //   value={phoneNumber}
                  onChange={(value) => changeText(value, "mobile_no")}
                /> */}
              </Form.Group>
            </Col>
            <Col md>
              <Form.Group className="mb-4" controlId="Country">
                <Form.Label>Country </Form.Label>
                <Form.Select
                  aria-label="Floating label select example"
                  required
                  className="input"
                  // onLoad={() => {
                  //     dataForUpdate?.country && getStateByCountryId(Number((dataForUpdate?.country)))
                  // }}
                  onChange={(e) => changeText(e.target.value, "country")}
                >
                  <option>Select country</option>
                  {AllCountry.map((indCountry, i) => {
                    return (
                      <option
                        key={indCountry.name}
                        value={indCountry.id}
                        // selected={formData?.country == indCountry.id}
                      >
                        {indCountry.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md>
              <Form.Group className="mb-4" controlId="state">
                <Form.Label>State </Form.Label>
                <Form.Select
                  aria-label="Floating label select example"
                  required
                  type="text"
                  className="input"
                  aria-readonly={states.length > 0 ? false : true}
                  disabled={states.length > 0 ? false : true}
                  onChange={(e) => changeText(e.target.value, "state")}
                >
                  <option>Select state</option>
                  {states.map((item, id) => {
                    return (
                      <option key={item.name} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md>
              <Form.Group className="mb-4" controlId="zip">
                <Form.Label>Zip Code </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter  zip code"
                  className="input"
                  // defaultValue={formData?.zip}
                  onChange={(e) => changeText(e.target.value, "zip")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-4" controlId="address_line_1">
            <Form.Label>Address</Form.Label>
            <Form.Control
              required={true}
              type="text"
              placeholder="Enter address"
              as="textarea"
              style={{ height: "100px" }}
              className="input"
              // defaultValue={formData?.address_line_1}
              onChange={(e) => changeText(e.target.value, "address_line_1")}
            />
          </Form.Group>

          <Form.Group className="mb-4" controlId="address_line_2">
            <Form.Label>Additional Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter additional address"
              as="textarea"
              style={{ height: "100px" }}
              className="input"
              // defaultValue={formData?.address_line_2}
              onChange={(e) => changeText(e.target.value, "address_line_2")}
            />
            <Form.Text id="Companywebsiturl" muted>
              (Its optional)
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3" controlId="default_address">
            <Form.Check
              type="checkbox"
              // checked={setAsDefaultAddress}
              // value={setAsDefaultAddress ? 'yes' : 'no'}
              label="Billing address same as delivery address"
              // defaultValue={formData?.default_address}
              onChange={(e) => {
                setBillingSameAsDelivery(!billingSameAsDelivery);
                if (e.target.value) {
                  setbillingAddess({ type: "ForBilling" });
                  localStorage.getItem("billingId");
                }
              }}
            />
          </Form.Group>
          {!billingSameAsDelivery && (
            <div className="billing-address-wrap">
              <div className="title">
                <h1>Billing Address</h1>
              </div>
              <Form
                noValidate
                validated={validated}
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
                ref={fromRef}
              >
                <Row>
                  <Col md>
                    <Form.Group className="mb-4" controlId="full_name">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter full name"
                        className="input"
                        // defaultValue={formData?.full_name}
                        onChange={(e) =>
                          changeText(
                            e.target.value,
                            "full_name",
                            "billing_address"
                          )
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md>
                    <Form.Group className="mb-4" controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email "
                        className="input"
                        // defaultValue={formData?.email}
                        onChange={(e) =>
                          changeText(e.target.value, "email", "billing_address")
                        }
                      />
                      <Form.Text id="Companywebsiturl" muted>
                        (Its optional)
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md>
                    <Form.Group className="mb-4" controlId="mobile_no">
                      <Form.Label>Mobile Number </Form.Label>
                      <Form.Control
                        required
                        type="tel"
                        placeholder="Enter  mobile number"
                        className="input"
                        // defaultValue={formData?.mobile_no}
                        onChange={(e) =>
                          changeText(
                            e.target.value,
                            "mobile_no",
                            "billing_address"
                          )
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md>
                    <Form.Group className="mb-4" controlId="Country">
                      <Form.Label>Country </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        required
                        className="input"
                        // onLoad={() => {
                        //     dataForUpdate?.country && getStateByCountryId(Number((dataForUpdate?.country)))
                        // }}
                        onChange={(e) =>
                          changeText(
                            e.target.value,
                            "country",
                            "billing_address"
                          )
                        }
                      >
                        <option>Select country</option>
                        {AllCountry.map((indCountry, i) => {
                          return (
                            <option
                              key={indCountry.name}
                              value={indCountry.id}
                              // selected={formData?.country == indCountry.id}
                            >
                              {indCountry.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md>
                    <Form.Group className="mb-4" controlId="state">
                      <Form.Label>State </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        required
                        type="text"
                        className="input"
                        aria-readonly={states.length > 0 ? false : true}
                        disabled={states.length > 0 ? false : true}
                        onChange={(e) =>
                          changeText(e.target.value, "state", "billing_address")
                        }
                      >
                        <option>Select state</option>
                        {states.map((item, id) => {
                          return (
                            <option
                              key={item.name}
                              value={item.id}
                              selected={billingAddess?.state == item.id}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md>
                    <Form.Group className="mb-4" controlId="zip">
                      <Form.Label>Zip Code </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter  zip code"
                        className="input"
                        // defaultValue={formData?.zip}
                        onChange={(e) =>
                          changeText(e.target.value, "zip", "billing_address")
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="mb-4" controlId="address_line_1">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    required={true}
                    type="text"
                    placeholder="Enter address"
                    as="textarea"
                    style={{ height: "100px" }}
                    className="input"
                    // defaultValue={formData?.address_line_1}
                    onChange={(e) =>
                      changeText(
                        e.target.value,
                        "address_line_1",
                        "billing_address"
                      )
                    }
                  />
                </Form.Group>

                <Form.Group className="mb-4" controlId="address_line_2">
                  <Form.Label>Additional Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter additional address"
                    as="textarea"
                    style={{ height: "100px" }}
                    className="input"
                    // defaultValue={formData?.address_line_2}
                    onChange={(e) =>
                      changeText(
                        e.target.value,
                        "address_line_2",
                        "billing_address"
                      )
                    }
                  />
                  <Form.Text id="Companywebsiturl" muted>
                    (Its optional)
                  </Form.Text>
                </Form.Group>
              </Form>
            </div>
          )}

          <Button className="btn-black " variant="primary" type="submit">
            Checkout
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default FillAddress;
