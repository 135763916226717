import React, { useState } from 'react';
import { BsWallet2 } from 'react-icons/bs';
import './ShippingInfo.scss';
;


function ShippingInfo() {
    const [defaultShippingMethod, setDefaultShippingMethod] = useState(3);


    return (
        <div className="vd-dashboard-wrap shipping-info">
            <div className="title">
                <h3>Shipping Info</h3>
            </div>
            <div className="wallet box-size">
                <div className="wallet_inner">

                    <div
                        className={`_card ${defaultShippingMethod === 1 ? 'active' : ''}`}
                        onClick={() => { 
                            alert('Soory, We are working on it. we are fixed very soon')
                            // setDefaultShippingMethod(1)
                         }}
                    >
                        <div className="card_content">
                            <h5>
                                Free shipping :-<span></span>
                            </h5>
                            <p>Free Shipping not need any price changes when item is shipped with this method there is no shipping charge will apply.</p>
                        </div>
                        {defaultShippingMethod === 1 &&
                            <div className="active-badge">
                                     <span>Default</span>  
                            </div>
                        }
                    </div>

                    <div className={`_card ${defaultShippingMethod === 2 ? 'active' : ''}`}
                        onClick={() => { 
                            alert('Soory, We are working on it. we are fixed very soon')
                            // setDefaultShippingMethod(2) 
                        }}
                    >
                        <div className="card_content">
                            <h5>
                                Flat Rate :-<span></span>
                            </h5>
                            <p>Price:- <strong>$50</strong></p>
                            <p>Price can be change by admin</p>
                            <p>Any item shipped with this method  means global shipping charge will apply on all products.</p>

                        </div>
                        {defaultShippingMethod === 2 &&
                            <div className="active-badge">
                              <span>Default</span>  
                            </div>
                        }
                    </div>

                    <div className={`_card ${defaultShippingMethod === 3 ? 'active' : ''}`}
                        onClick={() => { setDefaultShippingMethod(3) }}
                    >
                        <div className="card_content">
                            <h5>
                                Shipping Price :-<span></span>
                            </h5>
                            <p>Shipping price means shipping price by weight of product.</p>
                        </div>
                        {defaultShippingMethod === 3 &&
                            <div className="active-badge">
                                     <span>Default</span>  
                            </div>
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ShippingInfo;
