import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Header.scss";
import {
  IoIosArrowDown,
  IoIosNotifications,
  IoIosNotificationsOutline,
  IoMdNotifications,
} from "react-icons/io";
import { useDispatch } from "react-redux";
import { globalAction, LOGOUT, SET_VENDOR_NOTIFICATIONS } from "../../redux";
import { BiWallet } from "react-icons/bi";
import { FaShoppingCart, FaWallet } from "react-icons/fa";
import { BsDash } from "react-icons/bs";
import { useEffect } from "react";
import {
  ClearAllNotificationsApi,
  markAsReadNotificationApi,
  vendorNotificationApi,
} from "../../api";
import { useSelector } from "react-redux";
import setCookie from "../../Utilis/setCookies";

function Header(props) {
  const dispatch = useDispatch();
  const { logo, user, cartList } = props;
  const { notificationList } = useSelector((state) => state.appReducer);
  const [showNotification, setShowNotification] = useState(false);
  // const [notificationList, setNotificationList] = useState(undefined)
  const logout = (e) => {
    dispatch(globalAction(LOGOUT));
  };

  const getNotifications = (params) => {
    vendorNotificationApi(params)
      .then((res) => {
        const { status, data } = res;
        if (status === 200) {
          dispatch(globalAction(SET_VENDOR_NOTIFICATIONS, data.data.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClearAllNotification = () => {
    ClearAllNotificationsApi({ vendor_id: user?.id })
      .then((res) => {
        let params = `?vendor_id=${user?.id}`;
        getNotifications(params);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleMarkAsReadNotification = (notificationId) => {
    setShowNotification(false);
    markAsReadNotificationApi(`?id=${notificationId}`)
      .then((res) => {
        let params = `?vendor_id=${user?.id}`;
        getNotifications(params);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // GOOGLE TRANSLATOR CODE

  const googleTranslateElementInit = () => {
    setCookie("googtrans", "/en/en", 1);
    new window.google.translate.TranslateElement(
      {
        // pageLanguage: "en",
        autoDisplay: false,
        // defaultLanguage: "en",
        includedLanguages: "en,fr,pt",
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    addScript.async = true;
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
    <div class="dashboard-header-wrap">
      <div class="dh-wrap-inner clearfix">
        <div className="dh-col-left dh-col">
          <div className="dh-col-left-inner dh-col-inner">
            <div className="logo-wrap">
              <Link to="/">
                <img src={logo} alt="Du-Afrik" />
              </Link>
            </div>
          </div>
        </div>

        <div className="dh-col-right dh-col">
          <div className="dh-col-right-inner dh-col-inner">
            <div
              className="dh-notification-wrap"
              style={{
                float: "left",
                "background-color": "white",
                display: "inline",
                "@media (max-width: 500px)": {
                  display: "none",
                },
              }}
            >
              <div class="navbar-wrapper">
                {user.role_id == 3 ? (
                  <a class="navbar-brand" href="/vendorDashboard">
                    Seller’s Panel<div class="ripple-container"></div>
                  </a>
                ) : (
                  <a class="navbar-brand" href="/vendorDashboard">
                    Customer’s Panel<div class="ripple-container"></div>
                  </a>
                )}
              </div>
            </div>

            {user?.role_id === 3 && (
              <div
                className="dh-notification-wrap notification"
                onClick={() => setShowNotification(!showNotification)}
              >
                <span className="dh-notification-icon">
                  <IoIosNotifications />(
                  {notificationList?.filter((item) => item.read_status === 0)
                    ?.length || 0}
                  )
                </span>
                {showNotification && (
                  <div className="nf-wrap">
                    <div className="top-section">
                      <h6>Notification</h6>
                      {notificationList?.filter(
                        (item) => item.read_status === 0
                      )?.length > 0 && (
                        <button
                          onClick={() => handleClearAllNotification()}
                          className="btn-black"
                        >
                          Read All
                        </button>
                      )}
                    </div>

                    <div className="nf-list">
                      {notificationList?.length > 0 ? (
                        <ul>
                          {notificationList.map((item, i) => {
                            return (
                              <li
                                style={{
                                  opacity: item?.read_status ? "0.6" : "1",
                                }}
                              >
                                <p>
                                  <Link
                                    to={
                                      item?.route === "orders"
                                        ? `/vendorDashboard/order-detail/${item?.route_id}`
                                        : `/vendorDashboard/wallet`
                                    }
                                    onClick={() => {
                                      handleMarkAsReadNotification(item?.id);
                                    }}
                                  >
                                    <em>
                                      <strong>{item?.title}</strong>
                                    </em>{" "}
                                    <BsDash />
                                    {item?.body}
                                  </Link>
                                </p>
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        <ul>
                          <li>
                            <p> No Notification Found...!</p>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="dh-profile-menu">
              <div id="google_translate_element"></div>
              {/* <ul className="level-1">
                <li>
                  {user?.name}
                  <span className="dropdown-icon">
                    <IoIosArrowDown />
                  </span>
                  <ul className="level-2">
                    <li>
                      <Link
                        to="/login"
                        onClick={(e) => {
                          logout(e);
                        }}
                      >
                        Logout
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul> */}
            </div>
            <div className="dh-notification-wrap">
              <span className="dh-notification-icon">
                {user?.role_id === 3 && (
                  <Link to="/vendorDashboard/wallet">
                    <FaWallet />
                    <span>$ {user?.wallet_amount || 0} </span>
                  </Link>
                )}
                {user?.role_id === 2 && (
                  <Link to="/dashboard/cart">
                    <FaShoppingCart />
                    <span>{cartList?.length}</span>
                  </Link>
                )}
              </span>
            </div>

            <div className="dh-profile-menu-wrap">
              <div className="dh-profile-image-wrap">
                <div className="img">
                  <p>
                    {user?.name.split(" ").map((item) => {
                      let tempString = "";
                      tempString += item[0];
                      return tempString.toUpperCase();
                    })}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
