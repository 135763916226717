import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { globalAction, SHOW_LOADING, UPDATE_PAYMENT_INFO } from '../../../redux';


function UpdatePaymentInfo() {

    const [formData, setFormData] = useState({})
    const [validated, setValidated] = useState(false);
    const { user } = useSelector(state => state.authReducer)
    const dispatch = useDispatch()


    const changeText = (value, fieldName) => {
        let credentials = formData;
        credentials[fieldName] = value
        setFormData(credentials)
    }

    const handleSubmit = (e, formType) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === false) {
            e.stopPropagation();
            alert("Please Filled all fields")
        }
        else {
            dispatch(globalAction(SHOW_LOADING))
            dispatch(globalAction(UPDATE_PAYMENT_INFO, formData))
        }
        setValidated(true);
    }

    useEffect(() => {
        if (user) {
            let tempObj = {
                id: user?.id,
                bank_name: user?.bank_name,
                ifsc_code: user?.ifsc_code,
                account_number: user?.account_number,
                account_holder_name: user?.account_holder_name,
               
            }
            setFormData(tempObj)
        }
    }, [user])


    return (
        <div className="payment-update-info">
            <div class="title"><h3>Edit Payment Info</h3></div>
            <div className="ftd-fw-form payment-info">

                <Form
                    noValidate
                    validated={validated}
                    onSubmit={(e) => { handleSubmit(e, 'paymentInfo') }}
                >
                    <Row >
                        <Col md>
                            <Form.Group className="mb-4" controlId="bankName">
                                <Form.Label>Bank Name </Form.Label><span className='req'>*</span>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Enter Bank Name"
                                    defaultValue={formData?.bank_name}
                                    className="input"
                                    onChange={(e) => changeText(e.target.value, 'bank_name')}
                                />
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-4" controlId="bankAccountNumber">
                                <Form.Label>Bank Account Number</Form.Label><span className='req'>*</span>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Enter Bank Account Number"
                                    className="input"
                                    defaultValue={formData?.account_number}
                                    onChange={(e) => changeText(e.target.value, 'account_number')}
                                />
                            </Form.Group>

                        </Col>
                    </Row>

                    <Row >
                        <Col md>
                            <Form.Group className="mb-4" controlId="bankAccountNumber">
                                <Form.Label>Bank IFSC Code</Form.Label><span className='req'>*</span>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Enter Bank IFSC Code"
                                    className="input"
                                    defaultValue={formData?.ifsc_code}
                                    onChange={(e) => changeText(e.target.value, 'ifsc_code')}
                                />
                            </Form.Group>

                        </Col>
                        <Col md>
                            <Form.Group className="mb-4" controlId="bankAccountNumber">
                                <Form.Label>Bank Account Holder Name</Form.Label><span className='req'>*</span>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Enter Account Holder Name"
                                    className="input"
                                    defaultValue={formData?.account_holder_name}
                                    onChange={(e) => changeText(e.target.value, 'account_holder_name')}
                                />
                            </Form.Group>

                        </Col>
                    </Row>

                    <Button variant="primary" type="submit" className="btn-brown">
                        Update
                    </Button>
                </Form>
            </div>
        </div>

    )
}

export default UpdatePaymentInfo
