import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCategoryListApi } from "../../api";
import Loading from "../../components/Loading/Loading";
import { CLOSE_LOADING, globalAction, SHOW_LOADING } from "../../redux";
import "./CategoryListing.scss";
import adv1 from "../../assets/img/adv-2.gif";
import adv2 from "../../assets/img/adv-2.gif";
import Slider from "react-slick";

function CategoryListing() {
  const [list, setList] = useState(undefined);
  const { isLoading } = useSelector((state) => state.errorReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(globalAction(SHOW_LOADING));
    getCategoryListApi()
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          setList(data.category.data);
        }
      })
      .catch((err) => {
        console.log(err);
        // alert('Something went wrong')
      })
      .finally(() => {
        dispatch(globalAction(CLOSE_LOADING));
      });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    variableWidth: true,
  };
  return (
    <>
      <Loading showLoading={isLoading} />

      <div className="c-listing-wrap">
        <div className="container spacing">
          <div className="content-section">
            <div className="heading">
              <h3>Categories</h3>
            </div>
            <div className="c-listing">
              <div className="inner">
                {list?.map((item, i) => {
                  return (
                    <div className="category-row">
                      <div className="heading">
                        <h5>{item.name}</h5>
                      </div>
                      <Slider
                        {...{
                          dots: true,
                          infinite: true,
                          speed: 500,
                          slidesToScroll: 1,
                          slidesToShow: item?.sub_category ? item?.sub_category?.length : 2,
                          autoplay: true,
                          variableWidth: true,
                        }}
                      >
                        {item?.sub_category?.length > 0 ? (
                          item?.sub_category?.map((sitem, index) => {
                            return (
                              <div className="item" key={index}>
                                <div className="item-inner">
                                  <Link
                                    to={`/products/${item?.slug}/${sitem?.slug}`}
                                  >
                                    <img src={sitem.image} alt={sitem.name} />
                                  </Link>
                                  <h6>{sitem.name}</h6>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div className="item">
                            <div className="item-inner">
                              <Link to={`/products/${item?.slug}`}>
                                <img src={item.image} alt={item.name} />
                              </Link>
                              <h5>{item.name}</h5>
                            </div>
                          </div>
                        )}
                      </Slider>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="adv-section" style={{ marginTop: 10 }}>
            <div className="adv-img">
              <img src={adv1} alt="" />
              <img src={adv2} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CategoryListing;
