import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom"

function YourStore() {
  const { user } = useSelector(state => state.authReducer)


  console.log('YourStore', user)
  return (
    <div className="payment-update-info">
      <div class="title">
        <h3>Your Store</h3>
      </div>
      <div className="ftd-fw-form store">
        <Link to="/vendorDashboard/account-management">
          <h4>Profile</h4>
        </Link>
        <ul>
          <li>
            <strong>Name </strong>
            <span>{user?.name}</span>
          </li>
          <li>
            <strong>Business Email</strong>
            <span>{user?.email}</span>
          </li>
          <li>
            <strong>Mobile</strong>
            <span>{user?.mobile}</span>
          </li>
          <li>
            <strong>Country</strong>
            <span>{user?.country?.name}</span>
          </li>
          <li>
            <strong>State</strong>
            <span>{user?.state?.name}</span>
          </li>
          <li>
            <strong>City</strong>
            <span>{user?.city}</span>
          </li>
        </ul>

        <Link to="/vendorDashboard/update-company">
          <h4>Company Info</h4>
        </Link>
        <ul>
          <li>
            <strong>Company Name</strong>
            <span>{user?.company_name}</span>
          </li>
          <li>
            <strong>Company Registered Name</strong>
            <span>{user?.complete_legal_name}</span>
          </li>
          <li>
            <strong>Company Website Url</strong>
            <span>{user?.company_url}</span>
          </li>
          <li>
            <strong>Company Address</strong>
            <span>{user?.company_address}</span>
          </li>
          <li>
            <strong>Country</strong>
            <span>{user?.country?.name}</span>
          </li>
          <li>
            <strong>Company Description</strong>
            <span>{user?.company_details}</span>
          </li>
        </ul>

        <Link to="/vendorDashboard/update-payment-Info">
          <h4>Payment Info</h4>
        </Link>
        <ul>
          <li>
            <strong>Bank Name</strong>
            <span>{user?.bank_name}</span>
          </li>
          <li>
            <strong>Bank Account Number</strong>
            <span>{user?.account_number}</span>
          </li>
          <li>
            <strong>Bank IFSC Code</strong>
            <span>{user?.ifsc_code}</span>
          </li>
          <li>
            <strong>Bank Account Holder Name</strong>
            <span>{user?.account_holder_name}</span>
          </li>
        </ul>

        <Link to="/vendorDashboard/selling-countries">
        <h4>Selling Countries</h4>
        </Link>
        <ul>
          <li>
            
            {user?.vendor_country ?
              user?.vendor_country?.map((item, i) => {
                return (
                  <span>{item?.name}, </span>
                )
              })
              :
              <span>All Countries</span>
            }



          </li>
        </ul>
      </div>
    </div>
  );
}

export default YourStore;
