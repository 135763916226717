import React from "react";
import "./OurBrands.scss";
import { Link } from "react-router-dom";
import Brand1 from "../../assets/img/brands1.png";
import Brand2 from "../../assets/img/brands2.png";
import Brand7 from "../../assets/img/brands3.png";
import Brand3 from "../../assets/img/brands4.png";
import Brand5 from "../../assets/img/brands5.png";
import Brand4 from "../../assets/img/brands6.png";
import Brand6 from "../../assets/img/brands1.png";
import Brand8 from "../../assets/img/brands8.png";
import Brand9 from "../../assets/img/brands9.png";
import valuable1 from "../../assets/img/valuable1.png";
import valuable2 from "../../assets/img/valuable2.png";
import valuable3 from "../../assets/img/valuable3.png";

function OurBrands() {
  const brands = [
    {
      id: 1,
      link: "/",
      image: Brand1,
      alt: "bands image",
    },
    {
      id: 2,
      link: "/",
      image: Brand2,
      alt: "bands image",
    },
    {
      id: 3,
      link: "/",
      image: Brand3,
      alt: "bands image",
    },
    {
      id: 4,
      link: "/",
      image: Brand4,
      alt: "bands image",
    },
    {
      id: 5,
      link: "/",
      image: Brand5,
      alt: "bands image",
    },
    {
      id: 6,
      link: "/",
      image: Brand6,
      alt: "bands image",
    },
    {
      id: 7,
      link: "/",
      image: Brand7,
      alt: "bands image",
    },
    {
      id: 8,
      link: "/",
      image: Brand8,
      alt: "bands image",
    },
    {
      id: 9,
      link: "/",
      image: Brand9,
      alt: "bands image",
    },
    {
      id: 10,
      link: "/",
      image: Brand1,
      alt: "bands image",
    },
    {
      id: 11,
      link: "/",
      image: Brand2,
      alt: "bands image",
    },
    {
      id: 12,
      link: "/",
      image: Brand3,
      alt: "bands image",
    },
  ];

  const Valuable = [
    {
      id: 1,
      link: "/",
      image: valuable1,
      alt: "image",
    },
    {
      id: 2,
      link: "/",
      image: valuable2,
      alt: "image",
    },
    {
      id: 3,
      link: "/",
      image: valuable3,
      alt: "image",
    },
  ];

  return (
    <section className="our_brands">
      <div className="container">
        <h3>Our Brands</h3>
        <div className="brand_section">
          {brands.map((item, index) => (
            <div className="item">
              <Link to={item.link}>
                <img src={item.image} alt={item.alt} />
              </Link>
            </div>
          ))}
        </div>

        <div className="valuabale">
          <h3>Most Valuable Products</h3>
          {Valuable.map((item, index) => (
            <div className="item">
              <Link to={item.link}>
                <img src={item.image} alt={item.alt} />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default OurBrands;
