import axios from "./axios";

export const setSession = (access_token) => {
  if (access_token) {
    localStorage.setItem("duAfirk_token", access_token);
  } else {
    localStorage.removeItem("duAfirk_token");
    delete axios.defaults.headers.common.Authorization;
  }
};
