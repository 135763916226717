import React from 'react';
import '../Footer/Footer.scss';

function Footer(props) {
    const { content } = props
    return (
        <div className="dashboard-footer">
            <div className="dashboard-footer-inner">
                <p>
                 {content}
                </p>
            </div>
        </div>
    )
}

export default Footer
