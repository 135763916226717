import React, { useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import NotFound from "../components/Not-found/NotFound";
import Home from "../pages/Home/Home";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Login from "../pages/Login/Login";
import Signup from "../pages/Signup/Signup";
import EmailVerify from "../pages/Email-Verify/EmailVerify";
import Dashboard from "../pages/Dashboard/UserDashboard";
import VenderDashboard from "../pages/VendorDashboard/Dashboard/Dashboard";
import ForgetPassword from "../pages/ForgetPassword/ForgetPassword";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  GET_BRANDS_LIST,
  GET_CATEGORY_LIST,
  GET_OFFER_LIST,
  globalAction,
} from "../redux";
import Categories from "../pages/CategoryPage/Categories";
import OurBrands from "../pages/OurBrands/OurBrands";
import BlogListing from "../pages/Blog/BlogListing";
import BlogPost from "../pages/Blog/BlogPost";
import ShoppingCart from "../pages/ShoppingCart/ShoppingCart";
import ProductDetailPage from "../pages/ProductDetailPage/ProductDetailPage";
import SelectAddress from "../pages/Address/SelectAddress";
import OrderSummary from "../pages/OrderSummary/OrderSummary";
import OrderSuccess from "../pages/OrderSummary/OrderSuccess";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import AboutUs from "../pages/StaticPages/AboutUs";
import Contactus from "../pages/ContactUs/Contactus";
import FillAddress from "../pages/Address/FillAddress";
import VendorStore from "../pages/VendorStore/VendorStore";
import VendorListing from "../pages/VendorListing/VendorListing";
import GetNotification from "../pages/VendorDashboard/Dashboard/GetNotification";
import BecomePartner from "../pages/become-partner/Become-partner";
import HowItWork from "../pages/how-to-use/how-to-use";
import Faqs from "../pages/Faqs/Become-partner";
import { Button, Form } from "react-bootstrap";
import CategoryListing from "../pages/CategoryListing/CategoryListing";

const PublicRoute = ({
  component: Component,
  isLogin,
  restricted,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin && props.location.pathname === "/login" ? (
          <Redirect to="/" />
        ) : (
          <>
            {props.location.pathname !== "/login" &&
              localStorage.setItem("url", props.location.pathname)}
            <Component {...props} />
          </>
        )
      }
    />
  );
};

const PrivateRoute = ({ component: Component, isLogin, ...rest }) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page

    <Route
      {...rest}
      render={(props) =>
        isLogin ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

const PublicLayout = (props) => {
  return (
    <>
      <Header />
      <Switch>
        <PublicRoute isLogin={false} component={Home} exact path="/" />
        <PublicRoute isLogin={false} component={Home} exact path="/home" />
        <PublicRoute
          isLogin={false}
          component={CategoryListing}
          exact
          path="/all-categories"
        />
        <PublicRoute isLogin={false} component={Login} exact path="/login" />
        <PublicRoute isLogin={false} component={Signup} exact path="/sign-up" />
        <PublicRoute
          isLogin={false}
          component={EmailVerify}
          exact
          path="/verify-account"
        />
        <PublicRoute
          isLogin={false}
          component={ForgetPassword}
          exact
          path="/forget-password"
        />
        <PublicRoute
          isLogin={false}
          component={Categories}
          exact
          path="/products"
        />
        <PublicRoute
          isLogin={false}
          component={Categories}
          exact
          path="/products/:catSlug"
        />
        <PublicRoute
          isLogin={false}
          component={Categories}
          exact
          path="/products/:catSlug/:subCatSlug"
        />
        <PublicRoute
          isLogin={false}
          component={ProductDetailPage}
          exact
          path="/product-detail/:Id"
        />
        <PublicRoute
          isLogin={false}
          component={OurBrands}
          exact
          path="/our-brands"
        />
        <PublicRoute
          isLogin={false}
          component={BlogListing}
          exact
          path="/blog"
        />
        <PublicRoute
          isLogin={false}
          component={BlogListing}
          exact
          path="/blog/:slug"
        />
        <PublicRoute
          isLogin={false}
          component={BlogPost}
          exact
          path="/blog-post/:id"
        />
        <PublicRoute
          isLogin={false}
          component={ShoppingCart}
          exact
          path="/shopping-cart"
        />
        <PublicRoute
          isLogin={false}
          component={OrderSummary}
          exact
          path="/order-summary"
        />
        <PublicRoute
          isLogin={false}
          component={OrderSummary}
          exact
          path="/order-summary-as-guest"
        />
        <PublicRoute
          isLogin={false}
          component={SelectAddress}
          exact
          path="/select-Address"
        />
        <PublicRoute
          isLogin={false}
          component={OrderSuccess}
          exact
          path="/order-complete"
        />
        <PublicRoute
          isLogin={false}
          component={AboutUs}
          exact
          path="/about-us"
        />
        <PublicRoute
          isLogin={false}
          component={AboutUs}
          exact
          path="/privacy-policy"
        />
        <PublicRoute
          isLogin={false}
          component={AboutUs}
          exact
          path="/seller-policy"
        />
        <PublicRoute
          isLogin={false}
          component={AboutUs}
          exact
          path="/delivery-policy"
        />
        <PublicRoute
          isLogin={false}
          component={AboutUs}
          exact
          path="/Terms-and-Condtions"
        />
        <PublicRoute
          isLogin={false}
          component={AboutUs}
          exact
          path="/affiliate"
        />
        <PublicRoute
          isLogin={false}
          component={AboutUs}
          exact
          path="/vendor-connect"
        />
        <PublicRoute
          isLogin={false}
          component={AboutUs}
          exact
          path="/wallet-terms-and-conditions"
        />
        <PublicRoute
          isLogin={false}
          component={AboutUs}
          exact
          path="/in-news"
        />
        <PublicRoute
          isLogin={false}
          component={Contactus}
          exact
          path="/contact-us"
        />
        <PublicRoute
          isLogin={false}
          component={FillAddress}
          exact
          path="/fill-address"
        />
        <PublicRoute
          isLogin={false}
          component={VendorStore}
          exact
          path="/vendor-store/:id"
        />
        <PublicRoute
          isLogin={false}
          component={VendorListing}
          exact
          path="/vendor-listing/:id"
        />
        <PublicRoute
          isLogin={false}
          component={BecomePartner}
          exact
          path="/sell-with-us"
        />
        <PublicRoute
          isLogin={false}
          component={HowItWork}
          exact
          path="/how-it-works"
        />
        <PublicRoute isLogin={false} component={Faqs} exact path="/faqs" />
        <Route component={NotFound} />
      </Switch>
      <Footer />
    </>
  );
};

const ProtectedLayout = () => {
  const { isLogin, user } = useSelector((state) => state.authReducer);
  return (
    <>
      <Switch>
        <PrivateRoute
          isLogin={isLogin}
          component={VenderDashboard}
          path="/Dashboard"
        />
        <PrivateRoute
          user={isLogin}
          isLogin={true}
          component={VenderDashboard}
          path="/vendorDashboard"
        />
        <Route component={NotFound} />
      </Switch>
    </>
  );
};

//  Main routes
function Router(props) {
  const [fromData, setFromData] = useState({});
  const [fromFlag, setFromFlag] = useState(false);
  const dispatch = useDispatch();
  const { brandsList, categoryList, offerList } = useSelector(
    (state) => state.appReducer
  );
  useEffect(() => {
    window.scroll({ top: 0 });
    if (categoryList.length === 0) {
      dispatch(globalAction(GET_CATEGORY_LIST)); // get categories list
    }
    if (brandsList.length === 0) {
      dispatch(globalAction(GET_BRANDS_LIST)); // get brands list
    }
    if (offerList.length === 0) {
      dispatch(globalAction(GET_OFFER_LIST)); // get brands list
    }
  }, []);

  const changeText = (fieldValue, fieldName) => {
    setFromData({ ...fromData, [fieldName]: fieldValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <PayPalScriptProvider
        options={{
          "client-id":
            "AX7uOce6SUelGmtZ8LOO6Vi3DsTY13YZzLkjTypkfcw96qXgsbWL8i5mwcGw07ybVGl68o6NEKyBQWxs",
        }}
      >
        <BrowserRouter>
          <Switch>
            <Route path="/vendorDashboard" component={ProtectedLayout} />
            <Route path="/Dashboard" component={ProtectedLayout} />
            <Route path="/" component={PublicLayout} />
          </Switch>
        </BrowserRouter>
      </PayPalScriptProvider>
      {/* <div className="support-wrap">
                <button
                    className="btn btn-black"
                    onClick={()=>setFromFlag(true)}>
                    Support
                </button>
            </div>
            <div className={`support-form ${fromFlag ? '-open' : '-close'}`}>
                <div className="from">
                    <button
                        className="btn-black close-btn"
                        onClick={()=>setFromFlag(false)}
                    >
                        Close
                    </button>
                    <Form onSubmit={(e) => { handleSubmit(e) }}>
                        <Form.Group className="mb-1" controlId="formBasicEmail">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                                required
                                type="email"
                                placeholder="Enter email"
                                className="input"
                                onChange={(e) => { changeText(e.target.value, 'email') }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Message</Form.Label>
                            <Form.Control
                                required
                                type="email"
                                placeholder="Enter email"
                                className="input"
                                onChange={(e) => { changeText(e.target.value, 'message') }}
                            />
                        </Form.Group>

                        <Form.Group className="mb-4 product-image" controlId="formBasicPassword">
                            <Form.Label>Add Image</Form.Label>
                            <Form.Label className="forget-password"></Form.Label>
                            <Form.Control
                                accept="image/*"
                                type="file"

                                onChange={(e) => { changeText(e.target.files, 'photo') }} />
                        </Form.Group>

                        <Button
                            variant="primary"
                            type="submit"
                            className="btn-brown"
                        >
                            Submit
                        </Button>
                    </Form>

                </div>
            </div> */}
    </>
  );
}

export default Router;
