import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import "./Wallet.scss";
import Img from "../../../../assets/img/whiteBg.png";
import {
  GET_WITHDRAW_REQUEST_LIST,
  globalAction,
  SHOW_LOADING,
} from "../../../../redux";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import moment from "moment";

function WalletTransaction() {
  const dispatch = useDispatch();
  const { withdrawList } = useSelector((state) => state.appReducer);
  const { user } = useSelector((state) => state.authReducer);
  const [status, setStatus] = useState(1);

  useEffect(() => {
    window.scroll({ top: 0 });
    if (user) {
      let params = `?vendor_id=${user?.id}&status=${status}`;
      dispatch(globalAction(SHOW_LOADING));
      dispatch(globalAction(GET_WITHDRAW_REQUEST_LIST, params));
    }
  }, []);

  const handlePageClick = (data) => {
    document.getElementById("wallet box-size").scrollIntoView(true);
    let params = `?vendor_id=${user?.id}&page=${
      data.selected + 1
    }&status=${status}`;
    dispatch(globalAction(SHOW_LOADING));
    dispatch(globalAction(GET_WITHDRAW_REQUEST_LIST, params));
  };

  const handleTabChange = (value) => {
    setStatus(value);
    if (user) {
      let params = `?vendor_id=${user?.id}&status=${value}`;
      console.log(params);
      dispatch(globalAction(SHOW_LOADING));
      dispatch(globalAction(GET_WITHDRAW_REQUEST_LIST, params));
    }
  };

  return (
    <div className="vd-dashboard-wrap order-management">
      <div className="title">
        <h3>Your Transactions</h3>
      </div>
      <div className="wallet box-size" id="wallet box-size">
        <div className="transation">
          <Tabs
            defaultActiveKey="1"
            id="uncontrolled-tab-example"
            className="mb-3"
            onSelect={(value) => handleTabChange(value)}
          >
            <Tab eventKey="1" title="All Transactions">
              <div className="inner_content">
                <ul>
                  {withdrawList?.data?.map((item, i) => {
                    const { id, created_at, amount, status, reject_reason } =
                      item;
                    console.log(item);
                    return (
                      <li key={created_at} className={`status-${status}`}>
                        <div className="image">
                          <img src={Img} alt="" />
                        </div>
                        <div className="details">
                          <h5>Paid on Du Afrik</h5>
                          <span>
                            {" "}
                            {moment(created_at).format("DD MMM YYYY hh:mm A")}
                          </span>
                          {status === 1 && (
                            <span className="status-1">Pending</span>
                          )}
                          {status === 2 && (
                            <span className="status-1 Approved">Approved</span>
                          )}
                          {status === 3 && (
                            <span className="status-1 Rejected">Rejected</span>
                          )}
                          {reject_reason !== null && (
                            <p className="reason">
                              <strong>Reason: </strong>
                              {reject_reason}
                            </p>
                          )}
                        </div>
                        <div className="amount">
                          <h5>${Number(amount).toFixed(2)}</h5>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {withdrawList && withdrawList?.data?.length > 0 ? (
                <div className="pcw-pagination">
                  <div className="pcw-pagination-inner">
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(
                        withdrawList?.total / withdrawList?.per_page
                      )}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                      forcePage={Number(withdrawList?.current_page - 1)}
                    />
                  </div>
                </div>
              ) : (
                <div className="not-product">
                  <h4>No Transaction Found...!</h4>
                </div>
              )}
            </Tab>
            <Tab
              eventKey="2"
              title="Approved"
              onClick={() => {
                console.log("yess");
              }}
            >
              <div className="inner_content">
                <ul>
                  {withdrawList?.data?.map((item, i) => {
                    const { id, created_at, amount, status, reject_reason } =
                      item;
                    return (
                      <li key={created_at} className={`status-${status}`}>
                        {/* <Link to="#"></a> */}
                        <div className="image">
                          <img src={Img} alt="" />
                        </div>
                        <div className="details">
                          <h5>Paid on Du Afrik</h5>
                          {/* <span>Amazon Pay Credit card, Amazon Pay balance</span> */}
                          <span>
                            {" "}
                            {moment(created_at).format("DD MMM YYYY hh:mm A")}
                          </span>
                          {status === 1 && (
                            <span className="status-1">Pending</span>
                          )}
                          {status === 2 && (
                            <span className="status-1 Approved">Approved</span>
                          )}
                          {status === 3 && (
                            <span className="status-1 Rejected">Rejected</span>
                          )}
                          {reject_reason !== null && (
                            <p className="reason">
                              <strong>Reason: </strong>
                              {reject_reason}
                            </p>
                          )}
                        </div>
                        <div className="amount">
                          <h5>${Number(amount).toFixed(2)}</h5>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {withdrawList && withdrawList?.data?.length > 0 ? (
                <div className="pcw-pagination">
                  <div className="pcw-pagination-inner">
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(
                        withdrawList?.total / withdrawList?.per_page
                      )}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                      forcePage={Number(withdrawList?.current_page - 1)}
                    />
                  </div>
                </div>
              ) : (
                <div className="not-product">
                  <h4>No Transaction Found...!</h4>
                </div>
              )}
            </Tab>
            <Tab eventKey="3" title="Rejected">
              <div className="inner_content">
                <ul>
                  {withdrawList?.data?.map((item, i) => {
                    const { id, created_at, amount, status, reject_reason } =
                      item;
                    console.log(item);
                    return (
                      <li key={created_at} className={`status-${status}`}>
                        {/* <Link to="#"></a> */}
                        <div className="image">
                          <img src={Img} alt="" />
                        </div>
                        <div className="details">
                          <h5>Paid on Du Afrik</h5>
                          {/* <span>Amazon Pay Credit card, Amazon Pay balance</span> */}
                          <span>
                            {" "}
                            {moment(created_at).format("DD MMM YYYY hh:mm A")}
                          </span>
                          {status === 1 && (
                            <span className="status-1">Pending</span>
                          )}
                          {status === 2 && (
                            <span className="status-1 Approved">Approved</span>
                          )}
                          {status === 3 && (
                            <span className="status-1 Rejected">Rejected</span>
                          )}
                          {reject_reason !== null && (
                            <p className="reason">
                              <strong>Reason: </strong>
                              {reject_reason}
                            </p>
                          )}
                        </div>
                        <div className="amount">
                          <h5>${Number(amount).toFixed(2)}</h5>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {withdrawList && withdrawList?.data?.length > 0 ? (
                <div className="pcw-pagination">
                  <div className="pcw-pagination-inner">
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(
                        withdrawList?.total / withdrawList?.per_page
                      )}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                      forcePage={Number(withdrawList?.current_page - 1)}
                    />
                  </div>
                </div>
              ) : (
                <div className="not-product">
                  <h4>No Transaction Found...!</h4>
                </div>
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default WalletTransaction;
