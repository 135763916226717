import React, { useEffect, useState } from "react";
import Autocomplete from "react-autocomplete";
import { Button } from "react-bootstrap";
import { TiDeleteOutline } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { getVendorDashboardDetailsApi } from "../../../api";
import {
  CLOSE_LOADING,
  globalAction,
  SHOW_LOADING,
  UPDATE_COUNTRIES,
} from "../../../redux";
import AllCountry from "../../../Utilis/allCountry.json";

function SellingCountries() {
  const [selectedCountry, setselectedCountry] = useState("");
  const [selectedCountryArr, setselectedCountryArr] = useState([]);
  const [cmReRender, setCmReRender] = useState(false);
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);
  const [dashboardData, setDashboardData] = useState(undefined);

  const handleSubmit = (e, formType) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (form.checkValidity() === false) {
      e.stopPropagation();
      alert("Please Filled all fields");
    } else {
      let tempData = [];
      selectedCountryArr.forEach((ele) => {
        let tempItem = AllCountry.find((item, i) => item.name === ele);
        tempData.push(tempItem.id);
      });
      let finalData = { user_id: user.id, countries: tempData.toString() };
      dispatch(globalAction(SHOW_LOADING));
      // console.log(finalData)
      dispatch(globalAction(UPDATE_COUNTRIES, finalData));
    }
  };
  useEffect(() => {
    if (user?.id) {
      dispatch(globalAction(SHOW_LOADING));
      getVendorDashboardDetailsApi({ vendor_id: user?.id })
        .then((res) => {
          dispatch(globalAction(CLOSE_LOADING));
          const { status, data } = res;
          if (status === 200) {
            let sellingCountries = [];
            user?.vendor_country?.forEach((ele, i) => {
              sellingCountries.push(ele.name);
            });
            setselectedCountryArr(sellingCountries);
            setDashboardData(data);
          }
        })
        .catch((err) => {
          alert("something went wrong. Please try again");
        });
    }
  }, [user]);

  console.log("dashboardData,", dashboardData);

  return (
    <div className="update-selling-countries">
      <div class="title">
        <h3>Edit Selling Countries</h3>
      </div>
      <div className="ftd-fw-form vendor-selling-countries">
        <div className="ftd-fw-inner">
          <div className="ftd-auto-select">
            <label>Search & Select Countries</label>
            <Autocomplete
              placeholder="select selling countries"
              className="input"
              getItemValue={(item) => item.name}
              items={AllCountry}
              shouldItemRender={(item, value) =>
                item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
              }
              renderItem={(item, isHighlighted) => (
                <div
                  className="input"
                  style={{ background: isHighlighted ? "lightgray" : "white" }}
                >
                  {item.name}
                </div>
              )}
              value={selectedCountry}
              onChange={(e) => setselectedCountry(e.target.value)}
              onSelect={(val) => {
                selectedCountryArr.push(val);
                let removeDuplicateCountries = [...new Set(selectedCountryArr)];
                if (
                  removeDuplicateCountries.find(
                    (item) => item === "All Countries"
                  )
                ) {
                  removeDuplicateCountries = ["All Countries"];
                }
                // setselectedCountry(val);
                setselectedCountryArr(removeDuplicateCountries);
              }}
            />
          </div>
          {selectedCountryArr.length > 0 && (
            <>
              <div className="ftd-selected-countries-wrap">
                <div className="ftd-selected-country-wrap">
                  <h4>Selling Countries</h4>
                </div>
                <div className="ftd-selected-wrap">
                  {selectedCountryArr.length > 0 &&
                    selectedCountryArr.map((item, index) => {
                      return (
                        <div key={item} className="ftd_selected_country">
                          {item}
                          <span
                            onClick={() => {
                              selectedCountryArr.splice(index, 1);
                              setselectedCountryArr(selectedCountryArr);
                              setCmReRender(!cmReRender);
                            }}
                          >
                            <TiDeleteOutline />
                          </span>
                        </div>
                      );
                    })}
                </div>
              </div>
              <Button
                variant="primary"
                type="submit"
                className="btn-brown"
                onClick={(e) => {
                  handleSubmit(e, "sellingCountries");
                }}
              >
                Update
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default SellingCountries;
