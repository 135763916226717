//Api_Constants.js
// let dev = 'http://127.0.0.1:8000/';
// let live = "http://127.0.0.1:8000/";

let Apis = {
  //Main Urls
  // STRIPE_KEY,
  mid: "api/",

  //Apis
  registration: "registration",
  accountVerify: "signup_verify",
  resend_email_verify: "resend_email_verify",
  login: "login",
  forgot_password: "forgot_password",
  reset_password: "reset_password",

  // update Api constant
  profile_company: "profile_company",
  profile_kyc: "profile_kyc",
  profile_payment: "profile_payment",
  update_countries: "update_countries",
  update_profile: "update_profile",
  change_password: "change_password",

  state: "state",
  category_list: "category_list?limit=300",
  subcategory: "subcategory",
  brand_list: "brand_list",
  offers_list: "offers_list",

  add_product: "add_product",
  product_list: "product_list",
  product_details: "product_details",
  update_product: "update_product",
  attribute: "attribute",
  add_product_variant: "add_product_variant",
  product_variants: "product_variants",
  delete_product_variant: "delete_product_variant",
  update_product_variant: "update_product_variant",
  product_details: "product_details",

  advertisement: "advertisement",
  product_listing: "product_listing",

  // Blog
  blog: "blog",
  blog_category: "blog_category",
  blog_details: "blog_details",

  // Address
  add_address: "add_address",
  address_list: "address_list",
  delete_address: "delete_address",
  update_address: "update_address",
  add_order: "add_order",
  orders: "orders",
  order_details: "order_details",
  order_cancel: "order_cancel",
  ordersbyVedorID: "ordersbyVedorID",
  cancel_order_product: "cancel_order_product",
  update_order_status: "update_order_status",
  update_order_shipping: "update_order_shipping",
  order_feedback_list: "order_feedback_list",

  // static pages
  privacy_policy: "privacy_policy",
  terms_condition: "terms_condition",
  affiliate: "affiliate",
  wallet_tc: "wallet_tc",
  vendor_connect: "vendor_connect",
  in_news: "in_news",
  about_us: "about_us",
  contact_us: "contact_us",
  seller_contact_us: "seller_contact_us",

  add_save_product: "add_save_product",
  save_product_list: "save_product_list",
  delete_save_product: "delete_save_product",
  payment_setting: "payment_setting",

  list_withdraw_request: "list_withdraw_request",
  add_withdraw_request: "add_withdraw_request",

  user_dashboard: "user_dashboard",
  user_vendor: "user_vendor",
  home: "home",
  vendor_details: "vendor_details",
  vendor_listing: "vendor_listing",

  add_review: "add_review",
  order_feedback: "order_feedback",
  subscribe: "subscribe",
  vendor_notification_list: "vendor_notification_list",
  clearAllNotifications: "clearAllNotifications",
  markAsReadNotifcation: "markAsReadNotifcation",
  footer: "footer",
  add_to_cart: "add_to_cart",
  tax_list: "tax_list",
  header: "header",
};
export default Apis;
