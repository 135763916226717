/** @format */

import React, { useState, useRef, useEffect } from "react";
import Autocomplete from "react-autocomplete";
import { Form, Row, Button, Col } from "react-bootstrap";
import "./AddProduct.scss";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link,
} from "suneditor/src/plugins"; // Import Sun Editor's CSS File
import ImgPreview from "../../../../../assets/upload_image_sample.png";
import { useSelector, useDispatch } from "react-redux";
import { getSubCategoryListApi, taxApiListApi } from "../../../../../api";
import {
  ADD_NEW_PRODUCT_BY_VENDOR,
  GET_PRODUCT_DETAILS_BY_ID,
  GET_PRODUCT_VARIANT_LIST_BY_PRODUCT_ID,
  globalAction,
  SET_PRODUCT_VARIANT_LIST_BY_PRODUCT_ID,
  SHOW_LOADING,
  UPDATE_PRODUCT_BY_VENDOR,
} from "../../../../../redux";
import { TiDelete } from "react-icons/ti";
import AddProductVariant from "../AddProductVariant/AddProductVariant";
import ViewAllVariantOfProduct from "../ViewAllVariantOfProduct/ViewAllVariantOfProduct";

function AddnewProduct(props) {
  const {
    brandsList,
    categoryList,
    offerList,
    productDetails,
    productVariantList,
  } = useSelector((state) => state.appReducer);
  const { user, message, visibleMessage } = useSelector(
    (state) => state.authReducer
  );
  const dispatch = useDispatch();
  const [category, setCategory] = useState("");
  const [productData, setProductData] = useState({
    user_id: user?.id,
    stock_availability: "in_stock",
    gallery_images: [],
    galleryImgPreview: [],
    description: "",
    additional_information: "",
    subCategoryName: "",
    warranty: "no",
    refundable: "nos",
  });
  const [subCategory, setsubCategory] = useState([]);
  const [decription, setDecription] = useState("");
  const [AdditionalInfo, setAdditionalInfo] = useState("");
  const [cmRender, setCmRender] = useState(false);
  const [AddVarientFlag, setAddVarientFlag] = useState(false);
  const [AddVarientOnExistingFlag, setAddVarientOnExistingFlag] =
    useState(false);
  const [modalFlag, setModalFlag] = useState(false);
  const [taxList, settaxList] = useState(undefined);
  const myformRef = useRef();

  const {
    match: { params },
  } = props;
  let urlHasSlug = params.slug;

  /** Its takes country id as a argus. return state data*/
  const getSubCategoryByCategoryId = async (id) => {
    await getSubCategoryListApi(id).then(async (res) => {
      const { data, status } = res;
      if (status === 200) {
        await setsubCategory(data.subcategory.data);
      }
    });
  };

  const getTaxlist = function () {
    taxApiListApi()
      .then((result) => {
        const { data, status } = result;
        settaxList(data.tax);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeText = (value, fieldName) => {
    setProductData({ ...productData, [fieldName]: value });
  };

  const selectHandle = (value, fieldName, relatedField, itemArr) => {
    let tempItem = itemArr.find((item, i) => item.name === value);
    setProductData({ ...productData, [fieldName]: tempItem.id });
    if (fieldName === "category_id") {
      getSubCategoryByCategoryId(tempItem.id);
      delete productData["subCategoryName"];
      delete productData["subcategory_id"];
    }
    if (relatedField) {
      setProductData({
        ...productData,
        [fieldName]: tempItem.id,
        [relatedField]: value,
      });
    }
  };
  const selectOfferHandle = (value, fieldName, relatedField, itemArr) => {
    let tempItem = itemArr.find((item, i) => item.offer_name === value);
    setProductData({ ...productData, [fieldName]: tempItem.id });
    if (relatedField) {
      setProductData({
        ...productData,
        [fieldName]: tempItem.id,
        [relatedField]: value,
      });
    }
  };

  const onSelectImage = async (value, fieldName) => {
    let allfile = await value.target.files;
    let gallery_images = productData.gallery_images;
    let galleryImgPreview = productData.galleryImgPreview;
    // let reader =  new FileReader();
    if (allfile) {
      Object.keys(allfile).forEach((i) => {
        const file = allfile[i];
        const reader = new FileReader();
        reader.onload = (e) => {
          if (fieldName === "photo") {
            setProductData({
              ...productData,
              [fieldName]: file,
              [fieldName + "Preview"]: reader.result,
            });
          }
          gallery_images.push(file);
          galleryImgPreview.push(reader.result);
        };
        reader.readAsDataURL(file);
        reader.onloadend = (e) => {
          if (fieldName === "galleryImg" && i < 5) {
            setProductData({
              ...productData,
              ["gallery_images"]: gallery_images,
              ["galleryImgPreview"]: galleryImgPreview,
            });
          }
        };
      });
    }
  };

  const onSubmitHandle = (e) => {
    e.preventDefault();
    let finalObj;
    if (!urlHasSlug) {
      finalObj = {
        ...productData,
        description: decription,
        additional_information: AdditionalInfo,
      };
      delete finalObj["galleryImgPreview"];
      delete finalObj["photoPreview"];

      if (!AddVarientFlag) {
        dispatch(globalAction(SHOW_LOADING));
        dispatch(globalAction(ADD_NEW_PRODUCT_BY_VENDOR, finalObj));
      }
      myformRef.current[0].value = "";
      myformRef.current[1].value = "";
      myformRef.current[2].value = "";
      myformRef.current[3].value = "";
      myformRef.current[4].value = "";
      myformRef.current[5].value = "";
      myformRef.current[6].value = "";
      myformRef.current[7].value = "";
      myformRef.current[8].value = "";
      myformRef.current[9].value = "";
      myformRef.current[10].value = "";
      myformRef.current[11].value = "";
      myformRef.current[12].value = "";
      myformRef.current[13].value = "";
    } else {
      //  For update product

      finalObj = {
        ...productData,
        description: productDetails?.description,
        additional_information: productDetails?.additional_information,
      };
      delete finalObj["image_url"];
      dispatch(globalAction(SHOW_LOADING));
      dispatch(globalAction(UPDATE_PRODUCT_BY_VENDOR, finalObj));
    }
  };

  useEffect(() => {
    getTaxlist();
    if (urlHasSlug) {
      let tempObj = { id: urlHasSlug };
      dispatch(globalAction(SHOW_LOADING));
      dispatch(globalAction(GET_PRODUCT_DETAILS_BY_ID, tempObj));
      dispatch(
        globalAction(GET_PRODUCT_VARIANT_LIST_BY_PRODUCT_ID, urlHasSlug)
      );
      setAddVarientOnExistingFlag(true);
    } else {
      dispatch(globalAction(SET_PRODUCT_VARIANT_LIST_BY_PRODUCT_ID, []));
      setProductData({
        user_id: user?.id,
        stock_availability: "in_stock",
        gallery_images: [],
        galleryImgPreview: [],
        description: "",
        additional_information: "",
      });
    }
  }, []);

  useEffect(() => {
    if (productDetails && urlHasSlug) {
      let tempObj = {
        ...productDetails,
        gallery_images: [],
        galleryImgPreview: productDetails.product_images,
      };
      setProductData(tempObj);
    } else {
      setAddVarientFlag(false);
    }

    if (productVariantList.length > 0) {
      setAddVarientFlag(true);
    }
    return () => {
      localStorage.removeItem("rpId");
    };
  }, [productDetails, productVariantList]);

  useEffect(() => {
    if (message === "Product added successfully") {
      setProductData({
        user_id: user?.id,
        stock_availability: "in_stock",
        gallery_images: [],
        galleryImgPreview: [],
      });
      myformRef.current.reset();
    }
  }, [visibleMessage, message]);

  const handleGalleryImages = function (itemIndex, galleryArray) {
    productData.galleryImgPreview.splice(itemIndex, 1);
    setProductData({ ...productData });
  };
  console.log(productData, "productData");
  return (
    <div className="vd-dashboard-wrap addnew-product">
      <div className="title">
        <h3>{urlHasSlug ? "Update Product" : "Create New Product"}</h3>
      </div>
      <div className="vd-dashboard-body box-size">
        <Form ref={myformRef} onSubmit={(e) => onSubmitHandle(e)}>
          <Row className="mb-4">
            <Form.Group as={Col} controlId="Product Name">
              <Form.Label>
                Product Name <span className="requered">*</span>
              </Form.Label>
              <Form.Control
                className="input"
                type="text"
                placeholder="Enter Product Name"
                defaultValue={productData?.title}
                required
                onChange={(e) => changeText(e.target.value, "title")}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="Product Code">
              <div className="new-product-auto-select cm-autocomplete brand">
                <Form.Label>Brand Name</Form.Label>
                <Autocomplete
                  className="input"
                  items={brandsList}
                  shouldItemRender={(item, value) =>
                    item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
                  }
                  getItemValue={(item) => item.name}
                  renderItem={(item, isHighlighted) => (
                    <div
                      className="input"
                      style={{
                        zIndex: "10!important",
                        background: isHighlighted ? "lightgray" : "white",
                      }}
                    >
                      {item.name}
                    </div>
                  )}
                  value={productData?.brandName || productData?.brand_name}
                  onChange={(e, value) => changeText(value, "brandName")}
                  onSelect={(val) => {
                    selectHandle(val, "brand", "brandName", brandsList);
                  }}
                />
              </div>
            </Form.Group>
          </Row>

          <Row className="mb-4">
            <Form.Group as={Col} controlId="category">
              <Form.Label>
                Category <span className="requered">*</span>
              </Form.Label>
              <div className="new-product-auto-select cm-autocomplete">
                <Autocomplete
                  className="input"
                  getItemValue={(item) => item.name}
                  items={categoryList}
                  shouldItemRender={(item, value) =>
                    item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
                  }
                  renderItem={(item, isHighlighted) => (
                    <div
                      className="input"
                      style={{
                        zIndex: "999!important",
                        opacity: "1",
                        position: "relative",
                        background: isHighlighted ? "lightgray" : "white",
                      }}
                    >
                      {item.name}
                    </div>
                  )}
                  value={productData?.categoryName || productData.category_name}
                  onChange={(e, value) => changeText(value, "categoryName")}
                  onSelect={(val) => {
                    selectHandle(
                      val,
                      "category_id",
                      "categoryName",
                      categoryList
                    );
                  }}
                />
              </div>
            </Form.Group>
            {/* {subCategory.length > 0 && ( */}
            <Form.Group as={Col} controlId="subCategory">
              <div className="new-product-auto-select cm-autocomplete">
                <Form.Label>Sub Category</Form.Label>
                <Autocomplete
                  className="input"
                  getItemValue={(item) => item.name}
                  items={subCategory}
                  shouldItemRender={(item, value) =>
                    item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
                  }
                  renderItem={(item, isHighlighted) => (
                    <div
                      className="input"
                      style={{
                        zIndex: "10!important",
                        background: isHighlighted ? "lightgray" : "white",
                      }}
                    >
                      {item.name}
                    </div>
                  )}
                  value={
                    productData?.subCategoryName ||
                    productData?.sub_category_name
                  }
                  onChange={(e, value) => changeText(value, "subCategoryName")}
                  onSelect={(val) => {
                    selectHandle(
                      val,
                      "subcategory_id",
                      "subCategoryName",
                      subCategory
                    );
                  }}
                />
              </div>
            </Form.Group>
            {/* )} */}
          </Row>

          <Row className="mb-4">
            <Form.Group as={Col} controlId="Product Code">
              <Form.Label>
                Product Code <span className="requered">*</span>
              </Form.Label>
              <Form.Control
                className="input"
                type="text"
                placeholder="Enter Product Code"
                defaultValue={productData?.model_no}
                required
                onChange={(e) => changeText(e.target.value, "model_no")}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="Product SKU">
              <Form.Label>
                Product SKU <span className="requered">*</span>
              </Form.Label>
              <Form.Control
                className="input"
                type="text"
                placeholder="Enter Product SKU"
                required
                defaultValue={productData?.sku}
                onChange={(e) => changeText(e.target.value, "sku")}
              />
            </Form.Group>
          </Row>

          <Row className="mb-4">
            <Form.Group as={Col} controlId="Product tags">
              <Form.Label>
                Product Tags <span className="requered">*</span>
              </Form.Label>
              <Form.Control
                className="input"
                type="text"
                placeholder="Enter Product tags by comma"
                required
                defaultValue={productData?.tags}
                onChange={(e) => changeText(e.target.value, "tags")}
              />
            </Form.Group>
          </Row>

          <Row className="mb-4">
            <Form.Group as={Col} controlId="product_weight">
              <Form.Label>
                Weight{" "}
                <span className="helptext">(Product default weight)</span>
              </Form.Label>
              <Form.Control
                className="input"
                type="text"
                placeholder="Enter product weight"
                defaultValue={productData?.weight}
                onChange={(e) => changeText(e.target.value, "weight")}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="product_tax">
              <Form.Label>
                Tax <span className="requered">*</span>
              </Form.Label>

              <Form.Select
                required
                aria-label="Default tax"
                onChange={(e) => changeText(e.target.value, "tax")}
                value={productData?.tax}
              >
                <option>Select Tax</option>
                {taxList?.map((item, i) => {
                  return (
                    <option value={item.tax}>
                      {item.name}
                      {`(${item.tax}%)`}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Row>

          <Row className="mb-4">
            <Form.Group as={Col} controlId="regularPrice">
              <Form.Label>
                Regular Price <span className="requered">*</span>
              </Form.Label>
              <Form.Control
                className="input"
                type="text"
                placeholder="Enter regular price"
                defaultValue={productData?.regular_price}
                required
                onChange={(e) => changeText(e.target.value, "regular_price")}
              />
            </Form.Group>

            {/* <Form.Group as={Col} controlId="salePrice">
              <Form.Label>Sale Price</Form.Label>
              <Form.Control
                className="input"
                type="text"
                placeholder="Enter sale price"

                defaultValue={productData?.sale_price}
                onChange={(e) => changeText(e.target.value, "sale_price")}
              />
            </Form.Group> */}
          </Row>

          <Row className="mb-4">
            <Form.Group as={Col} controlId="warranty">
              <Form.Label>Warranty ?</Form.Label>
              <Form.Select
                aria-label="Default checkStockOptions"
                defaultValue={productData?.warranty}
                onChange={(e) => changeText(e.target.value, "warranty")}
              >
                <option selected={productData?.warranty === "no"} value="no">
                  No
                </option>
                <option selected={productData?.warranty === "yes"} value="yes">
                  Yes
                </option>
              </Form.Select>
            </Form.Group>
            {productData?.warranty === "yes" && (
              <Form.Group as={Col} controlId="salePrice">
                <Form.Label>
                  Warranty Duration<span className="requered"> *</span>
                </Form.Label>
                <Form.Control
                  className="input"
                  type="text"
                  placeholder="Enter warranty duration period"
                  required
                  defaultValue={productData?.warranty_duration}
                  onChange={(e) =>
                    changeText(e.target.value, "warranty_duration")
                  }
                />
              </Form.Group>
            )}
          </Row>

          <Row className="mb-4">
            <Form.Group as={Col} controlId="warranty">
              <Form.Label>Refundable ?</Form.Label>
              <Form.Select
                aria-label="Default checkStockOptions"
                defaultValue={productData?.refundable}
                onChange={(e) => changeText(e.target.value, "refundable ")}
              >
                <option selected={productData?.refundable === "no"} value="no">
                  No
                </option>
                <option
                  selected={productData?.refundable === "yes"}
                  value="yes"
                >
                  Yes
                </option>
              </Form.Select>
            </Form.Group>
          </Row>

          <Row className="mb-4 text-editor-wrap">
            <Col>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Product Description</Form.Label>
                <div className="text-editor">
                  <SunEditor
                    width="100%"
                    setAllPlugins={true}
                    setDefaultStyle="font-family: Roboto; font-size: 14px;"
                    setOptions={{
                      showPathLabel: false,
                      minHeight: "200px",
                      maxHeight: "200px",
                      placeholder: "Enter your text here!!!",
                      plugins: [
                        align,
                        font,
                        fontColor,
                        fontSize,
                        formatBlock,
                        hiliteColor,
                        horizontalRule,
                        lineHeight,
                        list,
                        paragraphStyle,
                        table,
                        template,
                        textStyle,
                        link,
                        image,
                      ],
                      buttonList: [
                        ["undo", "redo"],
                        ["font", "fontSize", "formatBlock"],
                        ["paragraphStyle"],
                        [
                          "bold",
                          "underline",
                          "italic",
                          "strike",
                          "subscript",
                          "superscript",
                        ],
                        ["fontColor", "hiliteColor"],
                        ["removeFormat"],
                        "/", // Line break
                        ["outdent", "indent"],
                        ["align", "horizontalRule", "list", "lineHeight"],
                        ["table", "link"],
                      ],
                      formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                      font: [
                        "Arial",
                        "Calibri",
                        "Comic Sans",
                        "Courier",
                        "Garamond",
                        "Georgia",
                        "Impact",
                        "Lucida Console",
                        "Palatino Linotype",
                        "Segoe UI",
                        "Tahoma",
                        "Times New Roman",
                        "Trebuchet MS",
                        "Roboto",
                      ],
                    }}
                    setContents={productData?.description}
                    onChange={(content) => setDecription(content)}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-4 text-editor-wrap">
            <Col>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Product Key Features</Form.Label>
                <div className="text-editor">
                  <SunEditor
                    width="100%"
                    setAllPlugins={true}
                    setDefaultStyle="font-family: Roboto; font-size: 14px;"
                    setOptions={{
                      showPathLabel: false,
                      minHeight: "200px",
                      maxHeight: "200px",
                      placeholder: "Enter your text here!!!",
                      plugins: [
                        align,
                        font,
                        fontColor,
                        fontSize,
                        formatBlock,
                        hiliteColor,
                        horizontalRule,
                        lineHeight,
                        list,
                        paragraphStyle,
                        table,
                        template,
                        textStyle,
                        link,
                        image,
                      ],
                      buttonList: [
                        ["undo", "redo"],
                        ["font", "fontSize", "formatBlock"],
                        ["paragraphStyle"],
                        [
                          "bold",
                          "underline",
                          "italic",
                          "strike",
                          "subscript",
                          "superscript",
                        ],
                        ["fontColor", "hiliteColor"],
                        ["removeFormat"],
                        "/", // Line break
                        ["outdent", "indent"],
                        ["align", "horizontalRule", "list", "lineHeight"],
                        ["table", "link"],
                      ],
                      formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                      font: [
                        "Arial",
                        "Calibri",
                        "Comic Sans",
                        "Courier",
                        "Garamond",
                        "Georgia",
                        "Impact",
                        "Lucida Console",
                        "Palatino Linotype",
                        "Segoe UI",
                        "Tahoma",
                        "Times New Roman",
                        "Trebuchet MS",
                        "Roboto",
                      ],
                    }}
                    setContents={productData?.additional_information}
                    onChange={(content) => setAdditionalInfo(content)}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>

          {/* <Row className="mb-4"> Temporary  remove offers functionality
            <Form.Group as={Col} controlId="Product tags">
              <div className="new-product-auto-select cm-autocomplete offers">
                <Form.Label>
                  Add Offers{" "}
                  <span className="helptext">(Add offer to products)</span>
                </Form.Label>
                <Autocomplete
                  className="input"
                  getItemValue={(item) => item.offer_name}
                  items={offerList}
                  shouldItemRender={(item, value) =>
                    item.offer_name.toLowerCase().indexOf(value.toLowerCase()) >
                    -1
                  }
                  renderItem={(item, isHighlighted) => (
                    <div
                      className="input"
                      style={{
                        zIndex: "10!important",
                        background: isHighlighted ? "lightgray" : "white",
                      }}
                    >
                      {item.offer_name}
                    </div>
                  )}
                  value={productData?.offerName}
                  onChange={(e, value) => changeText(value, "offerName")}
                  onSelect={(val) => {
                    selectOfferHandle(val, "offer_id", "offerName", offerList);
                  }}
                />
              </div>
            </Form.Group>
          </Row> */}
          <div className="add-variant-btn">
            <p className="customLabel">
              Do you want add Variant of this products .?
            </p>
            <label>
              <input
                checked={AddVarientFlag ? true : false}
                onChange={(e) => {
                  setAddVarientFlag(e.target.checked);
                }}
                type="checkbox"
                name=""
                id="add-variant"
              />
              <div className="btn-wrap_2">
                <div className="btn-toggle"></div>
              </div>
            </label>
          </div>
          {!AddVarientFlag && ( // Hide this fields when user create variant
            <>
              <Row className="mb-4">
                <Form.Group as={Col} controlId="product-color">
                  <Form.Label>
                    Color{" "}
                    <span className="helptext">(Product default color)</span>{" "}
                    <span className="requered">*</span>
                  </Form.Label>
                  <Form.Control
                    className="input"
                    type="text"
                    required
                    placeholder="Enter Product Color"
                    defaultValue={productData?.color}
                    onChange={(e) => changeText(e.target.value, "color")}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="Product-size">
                  <div className="new-product-auto-select cm-autocomplete size">
                    <Form.Label>
                      Size{" "}
                      <span className="helptext">(Product default size)</span>{" "}
                      <span className="requered">*</span>
                    </Form.Label>
                    <Form.Control
                      className="input"
                      type="text"
                      placeholder="Enter Product Size"
                      defaultValue={productData?.size}
                      onChange={(e) => changeText(e.target.value, "size")}
                    />
                  </div>
                </Form.Group>
              </Row>

              <Row className="mb-4">
                <Form.Group as={Col} controlId="product-style">
                  <div className="new-product-auto-select cm-autocomplete style">
                    <Form.Label>
                      Product's Style{" "}
                      <span className="helptext">(Product's style)</span>
                    </Form.Label>
                    <Form.Control
                      className="input"
                      type="text"
                      placeholder="Enter Product style"
                      defaultValue={productData?.style}
                      onChange={(e) => changeText(e.target.value, "style")}
                    />
                  </div>
                </Form.Group>

                <Form.Group as={Col} controlId="Product-size">
                  <div className="new-product-auto-select cm-autocomplete material">
                    <Form.Label>
                      Product's material{" "}
                      <span className="helptext">(Product's material)</span>
                    </Form.Label>
                    <Form.Control
                      className="input"
                      type="text"
                      placeholder="Enter Product material"
                      defaultValue={productData?.material}
                      onChange={(e) => changeText(e.target.value, "material")}
                    />
                  </div>
                </Form.Group>
              </Row>

              <Row className="mb-4">
                <Form.Group as={Col} controlId="stockStatus">
                  <Form.Label>
                    Stock Status <span className="requered"> *</span>
                  </Form.Label>
                  <Form.Select
                    required
                    aria-label="Default checkStockOptions"
                    defaultValue={productData.stock_availability}
                    onChange={(e) =>
                      changeText(e.target.value, "stock_availability")
                    }
                  >
                    <option
                      selected={productData?.stock_availability === "in_stock"}
                      value="in_stock"
                    >
                      In Stock
                    </option>
                    <option
                      selected={productData?.stock_availability === "out_stock"}
                      value="out_stock"
                    >
                      Out Of Stock
                    </option>
                  </Form.Select>
                </Form.Group>

                <Form.Group as={Col} controlId="salePrice">
                  <Form.Label>
                    Stock QTY<span className="requered"> *</span>
                  </Form.Label>
                  <Form.Control
                    className="input"
                    type="text"
                    placeholder="Enter product stock quantity price"
                    required
                    defaultValue={productData?.stock_qty}
                    onChange={(e) => changeText(e.target.value, "stock_qty")}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-4 product-image">
                <Col>
                  <Form.Group
                    controlId="productMainImage"
                    className="mb-3 cm-file-upload"
                  >
                    <Form.Label>
                      Image <span className="requered">*</span>
                    </Form.Label>
                    <div className="previewImage">
                      <img
                        src={
                          productData.photoPreview ||
                          productData.image_url ||
                          ImgPreview
                        }
                        alt="Product Main Image"
                      />
                    </div>

                    <Form.Control
                      accept="image/*"
                      type="file"
                      className="input "
                      onChange={(e) => onSelectImage(e, "photo")}
                    />
                    <Form.Text id="product_main_img" muted>
                      (file will be in png/jpeg format)
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-4 productGalleries">
                <Col>
                  <Form.Group
                    controlId="productGalleries"
                    className="mb-3 cm-file-upload"
                  >
                    <Form.Label>Product Gallery Images</Form.Label>
                    <div className="upload-input-wrap">
                      <div className="gallery-img-preview">
                        {productData.galleryImgPreview.length > 0 &&
                          productData.galleryImgPreview.map(
                            (item, i, itemArr) => {
                              return (
                                <div className="gip-img" key={`gip-img-${i}`}>
                                  <img src={item.image || item} alt="Preview" />
                                  {item && (
                                    <div
                                      className="delete-img"
                                      onClick={(e) => {
                                        handleGalleryImages(i);
                                      }}
                                    >
                                      <span>
                                        <TiDelete />
                                      </span>
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )}
                      </div>
                      <div className="upload-input-inner">
                        <Form.Control
                          accept="image/*"
                          type="file"
                          className="input pg-image "
                          multiple
                          onChange={(e) => onSelectImage(e, "galleryImg")}
                        />
                      </div>
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Button className="btn-brown" variant="primary" type="submit">
                {urlHasSlug ? "Update" : "Add Product"}
              </Button>
            </>
          )}
        </Form>

        {AddVarientFlag && (
          <div className="add-variants-wrap">
            <div className="button-w">
              <button
                className="btn-brown"
                onClick={(e) => {
                  setModalFlag(!modalFlag);
                }}
              >
                Add Variant Here +
              </button>
            </div>

            {productVariantList.length > 0 && (
              <div className="pv-list">
                <ViewAllVariantOfProduct
                  productId={localStorage.getItem("rpId") || urlHasSlug}
                />
              </div>
            )}

            <div className="pv-modal">
              <AddProductVariant
                modalFlag={modalFlag}
                handleClose={() => setModalFlag(!modalFlag)}
                btnText={"Add Variant"}
                modalHeading={"Add new Variant"}
                userId={user?.id}
                userForUpdate={false}
                productFormData={{
                  ...productData,
                  description: decription,
                  additional_information: AdditionalInfo,
                }}
                productId={productVariantList[0]?.product_id}
                addVariantOnExitProduct={AddVarientOnExistingFlag}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AddnewProduct;
