import React, { useEffect, useState } from "react";
import "./OrderManagement.scss";
import { Link } from "react-router-dom";
import DemoImg from "../../../../assets/img/productDemo.jpg";
import { orderFeedbackListByVendorIdApi } from "../../../../api/Api_calls";
import { useDispatch, useSelector } from "react-redux";
import { CLOSE_LOADING, globalAction, SHOW_LOADING } from "../../../../redux";
import ReactPaginate from "react-paginate";
import moment from "moment";

function FeedbackOrder() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);
  const [feedbackListData, setFeedbackListData] = useState(undefined);

  const getOrderfeedback = (data) => {
    orderFeedbackListByVendorIdApi(data)
      .then((res) => {
        dispatch(globalAction(CLOSE_LOADING));
        const { data, status } = res;
        if (status === 200) {
          setFeedbackListData(data.data);
        }
      })
      .catch((err) => {
        dispatch(globalAction(CLOSE_LOADING));
        alert("Something went wrong");
      });
  };

  useEffect(() => {
    dispatch(globalAction(SHOW_LOADING));
    window.scroll({ top: 0 });
    getOrderfeedback({
      vendor_id: user?.id,
      limit: 20,
    });
  }, []);

  const handlePageClick = (data) => {
    document.getElementById("feedback_orders").scrollIntoView(true);
    dispatch(globalAction(SHOW_LOADING));
    getOrderfeedback({
      vendor_id: user?.id,
      limit: 20,
      page: data.selected + 1,
    });
  };

  const feedback = [
    {
      img: DemoImg,
      name: "10% Black Shoest",
      url: "/vendorDashboard/order-management/feedback-orders",
      feedback:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,",
    },
    {
      img: DemoImg,
      name: "10% Black Shoest",
      url: "/vendorDashboard/order-management/feedback-orders",
      feedback:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,",
    },
    {
      img: DemoImg,
      name: "10% Black Shoest",
      url: "/vendorDashboard/order-management/feedback-orders",
      feedback:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,",
    },
  ];

  return (
    <div className="vd-dashboard-wrap">
      <div className="title">
        <h3>Feedback Orders</h3>
      </div>
      <div className="vd-dashboard-body">
        <div className="box-size feedback_orders" id="feedback_orders">
          {feedbackListData?.data?.length > 0 ? (
            <>
              <ul>
                {feedbackListData?.data?.map((item, i) => {
                  console.log(item);
                  return (
                    <li className="card_wrap">
                      <ul>
                        <li className="image">
                          <a
                            href={`/product-detail/${item?.order_product?.product_slug}`}
                          >
                            <img
                              src={item?.order_product?.image_url}
                              alt={item?.order_product?.title}
                            />
                          </a>
                        </li>
                        <li className="details">
                          <a
                            href={`/product-detail/${item?.order_product?.product_slug}`}
                          >
                            {item?.order_product?.title}
                          </a>
                          <span>{moment(item?.created_at).format("LL")}</span>
                          <p>{item?.message}</p>
                        </li>
                      </ul>
                    </li>
                  );
                })}
              </ul>

              <div className="pcw-pagination">
                <div className="pcw-pagination-inner">
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={Math.ceil(
                      feedbackListData?.total / feedbackListData?.per_page
                    )}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                    forcePage={Number(feedbackListData?.current_page - 1)}
                  />
                </div>
              </div>
            </>
          ) : (
            <div class="no-vendor">
              <h1> No Feedback found....!</h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FeedbackOrder;
