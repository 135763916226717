/** @format */

import { takeEvery, put, call, delay, takeLatest } from "redux-saga/effects";
import {
  REMOVE_MESSAGE,
  CLOSE_LOADING,
  ADD_NEW_PRODUCT_BY_VENDOR_WITH_VARIANT,
} from "../actionsType";
import { globalAction } from "../actions";
import {
  GET_BRANDS_LIST,
  GET_CATEGORY_LIST,
  REQUEST_VENDOR_PAYMENT_GRAPH,
  CLOSE_LOADING_VENDOR,
  ERROR_VENDOR_PAYMENT_GRAPH,
  SUCCESS_VENDOR_PAYMENT_GRAPH,
  GET_OFFER_LIST,
  HIDE_MESSAGE,
  SET_BRANDS_LIST,
  SET_CATEGORY_LIST,
  SET_OFFER_LIST,
  SET_USER,
  FETCH_ORDER_REQUEST,
  SET_ORDER,
  SHOW_MESSAGE,
  UPDATE_COMPANY_KYC,
  UPDATE_COMPANY_PROFILE,
  UPDATE_COUNTRIES,
  UPDATE_PAYMENT_INFO,
  UPDATE_PROFILE,
  ADD_NEW_PRODUCT_BY_VENDOR,
  GET_PRODUCT_LIST,
  GET_PRODUCT_DETAILS_BY_ID,
  SET_PRODUCT_DETAILS,
  SET_PRODUCT_LIST,
  SELL_ON,
  UPDATE_PRODUCT_BY_VENDOR,
  ADD_PRODUCT_VARIANT_BY_VENDOR,
  GET_PRODUCT_VARIANT_LIST_BY_PRODUCT_ID,
  DELETE_PRODUCT_VARIANT_BY_ID,
  GET_ADVERTISEMENT,
  GET_BLOG_CATEGORIES,
  GET_BLOG_LISTING,
  GET_PRODUCT_LIST_FOR_USER,
  SET_ADVERTISEMENT,
  SET_BLOG_CATEGORIES,
  SET_BLOG_LISTING,
  SET_PRODUCT_VARIANT_LIST_BY_PRODUCT_ID,
  UPDATE_PRODUCT_VARIANT_BY_ID,
} from "../actionsType";
import {
  addNewProductApi,
  getBrandsListApi,
  getCategoryListApi,
  getOffersListApi,
  getProductDetailByIdApi,
  getProductListByVendorId,
  updateCountriesApi,
  updatePaymentInfoApi,
  updateProductApi,
  updateProfileApi,
  updateProfileCompanyApi,
  updateProfileKycApi,
  addProductVariantByVendorApi,
  getProductVariantListByProductIdApi,
  deleteProductVariantByProductApi,
  UpdateProductVariantByIdApi,
  getAdvertisementListApi,
  getProductListingForUserApi,
  getBlogListingApi,
  getBlogCategoriesApi,
  add_addressByIdApi,
  getAddressListByUserIdApi,
  deleteAddressByAddressIdApi,
  updateAddressByAddressIdApi,
  addOrderApi,
  getOrderListByUserIdApi,
  postSellInfo,
  contactUsApi,
  orderCancelApi,
  addProductToWishlistApi,
  getWishListByUserIdApi,
  deleteWishlistProductByIdApi,
  GetListWithdrawRequestApi,
  moneyWithRequestApi,
  getOrderListByVendorIdApi,
  getHomepageApi,
  getVendorDetailsByIdApi,
  addReviewByUserApi,
  orderFeedbackByUserIdApi,
  SubscribedNewsletterApi,
  cancelOrderProductApi,
  updateOrderStatusApi,
  updateShippingStatusApis,
  fetchvendorPaymentGraph,
  getOrderDetailByOrderId,
} from "../../api";
import {
  ADD_ADDRESS_AS_GUEST,
  ADD_ADDRESS_BY_USER_ID,
  ADD_PRODUCT_TO_WISHLIST,
  ADD_REVIEW_BY_USER,
  ADD_TO_CART,
  BOOK_ORDER_BY_USER,
  CALCULATE_CART_TOTAL_PRICE,
  CANCEL_ORDER_BY_ORDER_ID,
  CANCEL_ORDER_PRODUCT,
  CONTACT_US,
  DELETE_ADDRESS_BY_ADDRESS_ID,
  DELETE_CART_ITEM,
  DELETE_WISHLIST_PRODUCT_BY_ID,
  GET_ADDRESS_LIST,
  GET_HOME_DATA,
  GET_ORDER_LIST_BY_USER_ID,
  GET_ORDER_LIST_BY_VENDOR_ID,
  GET_VENDOR_DETAILS,
  GET_WISHLIST_BY_USER_ID,
  GET_WITHDRAW_REQUEST_LIST,
  SEND_MONEY_WITHDRAW_REQUEST,
  SEND_ORDER_FEEDBACK_BY_USER,
  SET_ADDRESS_LIST,
  SET_CART_ITEMS,
  SET_HOME_DATA,
  SET_MAX_PRICE_IN_PRODUCT_LIST,
  SET_ORDER_LIST_BY_USER_ID,
  SET_PRODUCTS_SELECTED_VARIANT,
  SET_PRODUCTS_VARIANT,
  SET_PRODUCTS_VARIENT,
  SET_RELATED_PRODUCTS,
  SET_WISHLIST_BY_USER_ID,
  SET_WITHDRAW_REQUEST_LIST,
  SUBSCRIBED_NEWSLETTER,
  UPDATE_ADDRESS_BY_ADDRESS_ID,
  UPDATE_CART_ITEM_QTY,
  UPDATE_ORDER_STATUS,
  UPDATE_SHIPPING_STATUS,
} from "..";

const callApi = (customFunction, data) => {
  return customFunction(data)
    .then((result) => result)
    .catch((err) => err.response);
};

function* updateCompanyProfile({ payload }) {
  try {
    const result = yield call(callApi, updateProfileCompanyApi, payload);
    const { status, data } = result;
    console.log("updateCompanyProfile", data.data);
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield localStorage.setItem("duAfirk_user", JSON.stringify(data.data));
        yield put(globalAction(SET_USER, data.data));
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err.response));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* updateCompanyKYC({ payload }) {
  try {
    const result = yield call(callApi, updateProfileKycApi, payload);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield localStorage.setItem("duAfirk_user", JSON.stringify(data.data));
        yield put(globalAction(SET_USER, data.data));
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err.response));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* updatePaymentInfo({ payload }) {
  try {
    const result = yield call(callApi, updatePaymentInfoApi, payload);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield localStorage.setItem("duAfirk_user", JSON.stringify(data.data));
        yield put(globalAction(SET_USER, data.data));
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err.response));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* updateCountries({ payload }) {
  try {
    const result = yield call(callApi, updateCountriesApi, payload);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield localStorage.setItem("duAfirk_user", JSON.stringify(data.data));
        yield put(globalAction(SET_USER, data.data));
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err.response));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* updateProfile({ payload }) {
  console.log(payload);
  try {
    const result = yield call(callApi, updateProfileApi, payload);
    const { status, data } = result;
    console.log(result);
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield localStorage.setItem("duAfirk_user", JSON.stringify(data.data));

        yield put(globalAction(SET_USER, data.data));
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err.response));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* getCategoryList({ payload }) {
  try {
    const result = yield call(callApi, getCategoryListApi);
    const { data, status } = result; //destructuring
    if (status === 200) {
      yield put(globalAction(SET_CATEGORY_LIST, data.category.data)); // inserting data into reducer
    }
  } catch (error) {
    console.log(error);
  }
}

function* getBrandsList({ payload }) {
  try {
    const result = yield call(callApi, getBrandsListApi);
    const { data, status } = result; //destructuring
    if (status === 200) {
      yield put(globalAction(SET_BRANDS_LIST, data.brands.data)); // inserting data into reducer
    }
  } catch (error) {
    console.log(error);
  }
}

function* getOfferList({ payload }) {
  try {
    const result = yield call(callApi, getOffersListApi);
    const { data, status } = result; //destructuring
    if (status === 200) {
      yield put(globalAction(SET_OFFER_LIST, data.offers)); // inserting data into reducer
    }
  } catch (error) {
    console.log(error);
  }
}

function* addNewProductByVendor({ payload }) {
  try {
    const result = yield call(callApi, addNewProductApi, payload);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* addNewProductByVendorWithVariant({ payload }) {
  const { variantData, productFormData } = payload;
  try {
    const result = yield call(callApi, addNewProductApi, productFormData);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        console.log("Yes Im runing", data.product_id);
        localStorage.setItem("rpId", data.product_id); // set recently product id
        let temp = {
          ...variantData,
          product_id: data.product_id,
          user_id: productFormData.user_id,
        };
        yield put(globalAction(ADD_PRODUCT_VARIANT_BY_VENDOR, temp)); // call add variant function
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* getProductList({ payload }) {
  try {
    const result = yield call(callApi, getProductListByVendorId, payload);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));

      if (data.status === "success") {
        yield put(globalAction(SET_PRODUCT_LIST, data.product));
        // yield put(globalAction(SHOW_MESSAGE, data))
        // yield delay(4000)
        // yield put(globalAction(HIDE_MESSAGE))
        // yield delay(1000)
        // yield put(globalAction(REMOVE_MESSAGE))
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* getProductDetailsById({ payload }) {
  try {
    const result = yield call(callApi, getProductDetailByIdApi, payload);
    console.log("getProductDetailsById", result);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield put(globalAction(SET_PRODUCT_DETAILS, data.product));
        yield put(globalAction(SET_RELATED_PRODUCTS, data.related_products));
        yield put(
          globalAction(SET_PRODUCTS_SELECTED_VARIANT, data.selected_variant)
        );
        if (data.product_variants === null) {
          yield put(globalAction(SET_PRODUCTS_VARIANT, []));
        } else {
          yield put(globalAction(SET_PRODUCTS_VARIANT, data));
        }

        // yield put(globalAction(SHOW_MESSAGE, data))
        // yield delay(4000)
        // yield put(globalAction(HIDE_MESSAGE))
        // yield delay(1000)
        // yield put(globalAction(REMOVE_MESSAGE))
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* updateProductByVendorById({ payload }) {
  try {
    const result = yield call(callApi, updateProductApi, payload);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));

      if (data.status === "success") {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    console.log(err);
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* addProductVariantByVendor({ payload }) {
  console.log("addProductVariantByVendor", payload);
  try {
    const result = yield call(callApi, addProductVariantByVendorApi, payload);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield put(
          globalAction(
            GET_PRODUCT_VARIANT_LIST_BY_PRODUCT_ID,
            payload.product_id
          )
        );
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* getProductVariantListByProductId({ payload }) {
  try {
    const result = yield call(
      callApi,
      getProductVariantListByProductIdApi,
      payload
    );
    console.log("getProductVariantListByProductIdApi", result);
    const { status, data } = result;

    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));

      if (data.status === "success") {
        yield put(
          globalAction(
            SET_PRODUCT_VARIANT_LIST_BY_PRODUCT_ID,
            data.product_variants.data
          )
        );
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* deleteProductVariantById({ payload }) {
  try {
    const result = yield call(
      callApi,
      deleteProductVariantByProductApi,
      payload
    );
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));

      if (data.status === "success") {
        yield put(
          globalAction(
            GET_PRODUCT_VARIANT_LIST_BY_PRODUCT_ID,
            payload.productId
          )
        );
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(2000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* updateProductVariantById({ payload }) {
  try {
    const result = yield call(callApi, UpdateProductVariantByIdApi, payload);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));

      if (data.status === "success") {
        yield put(
          globalAction(
            GET_PRODUCT_VARIANT_LIST_BY_PRODUCT_ID,
            payload.product_id
          )
        );
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(2000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* getAdvertisementList({ payload }) {
  try {
    const result = yield call(callApi, getAdvertisementListApi, payload);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield put(globalAction(SET_ADVERTISEMENT, data.advertisement));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* getProductListForUser({ payload }) {
  const { filterObj, history } = payload;
  localStorage.setItem("filterObj", JSON.stringify(filterObj)); // improve search functionality, but is not necessary
  let finalParams = "";
  for (const key in filterObj) {
    if (filterObj[key] === undefined) {
      delete filterObj[key];
    }
  }

  if (filterObj) {
    let k = 0;

    for (const key in filterObj) {
      if (filterObj[key] !== undefined) {
        if (k === 0) {
          finalParams = finalParams + `?${key}=${filterObj[key]}`;
        } else {
          finalParams = finalParams + `&${key}=${filterObj[key]}`;
        }
      }
      k++;
    }
  }
  try {
    const result = yield call(
      callApi,
      getProductListingForUserApi,
      finalParams
    );
    const { status, data, statusText } = result;
    console.log(result, "result");
    history.push(`/products/${finalParams}`);
    if (status === 200 || statusText === "OK") {
      yield put(globalAction(CLOSE_LOADING));
      if (data?.status === "success") {
        yield put(globalAction(SET_PRODUCT_LIST, data.product_list));
        yield put(globalAction(SET_MAX_PRICE_IN_PRODUCT_LIST, data.max_price));
        // yield put(globalAction(SHOW_MESSAGE, data))
        // yield delay(4000)
        // yield put(globalAction(HIDE_MESSAGE))
        // yield delay(1000)
        // yield put(globalAction(REMOVE_MESSAGE))
      } else {
        yield put(globalAction(SHOW_MESSAGE, data || data?.message));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* getBlogListing({ payload }) {
  let finalParams = "";
  if (payload) {
    let k = 0;
    for (const key in payload) {
      if (payload[key] !== undefined) {
        if (k === 0) {
          finalParams = finalParams + `?${key}=${payload[key]}`;
        } else {
          finalParams = finalParams + `&${key}=${payload[key]}`;
        }
      }
      k++;
    }
  }
  try {
    const result = yield call(callApi, getBlogListingApi, finalParams);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield put(globalAction(SET_BLOG_LISTING, data.blog));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* getBlogCategories({ payload }) {
  try {
    const result = yield call(callApi, getBlogCategoriesApi);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield put(globalAction(SET_BLOG_CATEGORIES, data.blog_category));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* addToCart({ payload }) {
  let localCartList = localStorage.getItem("cartList")
    ? JSON.parse(localStorage.getItem("cartList"))
    : [];
  let tempData = {};

  // RESTRICTION FOR NOT ADDING PRODUCT BY MULTIPLE SELLERS

  if (localCartList?.length >= 1) {
    const sellerId = localCartList[0]?.user?.id;
    if (payload?.item?.user?.id !== sellerId) {
      alert("You can't buy multiple products from different seller");
      return;
    }
  }
  // RESTRICTION FOR NOT ADDING PRODUCT BY MULTIPLE SELLERS

  let itemAlreadyExits = localCartList.find(
    (item, i) => item.id === payload.item.id
  );
  if (itemAlreadyExits) {
    // check item is already exits
    tempData = {
      status: "error",
      message: "Already in your cart",
    };
  } else if (payload.user === null) {
    tempData = {
      status: "success",
      message: "Successfully Added in your cart",
    };
    localCartList.push(payload.item);
    localStorage.setItem("cartList", JSON.stringify(localCartList));
    yield put(globalAction(SET_CART_ITEMS, localCartList));
    yield put(globalAction(CALCULATE_CART_TOTAL_PRICE, localCartList));
  } else if (payload.user?.role_id !== 2) {
    // check user account type is already exits
    tempData = {
      status: "error",
      message: "Please Login with customer account",
    };
  } else {
    tempData = {
      status: "success",
      message: "Successfully Added in your cart",
    };
    localCartList.push(payload.item);
    localStorage.setItem("cartList", JSON.stringify(localCartList));
    yield put(globalAction(SET_CART_ITEMS, localCartList));
    yield put(globalAction(CALCULATE_CART_TOTAL_PRICE, localCartList));
  }
  yield put(globalAction(CLOSE_LOADING));
  yield put(globalAction(SHOW_MESSAGE, tempData));
  yield delay(1500);
  yield put(globalAction(HIDE_MESSAGE));
  yield delay(500);
  yield put(globalAction(REMOVE_MESSAGE));
}

function* updateCartItemQty({ payload }) {
  let tempData;
  if (payload.user === null) {
    localStorage.setItem("cartList", JSON.stringify(payload.cartList));
    yield put(globalAction(SET_CART_ITEMS, payload.cartList));
    yield put(globalAction(CALCULATE_CART_TOTAL_PRICE, payload.cartList));
  } else if (payload.user?.role_id !== 2) {
    tempData = {
      status: "error",
      message: "Please Login with customer account",
    };
    yield put(globalAction(SHOW_MESSAGE, tempData));
  } else {
    localStorage.setItem("cartList", JSON.stringify(payload.cartList));
    yield put(globalAction(SET_CART_ITEMS, payload.cartList));
    yield put(globalAction(CALCULATE_CART_TOTAL_PRICE, payload.cartList));
  }

  yield put(globalAction(CLOSE_LOADING));
  yield delay(1500);
  yield put(globalAction(HIDE_MESSAGE));
  yield delay(500);
  yield put(globalAction(REMOVE_MESSAGE));
}

function* deleteCartItem({ payload }) {
  let tempData;
  tempData = {
    status: "success",
    message: `${payload?.item?.title} successfully removed from cart`,
  };
  localStorage.setItem("cartList", JSON.stringify(payload.cartList));
  yield put(globalAction(SET_CART_ITEMS, payload.cartList));
  yield put(globalAction(CALCULATE_CART_TOTAL_PRICE, payload.cartList));
  if (payload?.showMessage) {
    yield put(globalAction(SHOW_MESSAGE, tempData));
  }
  yield put(globalAction(CLOSE_LOADING));
  yield delay(1500);
  yield put(globalAction(HIDE_MESSAGE));
  yield delay(500);
  yield put(globalAction(REMOVE_MESSAGE));
}

function* addAddressByUserId({ payload }) {
  try {
    const result = yield call(callApi, add_addressByIdApi, payload);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));

      if (data.status === "success") {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield put(globalAction(GET_ADDRESS_LIST, payload.user_id));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* getAddressListByUserId({ payload }) {
  console.log(payload);
  try {
    const result = yield call(callApi, getAddressListByUserIdApi, payload);
    const { status, data } = result;
    console.log(result);
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));

      if (data.status === "Success") {
        yield put(globalAction(SET_ADDRESS_LIST, data.addresses));
        yield delay(2000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(2000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* deleteAddressByAddressId({ payload }) {
  try {
    const result = yield call(callApi, deleteAddressByAddressIdApi, payload.id);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield put(globalAction(GET_ADDRESS_LIST, payload.user.id));
        console.log("deleteAddressByAddressId", payload);
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(2000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(200);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* updateAddressByAddressId({ payload }) {
  try {
    const result = yield call(callApi, updateAddressByAddressIdApi, payload);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));

      if (data.status === "success") {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield put(globalAction(GET_ADDRESS_LIST, payload.user_id));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* bookOrderByUser({ payload }) {
  const { finalOrderObject, history } = payload;
  try {
    const result = yield call(callApi, addOrderApi, finalOrderObject);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield put(globalAction(SHOW_MESSAGE, data));
        localStorage.removeItem("cartList");
        yield put(globalAction(SET_CART_ITEMS, []));
        yield delay(1000);
        history.push("/order-complete");
        yield delay(2000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* getOrderListByUserId({ payload }) {
  console.log(payload);
  try {
    const result = yield call(callApi, getOrderListByUserIdApi, payload);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield put(globalAction(SET_ORDER_LIST_BY_USER_ID, data.data));
        yield delay(2000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(2000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* sendSellInfo({ payload }) {
  try {
    const result = yield call(callApi, postSellInfo, payload);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* sendContactUs({ payload }) {
  try {
    const result = yield call(callApi, contactUsApi, payload);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* cancelOrderByOrderId({ payload }) {
  try {
    const result = yield call(callApi, orderCancelApi, payload);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* addProductToWishlist({ payload }) {
  try {
    const result = yield call(callApi, addProductToWishlistApi, payload);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* getWishlistByUserId({ payload }) {
  try {
    const result = yield call(callApi, getWishListByUserIdApi, payload);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield put(globalAction(SET_WISHLIST_BY_USER_ID, data.data));
        yield delay(2000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(2000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* deleteWishlistProductById({ payload }) {
  const { params, id } = payload;
  try {
    const result = yield call(callApi, deleteWishlistProductByIdApi, {
      id: id,
    });
    const { status, data } = result;
    console.log(params);
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield put(globalAction(GET_WISHLIST_BY_USER_ID, params));
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* addAddressAsGuest({ payload }) {
  try {
    const result = yield call(callApi, add_addressByIdApi, payload);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));

      if (data.status === "success") {
        if (payload.type === "ForShipping") {
          localStorage.setItem("shippingId", data.data.id);
        }
        if (payload.type === "ForBilling") {
          localStorage.setItem("billingId", data.data.id);
        }

        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* getWithdrawList({ payload }) {
  try {
    const result = yield call(callApi, GetListWithdrawRequestApi, payload);
    const { status, data } = result;
    console.log(result);
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield put(globalAction(SET_WITHDRAW_REQUEST_LIST, data.data));
        yield delay(2000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(2000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* sendMoneyWithdrawRequest({ payload }) {
  try {
    const result = yield call(callApi, moneyWithRequestApi, payload);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield put(globalAction(GET_VENDOR_DETAILS, payload?.vendor_id));
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* getOrderListByVendorId({ payload }) {
  console.log(payload);
  try {
    const result = yield call(callApi, getOrderListByVendorIdApi, payload);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield put(globalAction(SET_ORDER_LIST_BY_USER_ID, data.data));
        yield delay(2000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(2000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* getHomeData({ payload }) {
  try {
    const result = yield call(callApi, getHomepageApi);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield put(globalAction(SET_HOME_DATA, data));
        yield delay(2000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(2000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* getVendorDetailsById({ payload }) {
  console.log(payload);
  try {
    const result = yield call(callApi, getVendorDetailsByIdApi, payload);
    const { status, data } = result;
    console.log(result);
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield localStorage.setItem("duAfirk_token", data.access_token);
        // yield localStorage.setItem("duAfirk_user", JSON.stringify(data.data))
        // yield put(globalAction(SET_USER, data.data))
        yield delay(2000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(2000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* addReviewByUser({ payload }) {
  try {
    const result = yield call(callApi, addReviewByUserApi, payload);
    const { status, data } = result;

    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield put(
          globalAction(GET_PRODUCT_DETAILS_BY_ID, { slug: payload.slug })
        );
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* sendOrderFeedbackByUser({ payload }) {
  try {
    const result = yield call(callApi, orderFeedbackByUserIdApi, payload);
    const { status, data } = result;

    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      if (data.status === "success") {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(4000);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(1000);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(250);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* subscribedNewsletter({ payload }) {
  try {
    const result = yield call(callApi, SubscribedNewsletterApi, payload);
    const { status, data } = result;
    yield put(globalAction(CLOSE_LOADING));
    if (status === 200) {
      if (data.status === "success") {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(1500);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(100);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(1500);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(100);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(200);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(1500);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* cancelOrderProduct({ payload }) {
  try {
    const result = yield call(callApi, cancelOrderProductApi, payload);
    const { status, data } = result;
    yield put(globalAction(CLOSE_LOADING));
    if (status === 200) {
      if (data.status === "success") {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(1500);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(100);
        yield put(globalAction(REMOVE_MESSAGE));
      } else {
        yield put(globalAction(SHOW_MESSAGE, data));
        yield delay(1500);
        yield put(globalAction(HIDE_MESSAGE));
        yield delay(100);
        yield put(globalAction(REMOVE_MESSAGE));
      }
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
    yield delay(200);
    yield put(globalAction(SHOW_MESSAGE, err));
    yield delay(1500);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* fetchOrderRequest({ payload }) {
  try {
    const result = yield call(callApi, getOrderDetailByOrderId, payload);
    const { status, data } = result;
    yield put(globalAction(SET_ORDER, data.data));
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
  }
}

function* updateOrderStatus({ payload }) {
  try {
    const result = yield call(callApi, updateOrderStatusApi, payload);
    const { status, data } = result;
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
  }
}

function* vendorPaymentsGraph({ payload }) {
  try {
    const result = yield call(callApi, fetchvendorPaymentGraph, payload);
    const { data } = result;
    const payments = [["Months", "Amount"]];
    data?.paymentGraph?.forEach((item, i) => {
      payments.push([item?.months, parseInt(item.totalPayment) || 0]);
    });

    yield put(globalAction(SUCCESS_VENDOR_PAYMENT_GRAPH, payments));
    yield put(globalAction(CLOSE_LOADING_VENDOR));
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING_VENDOR));
    yield put(globalAction(ERROR_VENDOR_PAYMENT_GRAPH));
  }
}

function* updateShippingStatus({ payload }) {
  try {
    const result = yield call(callApi, updateShippingStatusApis, payload);
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(FETCH_ORDER_REQUEST, payload.order_id));
      yield put(globalAction(CLOSE_LOADING));
      yield put(globalAction(SHOW_MESSAGE, data));
      yield delay(1500);
      yield put(globalAction(HIDE_MESSAGE));
      yield delay(100);
      yield put(globalAction(REMOVE_MESSAGE));
    }
  } catch (err) {
    yield put(globalAction(CLOSE_LOADING));
  }
}

export function* watchAppSaga() {
  yield takeEvery(UPDATE_COMPANY_PROFILE, updateCompanyProfile);
  yield takeEvery(UPDATE_COMPANY_KYC, updateCompanyKYC);
  yield takeEvery(UPDATE_PAYMENT_INFO, updatePaymentInfo);
  yield takeEvery(UPDATE_COUNTRIES, updateCountries);
  yield takeEvery(UPDATE_PROFILE, updateProfile);
  yield takeEvery(GET_CATEGORY_LIST, getCategoryList);
  yield takeEvery(GET_BRANDS_LIST, getBrandsList);
  yield takeEvery(GET_OFFER_LIST, getOfferList);
  yield takeEvery(ADD_NEW_PRODUCT_BY_VENDOR, addNewProductByVendor);
  yield takeEvery(
    ADD_NEW_PRODUCT_BY_VENDOR_WITH_VARIANT,
    addNewProductByVendorWithVariant
  );
  yield takeEvery(GET_PRODUCT_LIST, getProductList);
  yield takeEvery(GET_PRODUCT_DETAILS_BY_ID, getProductDetailsById);
  yield takeEvery(UPDATE_PRODUCT_BY_VENDOR, updateProductByVendorById);
  yield takeEvery(ADD_PRODUCT_VARIANT_BY_VENDOR, addProductVariantByVendor);
  yield takeEvery(
    GET_PRODUCT_VARIANT_LIST_BY_PRODUCT_ID,
    getProductVariantListByProductId
  );
  yield takeEvery(DELETE_PRODUCT_VARIANT_BY_ID, deleteProductVariantById);
  yield takeEvery(UPDATE_PRODUCT_VARIANT_BY_ID, updateProductVariantById);
  yield takeEvery(GET_ADVERTISEMENT, getAdvertisementList);
  yield takeEvery(GET_PRODUCT_LIST_FOR_USER, getProductListForUser);
  yield takeEvery(GET_BLOG_LISTING, getBlogListing);
  yield takeEvery(GET_BLOG_CATEGORIES, getBlogCategories);
  yield takeEvery(ADD_TO_CART, addToCart);
  yield takeEvery(UPDATE_CART_ITEM_QTY, updateCartItemQty);
  yield takeEvery(DELETE_CART_ITEM, deleteCartItem);
  yield takeEvery(ADD_ADDRESS_BY_USER_ID, addAddressByUserId);
  yield takeEvery(ADD_ADDRESS_AS_GUEST, addAddressAsGuest);
  yield takeEvery(GET_ADDRESS_LIST, getAddressListByUserId);
  yield takeEvery(DELETE_ADDRESS_BY_ADDRESS_ID, deleteAddressByAddressId);
  yield takeEvery(UPDATE_ADDRESS_BY_ADDRESS_ID, updateAddressByAddressId);
  yield takeEvery(BOOK_ORDER_BY_USER, bookOrderByUser);
  yield takeEvery(GET_ORDER_LIST_BY_USER_ID, getOrderListByUserId);
  yield takeEvery(CONTACT_US, sendContactUs);
  yield takeEvery(SELL_ON, sendSellInfo);
  yield takeEvery(CANCEL_ORDER_BY_ORDER_ID, cancelOrderByOrderId);
  yield takeEvery(ADD_PRODUCT_TO_WISHLIST, addProductToWishlist);
  yield takeEvery(GET_WISHLIST_BY_USER_ID, getWishlistByUserId);
  yield takeEvery(DELETE_WISHLIST_PRODUCT_BY_ID, deleteWishlistProductById);
  yield takeEvery(GET_WITHDRAW_REQUEST_LIST, getWithdrawList);
  yield takeEvery(SEND_MONEY_WITHDRAW_REQUEST, sendMoneyWithdrawRequest);
  yield takeEvery(GET_ORDER_LIST_BY_VENDOR_ID, getOrderListByVendorId);
  yield takeEvery(GET_HOME_DATA, getHomeData);
  yield takeEvery(GET_VENDOR_DETAILS, getVendorDetailsById);
  yield takeEvery(ADD_REVIEW_BY_USER, addReviewByUser);
  yield takeEvery(SEND_ORDER_FEEDBACK_BY_USER, sendOrderFeedbackByUser);
  yield takeEvery(SUBSCRIBED_NEWSLETTER, subscribedNewsletter);
  yield takeEvery(CANCEL_ORDER_PRODUCT, cancelOrderProduct);
  yield takeEvery(UPDATE_ORDER_STATUS, updateOrderStatus);
  yield takeEvery(FETCH_ORDER_REQUEST, fetchOrderRequest);
  yield takeEvery(UPDATE_SHIPPING_STATUS, updateShippingStatus);
  yield takeLatest(REQUEST_VENDOR_PAYMENT_GRAPH, vendorPaymentsGraph);
}
