export const SET_CURRENT_COUNTRY = "SET_CURRENT_COUNTRY";
export const SIGN_UP = "SIGN_UP";
export const LOGIN = "LOGIN";
export const ACCOUNT_VERIFY = "ACCOUNT_VERIFY";
export const RESEND_EMAIL_VERIFY_CODE = "RESEND_EMAIL_VERIFY_CODE";
export const SET_USER = "SET_USER";
export const LOGOUT = "LOGOUT";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";

// Error Action Constant
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";
export const CLOSE_LOADING = "CLOSE_LOADING";
export const SHOW_LOADING = "SHOW_LOADING";

export const CLOSE_LOADING_VENDOR = "CLOSE_LOADING_VENDOR";
export const SHOW_LOADING_VENDOR = "SHOW_LOADING_VENDOR";

export const UPDATE_COMPANY_PROFILE = "UPDATE_COMPANY_PROFILE";
export const UPDATE_COMPANY_KYC = "UPDATE_COMPANY_KYC";
export const UPDATE_PAYMENT_INFO = "UPDATE_PAYMENT_INFO";
export const UPDATE_COUNTRIES = "UPDATE_COUNTRIES";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

// GET Common data
export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";
export const SET_CATEGORY_LIST = "SET_CATEGORY_LIST";
export const GET_SUB_CATEGORY_LIST = "GET_SUB_CATEGORY_LIST";
export const SET_SUB_CATEGORY_LIST = "SET_SUB_CATEGORY_LIST";
export const GET_BRANDS_LIST = "GET_BRANDS_LIST";
export const SET_BRANDS_LIST = "SET_BRANDS_LIST";
export const GET_OFFER_LIST = "GET_OFFER_LIST";
export const SET_OFFER_LIST = "SET_OFFER_LIST";
export const FETCH_ORDER_REQUEST = "FETCH_ORDER_REQUEST";
export const SET_ORDER = "SET_ORDER";

export const GET_PRODUCT_LIST = "GET_PRODUCT_LIST";
export const SET_PRODUCT_LIST = "SET_PRODUCT_LIST";
export const SET_MAX_PRICE_IN_PRODUCT_LIST = "SET_MAX_PRICE_IN_PRODUCT_LIST";
export const ADD_NEW_PRODUCT_BY_VENDOR = "ADD_NEW_PRODUCT_BY_VENDOR";
export const ADD_NEW_PRODUCT_BY_VENDOR_WITH_VARIANT =
  "ADD_NEW_PRODUCT_BY_VENDOR_WITH_VARIANT";
export const GET_PRODUCT_DETAILS_BY_ID = "GET_PRODUCT_DETAILS_BY_ID";
export const SET_PRODUCT_DETAILS = "GET_PRODUCT_DETAIL";
export const UPDATE_PRODUCT_BY_VENDOR = "UPDATE_PRODUCT_BY_VENDOR";
export const ADD_PRODUCT_VARIANT_BY_VENDOR = "ADD_PRODUCT_VARIANT_BY_VENDOR";
export const GET_PRODUCT_VARIANT_LIST_BY_PRODUCT_ID =
  "GET_PRODUCT_VARIANT_LIST_BY_PRODUCT_ID";
export const SET_PRODUCT_VARIANT_LIST_BY_PRODUCT_ID =
  "SET_PRODUCT_VARIANT_LIST_BY_PRODUCT_ID";
export const DELETE_PRODUCT_VARIANT_BY_ID = "DELETE_PRODUCT_VARIANT_BY_ID";
export const UPDATE_PRODUCT_VARIANT_BY_ID = "UPDATE_PRODUCT_VARIANT_BY_ID";
export const SET_PRODUCTS_VARIANT = "SET_PRODUCTS_VARIANT";
export const SET_PRODUCTS_SELECTED_VARIANT = "SET_PRODUCTS_SELECTED_VARIANT";

export const GET_ADVERTISEMENT = "GET_ADVERTISEMENT";
export const SET_ADVERTISEMENT = "SET_ADVERTISEMENT";

export const GET_PRODUCT_LIST_FOR_USER = "GET_PRODUCT_LIST_FOR_USER";

export const GET_BLOG_LISTING = "GET_BLOG_LISTING";
export const SET_BLOG_LISTING = "SET_BLOG_LISTING";

export const GET_BLOG_CATEGORIES = "GET_BLOG_CATEGORIES";
export const SET_BLOG_CATEGORIES = "SET_BLOG_CATEGORIES";
export const SET_RELATED_PRODUCTS = "SET_RELATED_PRODUCTS";

export const ADD_TO_CART = "ADD_TO_CART";
export const SET_CART_ITEMS = "SET_CART_ITEMS";
export const GET_CART_ITEMS = "GET_CART_ITEMS";
export const UPDATE_CART_ITEM_QTY = "UPDATE_CART_ITEM_QTY";
export const DELETE_CART_ITEM = "DELETE_CART_ITEM";
export const CALCULATE_CART_TOTAL_PRICE = "CALCULATE_CART_TOTAL_PRICE";

export const ADD_ADDRESS_BY_USER_ID = "ADD_ADDRESS_BY_USER_ID";
export const ADD_ADDRESS_AS_GUEST = "ADD_ADDRESS_AS_GUEST";
export const UPDATE_ADDRESS_BY_ADDRESS_ID = "UPDATE_ADDRESS_BY_ADDRESS_ID";
export const DELETE_ADDRESS_BY_ADDRESS_ID = "DELETE_ADDRESS_BY_ADDRESS_ID";
export const GET_ADDRESS_LIST_BY_USER_ID = "GET_ADDRESS_LIST_BY_USER_ID";

export const GET_ADDRESS_LIST = "GET_ADDRESS_LIST";
export const SET_ADDRESS_LIST = "SET_ADDRESS_LIST";

export const BOOK_ORDER_BY_USER = "BOOK_ORDER_BY_USER";
export const GET_ORDER_LIST_BY_USER_ID = "GET_ORDER_LIST_BY_USER_ID";
export const SET_ORDER_LIST_BY_USER_ID = "SET_ORDER_LIST_BY_USER_ID";
export const CANCEL_ORDER_BY_ORDER_ID = "CANCEL_ORDER_BY_ORDER_ID";
export const GET_ORDER_LIST_BY_VENDOR_ID = "GET_ORDER_LIST_BY_VENDOR_ID";
export const SEND_ORDER_FEEDBACK_BY_USER = "SEND_ORDER_FEEDBACK_BY_USER";
export const CANCEL_ORDER_PRODUCT = "CANCEL_ORDER_PRODUCT";
export const UPDATE_ORDER_STATUS = "UPDATE_ORDER_STATUS";
export const UPDATE_SHIPPING_STATUS = "UPDATE_SHIPPING_STATUS";

export const ADD_PRODUCT_TO_WISHLIST = "ADD_PRODUCT_TO_WISHLIST";
export const GET_WISHLIST_BY_USER_ID = "  GET_WISHLIST_BY_USER_ID";
export const SET_WISHLIST_BY_USER_ID = "SET_WISHLIST_BY_USER_ID";
export const DELETE_WISHLIST_PRODUCT_BY_ID = "DELETE_WISHLIST_PRODUCT_BY_ID";

export const GET_WITHDRAW_REQUEST_LIST = "GET_WITHDRAW_REQUEST_LIST";
export const SET_WITHDRAW_REQUEST_LIST = "SET_WITHDRAW_REQUEST_LIST";
export const SEND_MONEY_WITHDRAW_REQUEST = "SEND_MONEY_WITHDRAW_REQUEST";

export const CONTACT_US = "CONTACT_US";
export const SELL_ON = "SELL_ON";
export const GET_HOME_DATA = "GET_HOME_DATA";
export const SET_HOME_DATA = "SET_HOME_DATA";

export const GET_VENDOR_DETAILS = "GET_VENDOR_DETAILS";

export const ADD_REVIEW_BY_USER = "ADD_REVIEW_BY_USER";
export const SUBSCRIBED_NEWSLETTER = "SUBSCRIBED_NEWSLETTER";
export const SET_VENDOR_NOTIFICATIONS = "SET_VENDOR_NOTIFICATIONS";

export const REQUEST_VENDOR_PAYMENT_GRAPH = "REQUEST_VENDOR_PAYMENT_GRAPH";
export const SUCCESS_VENDOR_PAYMENT_GRAPH = "SUCCESS_VENDOR_PAYMENT_GRAPH";
export const ERROR_VENDOR_PAYMENT_GRAPH = "ERROR_VENDOR_PAYMENT_GRAPH";
