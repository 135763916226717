import React, { useEffect, useState } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import "./Login.scss";
import LoginImg from "../../assets/img/Login-img.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CLOSE_LOADING, globalAction, LOGIN, SHOW_LOADING } from "../../redux";
import { useHistory } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import CmAlert from "../../components/CmAlert/CmAlert";
import Slider from "react-slick";
import { getAdvertisementListApi } from "../../api";
import SeoSetting from "../../components/SeoSetting/SeoSetting";
import { IoEye, IoEyeOffSharp } from "react-icons/io5";

const credential = {
  email: "",
  password: "",
};

function Login() {
  let history = useHistory();
  const dispatch = useDispatch();
  const { visibleMessage, isLoading, status, message } = useSelector(
    (state) => state.errorReducer
  );
  const [state, setState] = useState(credential);
  const [advertisementList, setAdvertisementList] = useState(undefined);
  const [showPassword, setShowPassword] = useState(false);

  const [validated, setValidated] = useState(false);

  useEffect(() => {
    dispatch(globalAction(CLOSE_LOADING));
    window.scroll({ top: 0 });
    getAdvertisementListApi("?page=login")
      .then((res) => {
        const { status, data } = res;
        if (status === 200) {
          setAdvertisementList(data.advertisement);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const changeText = (value, text) => {
    let credential = state;
    credential[text] = value;
    setState(credential);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    if (form.checkValidity()) {
      let formData = { ...state, history: history };
      dispatch(globalAction(SHOW_LOADING));
      dispatch(globalAction(LOGIN, formData));
    }
    setValidated(true);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <SeoSetting title="Du-Afrik-Login" metaDescription="Home" />

      <CmAlert message={message} status={status} showAlert={visibleMessage} />

      <div className="login-m-wrap">
        <div className="cm-container">
          <div className="row">
            <div className="col-md-6">
              <div className="login-form-wrap">
                <Loading showLoading={isLoading} />
                <div className="top-text">
                  <h1>Login</h1>
                  <p>
                    Doesn't have an account yet?{" "}
                    <a style={{ "font-size": "21px" }} href="/sign-up">
                      Sign Up
                    </a>
                  </p>
                </div>
                <div className="login-form">
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    <Form.Group className="mb-5" controlId="formBasicEmail">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        required
                        type="email"
                        placeholder="Enter email"
                        className="input"
                        onChange={(e) => {
                          changeText(e.target.value, "email");
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter an email address.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-4" controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <InputGroup
                        style={{
                          border: "1px solid #000000",
                          borderRadius: "4px",
                        }}
                      >
                        <Form.Control
                          required
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter 6 characters or more"
                          // className="input"
                          onChange={(e) =>
                            changeText(e.target.value, "password")
                          }
                          style={{
                            minHeight: "55px",
                            fontSize: "18px",
                            color: "#3a3a3a",
                          }}
                        />
                        <Button
                          onClick={() => setShowPassword(!showPassword)}
                          style={{
                            color: "#000000",
                            backgroundColor: "#ffffff",
                            borderColor: "#ffffff",
                            outline: "none",
                            boxShadow: "none",
                          }}
                        >
                          {showPassword ? (
                            <IoEye fontSize={25} />
                          ) : (
                            <IoEyeOffSharp fontSize={25} />
                          )}
                        </Button>
                      </InputGroup>
                      <Form.Control.Feedback type="invalid">
                        Please enter your password.
                      </Form.Control.Feedback>
                      {/* <Form.Control
                        required
                        type="password"
                        placeholder="Password"
                        className="input"
                        onChange={(e) => {
                          changeText(e.target.value, "password");
                        }}
                      /> */}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check type="checkbox" label="Remember me" />
                    </Form.Group>

                    <Form.Label className="forget-password">
                      <a href="/forget-password">Forgot Password</a>
                    </Form.Label>
                    <Button
                      variant="primary"
                      type="submit"
                      className="btn-brown"
                    >
                      Login
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="slider">
                <Slider {...settings}>
                  {advertisementList &&
                    advertisementList?.map((item, index) => {
                      return (
                        <div className="item img-wrap">
                          <a
                            href={`/${item.link}`}
                            rel="noreferrer noopener"
                            onClick={(e) => {
                              e.preventDefault();
                              window.open(
                                item?.link,
                                "_blank",
                                "noopener,noreferrer"
                              );
                            }}
                          >
                            <img src={item.image} alt={item.title} />
                          </a>
                        </div>
                      );
                    })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
