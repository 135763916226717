import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import { Link } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import { FaTimes } from "react-icons/fa";
import img from "../../assets/img/puma.png";
import emptyProduct from "../../assets/img/empty-wishlist.png";
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_TO_CART,
  GET_WISHLIST_BY_USER_ID,
  DELETE_WISHLIST_PRODUCT_BY_ID,
  globalAction,
  SHOW_LOADING,
} from "../../redux";
import ReactPaginate from "react-paginate";

function WishList() {
  const { user } = useSelector((state) => state.authReducer);
  const { wishList } = useSelector((state) => state.appReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      let params = `?id=${user?.id}&limit=${10}`;
      dispatch(globalAction(SHOW_LOADING));
      dispatch(globalAction(GET_WISHLIST_BY_USER_ID, params));
    }
  }, []);

  const handlePageClick = (data) => {
    let params = `?id=${user?.id}&page=${data.selected + 1}&limit=${1}`;
    document.getElementById("wishlist_item").scrollIntoView(true);
    dispatch(globalAction(SHOW_LOADING));
    dispatch(globalAction(GET_WISHLIST_BY_USER_ID, params));
  };

  const RemoveProductFromWishlist = (id) => {
    let params;
    if (user) {
      if (wishList?.data?.length === 1 && wishList?.last_page > 1) {
        params = `?id=${user?.id}&page=${wishList?.current_page - 1}`;
      } else {
        params = `?id=${user?.id}&page=${wishList?.current_page}`;
      }
      dispatch(globalAction(SHOW_LOADING));
      dispatch(
        globalAction(DELETE_WISHLIST_PRODUCT_BY_ID, { params: params, id: id })
      );
    }
  };

  const addToCart = (item) => {
    dispatch(globalAction(SHOW_LOADING));
    item["qty"] = 1;
    dispatch(globalAction(ADD_TO_CART, { item: item, user: user }));
  };

  return (
    <section className="wish_list">
      <div className="page_title">
        <h3>Your WishList</h3>
      </div>
      {wishList && wishList?.data?.length > 0 ? (
        <div className="box-size ">
          <div className="wishlist_item" id="wishlist_item">
            {wishList?.data.length > 0 &&
              wishList.data.map((item, i) => {
                const { id, user_id, product } = item;
                return (
                  <div className="item">
                    <div className="item_image">
                      <Link to={`/product-detail/${product?.slug}`}>
                        <img src={product?.image_url} alt={product?.title} />
                      </Link>
                    </div>
                    <div className="item_name">
                      <Link to={`/product-detail/${product?.slug}`}>
                        <h6>{product?.title || "Sample Title"}</h6>
                      </Link>
                      {/* {product?.product_review_count &&
                        <div className="review">
                          <ReactStars
                            count={5}
                            size={24}
                            value={product?.product_review_count}
                            edit={false}
                            isHalf={true}
                            activeColor="#ffd700"
                          />
                          <span class="text">({product?.product_review_count}) Reviews</span>
                        </div>
                      } */}

                      {/* {!product?.product_review_count &&
                        <div className="review">
                          <ReactStars
                            count={5}
                            size={24}
                            value={product?.product_review_count}
                            edit={false}
                            isHalf={true}
                            activeColor="#ffd700"
                          />
                          <span class="text">({product?.product_review_count}) Reviews</span>
                        </div>
                      } */}

                      <div class="des">
                        <ul>
                          {product?.brand_name !== null && (
                            <li>Brand : {product?.brand_name}</li>
                          )}
                          {product?.model_no !== null && (
                            <li>Model Number : {product?.model_no}</li>
                          )}
                          {product?.category_name !== null && (
                            <li>Category : {product?.category_name}</li>
                          )}
                          {/* {product?.category !== null &&
                            <li>Tags: <strong>{product?.tags?.split(', ').reduce((acc, item) => {
                              return acc = acc + ' #' + item;
                            }, '')}
                            </strong></li>
                          } */}
                        </ul>
                      </div>
                    </div>
                    <div className="item_button">
                      {product?.sale_price !== null ? (
                        <h5>
                          ${product?.sale_price}
                          <del>${product?.regular_price}</del>
                        </h5>
                      ) : (
                        <h5>${product?.regular_price}</h5>
                      )}

                      <div className="btn-div">
                        <div className="button_wrap">
                          <button
                            className="btn-black"
                            onClick={(e) => {
                              addToCart(product);
                            }}
                          >
                            Add to cart
                          </button>
                        </div>
                        <div className="button_wrap">
                          <button
                            className="remove-btn"
                            onClick={() => {
                              RemoveProductFromWishlist(id);
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          {wishList && wishList?.data?.length > 0 && (
            <div className="pcw-pagination">
              <div className="pcw-pagination-inner">
                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={Math.ceil(wishList?.total / wishList?.per_page)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                  forcePage={Number(wishList?.current_page - 1)}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="no-product-wrap">
          <div>
            <img src={emptyProduct} alt="No product found" />
          </div>
          <h5 style={{ color: "red" }}>No product found...! </h5>
        </div>
      )}
    </section>
  );
}

export default WishList;
