// Imports: Dependencies
import { combineReducers } from 'redux';
// Imports: Reducers
import authReducer from './authReducer'
import errorReducer from './errorReducer'
import appReducer from './appReducer'
// Redux: Root Reducer
const rootReducer = combineReducers({
  authReducer,
  errorReducer,
  appReducer
});
// Exports
export default rootReducer;