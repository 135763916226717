import React, { useEffect, useState } from 'react'
import './FirstTimeDetailForm.scss'
import { ImWarning } from 'react-icons/im';
import { Col, Form, Row } from 'react-bootstrap';
import Button from '@restart/ui/esm/Button';
import AllCountry from '../../../../Utilis/allCountry.json'
import { useDispatch, useSelector } from 'react-redux';
import { globalAction, SHOW_LOADING, UPDATE_COMPANY_KYC, UPDATE_COMPANY_PROFILE, UPDATE_COUNTRIES, UPDATE_PAYMENT_INFO } from '../../../../redux';
import ImgPreview from '../../../../assets/upload_image_sample.png'
import PendingApprovalImg from '../../../../assets/undraw_pending_approval_xuu9.svg'
import { TiDeleteOutline } from 'react-icons/ti';
import Autocomplete from 'react-autocomplete';



function FirstTimeDetailForm(props) {
    const { user } = useSelector(state => state.authReducer);
    const dispatch = useDispatch()
    const [formStep, setformStep] = useState(1)
    const [formData, setFormData] = useState({})
    const [validated, setValidated] = useState(false);
    const [uploadImgPreview1, setUploadImgPreview1] = useState(null)
    const [uploadImgPreview2, setUploadImgPreview2] = useState(null)
    const [selectedCountry, setselectedCountry] = useState('')
    const [selectedCountryArr, setselectedCountryArr] = useState([])
    const [cmReRender, setCmReRender] = useState(false)




    useEffect(() => {
        if (user?.company_profile_completed === 'yes') {
            setformStep(2);
            setFormData({})
        }
        if (user?.kyc_submit === 1) {
            setformStep(3);
            setFormData({})
        }
        if (user?.payment_details_submit === 'yes') {
            setformStep(4);
            setFormData({})
        }

        if (user?.vendor_countries !== null) {
            setformStep(5);
            setFormData({})
        }

        if(user?.company_profile_completed !== 'yes'){
            setFormData({...formData,company_name:user?.company_name,country_id:user?.country_id})
        } 
       
    }, [user])

    useEffect(() => {

    }, [uploadImgPreview1, uploadImgPreview2, cmReRender])

    useEffect(() => {
    }, [])


    const changeText = (value, fieldName) => {
        let credentials = formData;
        credentials[fieldName] = value
        setFormData(credentials)
    }

    const handleSubmit = (e, formType) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }
        else {
            dispatch(globalAction(SHOW_LOADING))
            if (formType === 'companyProfile') {
                let tempData = {
                    ...formData,
                    company_profile_completed: 'yes',
                    latitude: '0.554',
                    longitude: '87.554',
                    id: user?.id 
                }
                setFormData(tempData)
                dispatch(globalAction(UPDATE_COMPANY_PROFILE, tempData))
                setFormData({})
            }
            if (formType === 'kyc') {
                let tempData = {
                    ...formData,
                    id: user?.id 
                }
                setFormData(tempData)
                dispatch(globalAction(UPDATE_COMPANY_KYC, tempData))
            }
            if (formType === 'paymentInfo') {
                let tempData = {
                    ...formData,
                    id: user?.id 
                }
                setFormData(tempData)
                dispatch(globalAction(UPDATE_PAYMENT_INFO, tempData))
            }
            if (formType === 'sellingCountries') {
                let tempData = [];
                selectedCountryArr.forEach(ele => {
                    let tempItem = AllCountry.find((item, i) => item.name === ele)
                    tempData.push(tempItem.id)
                })
                let finalData = { user_id: user?.id, countries: tempData.toString() }
                dispatch(globalAction(UPDATE_COUNTRIES, finalData))


            }
        }
        setValidated(true);
    }

    const onSelectImage = async (value, fieldName) => {
        let file = await value.target.files[0];
        let reader = await new FileReader();
        if (file) {
            reader.readAsDataURL(file);
        }
        reader.onload = async () => {
            let credentials = formData;
            credentials[fieldName] = file;
            if (fieldName === 'proof_registration') {
                setUploadImgPreview1(reader.result)
            }
            if (fieldName === 'proof_address') {
                setUploadImgPreview2(reader.result)

            }
            setFormData(credentials)
        }
    }


    return (
        <div className="first-time-detail-form-wrap">
            <div className="ftd-inner">
                <div className="ftd-alert">
                    <div className="ftd-inner">
                        <h6> <span><ImWarning /></span> Warning: KYC PENDING </h6>
                        {formStep < 5  && 
                        <p>
                            After you submit this details we are approved you as verified seller on Du-Afrik</p>}
                        
                    </div>
                </div>
                {(user?.vendor_country !== null && formStep === 5)
                    ?
                    <div className="ftd-pending-approval">
                        <div className="ftd-pending-approval-inner">
                            <div className="ftd-pa-ing">
                                <img src={PendingApprovalImg} alt="Pending Approval" />
                            </div>
                            <div className="ftd-pa-content">
                                <p>We have received your approval request. We will update soon.</p>
                            </div>
                        </div>

                    </div>

                    :
                    <div className="ftd-form-wrap">
                        <div className="ftd-form-wrap-inner">
                            <div className="ftd-f-steps-wrap">
                                <div className="steps-inner">
                                    <div className="currentSteps">
                                        <span>{formStep}</span>
                                    </div>
                                    <div className="totalSteps">
                                        <span>4 Steps</span>
                                    </div>
                                </div>
                            </div>

                            <div className="ftd-fw-main-section">
                                <div className="ftd-fw-main-inner">
                                    <div className="ftd-fw-heading">
                                        <h1>
                                            {formStep === 1 && 'Company Profile'}
                                            {formStep === 2 && 'KYC'}
                                            {formStep === 3 && 'Payment Information'}
                                            {formStep === 4 && 'Selling Countries'}
                                        </h1>
                                    </div>
                                    {formStep === 1
                                        &&
                                        <div className="ftd-fw-form company">
                                            <Form
                                                noValidate
                                                validated={validated}
                                                onSubmit={(e) => { handleSubmit(e, 'companyProfile') }}
                                            >
                                                <Row >
                                                    <Col md>
                                                        <Form.Group className="mb-4" controlId="formBasicCompanyName">
                                                            <Form.Label>Company Name</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                placeholder="Enter company name"
                                                                className="input"
                                                                defaultValue={formData?.company_name}
                                                                onChange={(e) => changeText(e.target.value, 'company_name')}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md>
                                                        <Form.Group className="mb-4" controlId="Companywebsiturl">
                                                            <Form.Label>Company Website Url</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Company Website Url"
                                                                className="input"
                                                                onChange={(e) => changeText(e.target.value, 'company_url')}
                                                            />
                                                            <Form.Text id="Companywebsiturl" muted>(Its optional)
                                                            </Form.Text>
                                                        </Form.Group>

                                                    </Col>
                                                </Row>

                                                <Row >
                                                    <Col md>
                                                        <Form.Group className="mb-4" controlId="companyAddress">
                                                            <Form.Label>Company Address</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                placeholder="Enter Company Address"
                                                                className="input"
                                                                onChange={(e) => changeText(e.target.value, 'company_address')}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md>
                                                        <Form.Group className="mb-4" controlId="Country">
                                                            <Form.Label>Country </Form.Label>
                                                            <Form.Select
                                                                aria-label="Floating label select example"
                                                                required
                                                                onChange={(e) => changeText(Number(e.target.value), 'country_id')}
                                                            >
                                                                {AllCountry.map((item,i)=>{
                                                                    return(
                                                                        <option 
                                                                        value={item.id}
                                                                        selected={item.id==formData.country_id}
                                                                        >{item.name}</option>
                                                                    )
                                                                })}
                                                                
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>


                                                <Form.Group className="mb-4" controlId="CompanyDescription">
                                                    <Form.Label>Company Description</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Company description"
                                                        as="textarea"
                                                        style={{ height: '100px' }}
                                                        className="input"
                                                        onChange={(e) => changeText(e.target.value, 'company_details')}
                                                    />
                                                    <Form.Text id="Companywebsiturl" muted>(Its optional)
                                                    </Form.Text>
                                                </Form.Group>
                                                <Button variant="primary" type="submit" className="btn-brown">
                                                    Submit & Next
                                                </Button>
                                            </Form>
                                        </div>
                                    }

                                    {/* KYC */}
                                    {formStep === 2
                                        &&
                                        <div className="ftd-fw-form company">
                                            <Form
                                                noValidate
                                                validated={validated}
                                                onSubmit={(e) => { handleSubmit(e, 'kyc') }}
                                            >
                                                <Row >
                                                    <Col md>
                                                        <Form.Group className="mb-4" controlId="registrationName">
                                                            <Form.Label>Registered Name </Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                placeholder="Enter registered name"
                                                                className="input"
                                                                onChange={(e) => changeText(e.target.value, 'complete_legal_name')}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md>
                                                        <Form.Group className="mb-4" controlId="taxRegistrationDetails">
                                                            <Form.Label>Tax Registration Details</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                placeholder="Enter tax registration details"
                                                                className="input"
                                                                onChange={(e) => changeText(e.target.value, 'tax_registration_details')}
                                                            />

                                                        </Form.Group>

                                                    </Col>
                                                </Row>

                                                <Row >
                                                    <Col md>
                                                        <Form.Group controlId="registrationProof" className="mb-3 cm-file-upload">
                                                            <Form.Label>Registration Proof</Form.Label>
                                                            <div className="previewImage">
                                                                <img src={uploadImgPreview1 || ImgPreview} alt="Registration Proof" />
                                                            </div>
                                                            <Form.Control
                                                                required
                                                                type="file"
                                                                className="input "
                                                                onChange={(e) => onSelectImage(e, 'proof_registration')}
                                                            />
                                                            <Form.Text id="registrationProof" muted>(file will be in png/jpeg/pdf format)
                                                            </Form.Text>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md>
                                                        <Form.Group controlId="formFile" className={`mb-3 cm-file-upload  ${uploadImgPreview2 ? 'active' : ''} `}>
                                                            <Form.Label>Address Proof</Form.Label>
                                                            <div className={`previewImage `}>
                                                                <img src={uploadImgPreview2 || ImgPreview} alt="Registration Proof" />
                                                            </div>
                                                            <Form.Control
                                                                required
                                                                type="file"
                                                                className={`input `}
                                                                onChange={(e) => onSelectImage(e, 'proof_address')}
                                                            />
                                                            <Form.Text id="addressProof" muted>(file will be in png/jpeg/pdf format)
                                                            </Form.Text>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Button variant="primary" type="submit" className="btn-brown">
                                                    Submit & Next
                                                </Button>
                                            </Form>
                                        </div>
                                    }

                                    {/* Payment Info */}
                                    {formStep === 3
                                        &&
                                        <div className="ftd-fw-form payment-info">
                                            <Form
                                                noValidate
                                                validated={validated}
                                                onSubmit={(e) => { handleSubmit(e, 'paymentInfo') }}
                                            >
                                                <Row >
                                                    <Col md>
                                                        <Form.Group className="mb-4" controlId="bankName">
                                                            <Form.Label>Bank Name </Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                placeholder="Enter Bank Name"
                                                                className="input"
                                                                onChange={(e) => changeText(e.target.value, 'bank_name')}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md>
                                                        <Form.Group className="mb-4" controlId="bankAccountNumber">
                                                            <Form.Label>Bank Account Number</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                placeholder="Enter Bank Account Number"
                                                                className="input"
                                                                onChange={(e) => changeText(e.target.value, 'account_number')}
                                                            />
                                                        </Form.Group>

                                                    </Col>
                                                </Row>

                                                <Row >
                                                    <Col md>
                                                        <Form.Group className="mb-4" controlId="bankAccountNumber">
                                                            <Form.Label>Bank IFSC Code</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                placeholder="Enter Bank IFSC Code"
                                                                className="input"
                                                                onChange={(e) => changeText(e.target.value, 'ifsc_code')}
                                                            />
                                                        </Form.Group>

                                                    </Col>
                                                    <Col md>
                                                        <Form.Group className="mb-4" controlId="bankAccountNumber">
                                                            <Form.Label>Bank Account Holder Name</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                placeholder="Enter Account Holder Name"
                                                                className="input"
                                                                onChange={(e) => changeText(e.target.value, 'account_holder_name')}
                                                            />
                                                        </Form.Group>

                                                    </Col>
                                                </Row>

                                                <Button variant="primary" type="submit" className="btn-brown">
                                                    Submit & Next
                                                </Button>
                                            </Form>
                                        </div>
                                    }

                                    {/* Selling Country */}
                                    {formStep === 4
                                        &&
                                        <div className="ftd-fw-form vendor-selling-countries">
                                            <div className="ftd-fw-inner">
                                                <div className="ftd-auto-select">
                                                    <Autocomplete
                                                        className='input'
                                                        getItemValue={(item) => item.name}
                                                        items={AllCountry}
                                                        shouldItemRender={(item, value) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1}
                                                        renderItem={(item, isHighlighted) =>
                                                            <div
                                                                className='input'
                                                                style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                                                {item.name}
                                                            </div>
                                                        }
                                                        value={selectedCountry}
                                                        onChange={(e) => setselectedCountry(e.target.value)}
                                                        onSelect={(val) => {
                                                            selectedCountryArr.push(val);
                                                            let removeDuplicateCountries = [...new Set(selectedCountryArr)];
                                                            if (removeDuplicateCountries.find((item) => item === 'All Countries')) {
                                                                removeDuplicateCountries = ['All Countries']
                                                            }
                                                            setselectedCountry(val);
                                                            setselectedCountryArr(removeDuplicateCountries)
                                                        }}
                                                    />
                                                </div>
                                                {selectedCountryArr.length > 0 &&
                                                    <>
                                                        <div className="ftd-selected-countries-wrap">
                                                            <div className="ftd-selected-country-wrap">
                                                                <h4>Selected Countries</h4>
                                                            </div>
                                                            <div className="ftd-selected-wrap">


                                                                {selectedCountryArr.length > 0 &&
                                                                    selectedCountryArr.map((item, i) => {
                                                                        return (
                                                                            <div key={item} className="ftd_selected_country">
                                                                                {item}
                                                                                <span
                                                                                    onClick={(e, i) => {
                                                                                        selectedCountryArr.splice(i, 1)
                                                                                        setselectedCountryArr(selectedCountryArr)
                                                                                        setCmReRender(!cmReRender)
                                                                                    }
                                                                                    }
                                                                                ><TiDeleteOutline /></span>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                        <Button
                                                            variant="primary"
                                                            type="submit"
                                                            className="btn-brown"
                                                            onClick={(e) => { handleSubmit(e, 'sellingCountries') }}
                                                        >
                                                            Submit & Next
                                                        </Button>
                                                    </>
                                                }
                                            </div>

                                        </div>
                                    }


                                </div>
                            </div>

                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default FirstTimeDetailForm
