import { Link } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  deleteProductVariantByProductApi,
  getProductVariantListByProductId,
} from "../../../../../api";
import {
  DELETE_PRODUCT_VARIANT_BY_ID,
  GET_PRODUCT_VARIANT_LIST_BY_PRODUCT_ID,
  globalAction,
  SHOW_LOADING,
} from "../../../../../redux";
import tableIcons from "../../../../Dashboard/TableIcons";
import AddProductVariant from "../AddProductVariant/AddProductVariant";
import "./ViewAllVariantOfProduct.scss";

function ViewAllVariantOfProduct(props) {
  const { productId } = props;
  let urlHasSlug = productId;
  const dispatch = useDispatch();
  const { productVariantList } = useSelector((state) => state.appReducer);
  const { user } = useSelector((state) => state.authReducer);
  const [modalFlag, setModalFlag] = useState(false);
  const [variantId, setvariantId] = useState(undefined);
  const [variantData, setVariantData] = useState(undefined);

  useEffect(() => {
    if (urlHasSlug) {
      dispatch(
        globalAction(GET_PRODUCT_VARIANT_LIST_BY_PRODUCT_ID, urlHasSlug)
      );
    }
  }, []);

  const handleDeleteVariant = (e, variantId) => {
    const confirmation = window.confirm(
      "Are you Sure to delete this Variant ?"
    );
    if (!confirmation) {
      return;
    }
    e.preventDefault();
    dispatch(globalAction(SHOW_LOADING));
    dispatch(
      globalAction(DELETE_PRODUCT_VARIANT_BY_ID, {
        id: variantId,
        productId: urlHasSlug,
      })
    );
  };

  const handleAddVariantModal = (e, productId, variantData) => {
    e.preventDefault();
    setModalFlag(!modalFlag);
    setvariantId(productId);
    setVariantData(variantData);
  };
  const handleCloseAddVariantModal = () => {
    setModalFlag(!modalFlag);
  };

  const orderTitle = [
    { title: " ID", field: "id" },
    {
      title: "Image",
      field: "image",
      render: (rowData) => (
        <img
          src={rowData.image}
          style={{
            width: 100,
            height: 100,
            borderRadius: "50%",
            objectFit: "contain",
          }}
        />
      ),
    },
    {
      title: "Variant Name",
      field: "variant_name",
      render: (rowData) => {
        return <p className="mt-3">{rowData.variant_name}</p>;
      },
    },
    { title: "Category", field: "product.category_name" },
    {
      title: "Price",
      field:
        productVariantList.sale_price !== null ? "sale_price" : "regular_price",
    },
    { title: "QTY", field: "stock_qty" },
    {
      title: "QTY Status",
      field: "stock_availability",
      render: (rowData) =>
        rowData.stock_availability === "out_stock"
          ? "Out of Stock"
          : "In Stock",
    },
    {
      title: "Options",
      render: (rowData) => {
        return (
          <div className="menu-options">
            <ul>
              <li>
                <a
                  onClick={(e) => {
                    handleAddVariantModal(e, rowData.id, rowData);
                  }}
                >
                  <span className="icon">
                    <AiFillEdit />
                  </span>
                  <span className="text">Edit </span>
                </a>
              </li>
              <li className="delete-icon">
                <a
                  onClick={(e) => {
                    handleDeleteVariant(e, rowData.id);
                  }}
                >
                  <span className="icon">
                    <MdDelete />
                  </span>
                  <span className="text">Delete variant </span>
                </a>
              </li>
            </ul>
          </div>
        );
      },
    },
  ];

  return (
    <div className="view-all-variant">
      <div className="vav-inner">
        <div className="title">
          <h3>
            Variants
            <span className="small">
              (
              {productVariantList.length > 0 &&
                productVariantList[0].product.title}
              )
            </span>
          </h3>
        </div>

        <div className="vd-dashboard-body box-size vav-content">
          <div className="vav-table">
            <MaterialTable
              title="All Orders"
              columns={orderTitle}
              data={productVariantList}
              icons={tableIcons}
              options={{
                search: true,
                exportButton: true,
              }}
              onChangePage={(e) => console.log(e)}
            />
          </div>

          {modalFlag && (
            <div className="pv-modal">
              <AddProductVariant
                modalFlag={modalFlag}
                handleClose={() => handleCloseAddVariantModal()}
                btnText={"Update"}
                modalHeading={"Update"}
                userId={user?.id}
                productId={productId}
                userForUpdate={true}
                variantData={variantData}
                addVariantOnExitProduct={false}
              />

              {/* <AddProductVariant
                modalFlag={modalFlag}
                handleClose={() => setModalFlag(!modalFlag)}
                btnText={'Add Variant'}
                modalHeading={'Add new Variant'}
                userId={user?.id}
                productFormData={{ ...productData, 'description': decription, 'additional_information': AdditionalInfo }}
                productId={urlHasSlug}
                addVariantOnExitProduct={AddVarientFlag}
              /> 
            </div>*/}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewAllVariantOfProduct;
