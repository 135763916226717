
import React from 'react'
import { GrNext, GrPrevious } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import './RelatedProduct.scss'

function RelatedProduct(props) {
    const { data } = props

    const settings = {
      dots: true,
      infinite: false,
      speed: 1000,
      slidesToShow: 4,
      slidesToScroll: 4,
      nextArrow: <GrNext />,
      prevArrow: <GrPrevious />,
      centerPadding: "60px",
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
            arrows: false,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2,
            arrows: false,
          },
        },
      ],
    };
    return (
        <div className='related-product-wrap'>
            <div className="rpw-inner cm-container">
                <div className="title">
                    <h4> Related Products</h4>
                </div>
                <Slider {...settings}>
                    {data.map((item, index) => {
                        return (
                            <div className="item">
                                <div className="item-inner">


                                    <div className="img-wrap">

                                        <Link 
                                            to={`/product-detail/${item?.slug}`}>
                                            <img src={item?.image_url} alt={item?.seo_title || item?.title} />
                                        </Link>
                                    </div>
                                    <div className="content">
                                        <Link to={`/product-detail/${item?.slug}`}> <h6>
                                            {item?.title}</h6>
                                        </Link>
                                        {item?.sale_price !== null ?
                                        <p>
                                            <span className="saleprice">
                                                {item?.sale_price}$
                                            </span>
                                            <del className="regular-price">
                                                {
                                                    (item && item.regular_price != item.sale_price) ?
                                                    item.regular_price + '$'
                                                    :
                                                        ''
                                                }
                                            </del>

                                        </p>
                                        :
                                        <p>
                                            <span className="saleprice">
                                                {item?.regular_price} $
                                            </span>

                                        </p>
                                    }


                                        <p><strong>Brand :</strong> {item?.brand_name}</p>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                    )}
                </Slider>
            </div>

        </div>
    )
}

export default RelatedProduct
