import React, { useEffect, useState } from "react";
import MasterCardImg from "../../assets/img/Master-card-icon.png";
import VisaImg from "../../assets/img/VISA-icon.png";
import PaytmImg from "../../assets/img/Paytm-icon.png";
import PaypalImg from "../../assets/img/Pay-pal-icon.png";
import facebookImg from "../../assets/img/fb.png";
import InstagramImg from "../../assets/img/instagram.png";
import PinterestImg from "../../assets/img/pinterest.png";
import TwitterImg from "../../assets/img/twitter.png";
import "./Footer.scss";
import { Link } from "react-router-dom";
import { footerApi } from "../../api";
import { BsArrowRight } from "react-icons/bs";

function Footer() {
  const [footerData, setfooterData] = useState(undefined);

  const getFooterData = async function () {
    footerApi()
      .then((res) => {
        const { status, data } = res;
        if (status === 200) {
          setfooterData(data.footer);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFooterData();
  }, []);

  return (
    <>
      <div className="footer-main-wrap">
        <div className="f-menu-wrap cm-container">
          <div className="f-menu-inner">
            <div className="f-menu-col cm-col-1">
              <div className="logo-wrap">
                <img src={footerData?.logo} alt="Du-Afrik" />
              </div>
              <div className="content">
                <p>{footerData?.about_us}</p>
                <a href="/about-us" className="btn-black">
                  Read More{" "}
                  <span>
                    <BsArrowRight />
                  </span>
                </a>
              </div>
            </div>
            <div className="f-menu-col  cm-col-2">
              <div className="f-menu">
                <h3>Help</h3>
                <ul>
                  <li>
                    <a href="/faqs">FAQs</a>
                  </li>
                  <li>
                    <a href="/about-us">About US</a>
                  </li>
                  <li>
                    <a href="/contact-us">Contact Us</a>
                  </li>
                  <li className="sell-on-btn ">
                    <a href="/sell-with-us">Sell on DuAfrik</a>
                  </li>
                  <li>
                    <a href="/how-it-works">How it works</a>
                  </li>
                  {/* <li><a href='/vendor-connect'>Vendor Connect</a></li> */}
                  {/* <li><a href='/wallet-faqs'>WalletFAQs</a></li> */}
                  {/* <li>
                    <a href="/wallet-terms-and-conditions">Wallet T&Cs</a>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="f-menu-col cm-col-3">
              <div className="f-menu">
                <h3>Important Links</h3>
                <ul>
                  <li>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                  {/* <li>
                    <a href="/seller-policy">Seller Policy</a>
                  </li> */}
                  {/* <li>
                    <a href="/delivery-policy">Delivery Policy</a>
                  </li> */}
                  <li>
                    <a href="/Terms-and-Condtions">Terms and Condtions</a>
                  </li>
                  {/* <li><a href='/in-news'>In News</a></li> */}
                  <li>
                    <a href="/blog">Blog</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="f-menu-col cm-col-4">
              <h3>Get Social With Us</h3>
              <div className="social-icons-wrap">
                <div className="social-item">
                  <a href="https://www.facebook.com/">
                    <img src={facebookImg} alt="du-afric-facebook" />
                  </a>
                </div>
                <div className="social-item">
                  <a href="https://in.pinterest.com/">
                    <img src={PinterestImg} alt="du-afric-pinterest" />
                  </a>
                </div>
                {/* <div className="social-item">
                  <a href="https://twitter.com/">
                    <img src={TwitterImg} alt="du-afric-twitter" />
                  </a>
                </div> */}
                <div className="social-item">
                  <a href="https://www.instagram.com/">
                    <img src={InstagramImg} alt="du-afric-instagram" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="f-payment-main-wrap cm-container">
        <ul>
          <li>
            <h3>PAYMENT OPTIONS</h3>
          </li>
          <li>
            <div className="payment-option">
              <img src={MasterCardImg} alt="VISA" />
            </div>
          </li>
          <li>
            <div className="payment-option">
              <img src={VisaImg} alt="Master-card" />
            </div>
          </li>
          <li>
            <div className="payment-option">
              <img src={PaypalImg} alt="PayPal" />
            </div>
          </li>
        </ul>
      </div>
      <div className="copyright-wrap">
        {/* <p>{footerData?.copyright_text}</p> */}
        <p>&copy; {new Date().getFullYear()}, made by duafrik.</p>
      </div>
    </>
  );
}

export default Footer;
